import { FC, useEffect, useRef, useState } from 'react'
import { Row, Col } from 'antd'
import moment from 'moment'
import styled from 'styled-components'
import { TextLegacy } from '@/components/common'
import { InputText } from '@/components/common/inputs'
import { BulletedText } from '@/components/common/paragraphs'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { useSignupEventTracker } from '@/containers/event-tracker/signup.event-tracker'
import { useMessage } from '@/containers/hooks'
import { isDateAfter } from '@/utils/utilDate'
import { validateBirthdayFormat } from '@/utils/utilFormValidators'

type InputType = 'year' | 'month' | 'day'

type InputBirthdayProps = {
  defaultBirthday?: string
  setValue: (birthday: string, valid: boolean) => void
  checkOver14Year?: boolean
  inProfileUpdate: boolean
  socialType?: string
}

const InputBirthday: FC<InputBirthdayProps> = ({
  defaultBirthday,
  setValue,
  checkOver14Year,
  inProfileUpdate,
  socialType,
}) => {
  const { show } = useMessage()
  let defaultYear = ''
  let defaultMonth = ''
  let defaultDay = ''

  if (defaultBirthday) {
    const _birthdaySplit = defaultBirthday.split('-')
    defaultYear = _birthdaySplit[0]
    defaultMonth = _birthdaySplit[1]
    defaultDay = _birthdaySplit[2]
  }

  const [isValid, setIsValid] = useState<boolean>(true)
  const [errorMsg, setErrMsg] = useState<string>()
  const [year, setYear] = useState<string>(defaultYear)
  const [month, setMonth] = useState<string>(defaultMonth)
  const [day, setDay] = useState<string>(defaultDay)

  const yearFocusRef = useRef<boolean>(false)
  const monthFocusRef = useRef<boolean>(false)
  const dayFocusRef = useRef<boolean>(false)
  const { trackerSubmitSignupInfoBirthdayEvent } = useSignupEventTracker()

  useEffect(() => {
    if (errorMsg && inProfileUpdate) {
      show('입력한 정보를 확인해 주세요.')
    }
  }, [errorMsg])

  const _onInput = (value: string, type: InputType) => {
    let _birthday = ''

    if (type === 'year') {
      setYear(value)
      _birthday = value + month + day
    } else if (type === 'month') {
      setMonth(value)
      _birthday = year + value + day
    } else if (type === 'day') {
      setDay(value)
      _birthday = year + month + value
    }

    // FormUpdateProfile 에서 onBlur 가 호출되지 않고 변경 완료 버튼을 눌렀을 경우에 필요
    setValue(_birthday, true)
  }

  const _onFocus = (type: InputType) => {
    if (type === 'year') {
      yearFocusRef.current = true
    } else if (type === 'month') {
      monthFocusRef.current = true
    } else if (type === 'day') {
      dayFocusRef.current = true
    }
  }

  const _onBlur = (type: InputType) => {
    let month_ = month
    let day_ = day
    if (type === 'year') {
      yearFocusRef.current = false
    } else if (type === 'month') {
      if (month.length == 1) {
        month_ = '0' + month_
        setMonth(month_)
      }
      monthFocusRef.current = false
    } else if (type === 'day') {
      if (day.length == 1) {
        day_ = '0' + day_
        setDay(day_)
      }
      dayFocusRef.current = false
    }

    setTimeout(() => {
      if (yearFocusRef.current || monthFocusRef.current || dayFocusRef.current) {
        return
      }

      const birthday = year + month_ + day_
      let errMsg_ = undefined

      if (birthday.length > 0) {
        let valid = validateBirthdayFormat(birthday)
        if (valid) {
          if (checkOver14Year) {
            const older14ageAt = moment().add(-14, 'year').add(1, 'day').format('YYYY-MM-DD')
            if (isDateAfter(birthday, older14ageAt)) {
              valid = false
              errMsg_ = '만 14세 미만은 가입할 수 없습니다.'
            }
          }
        } else {
          errMsg_ = '생년월일을 정확히 입력해 주세요.'
        }
        setIsValid(valid)
        setErrMsg(errMsg_)
        setValue(birthday, valid)
      } else {
        setIsValid(true)
      }
      trackerSubmitSignupInfoBirthdayEvent({
        signupType: socialType,
        errorType: errMsg_ ? 'error' : 'normal',
      })
    }, 100)
  }

  return (
    <>
      <Row>
        <Col>
          <TextLegacy size={{ xs: 14, md: 16 }} weight="bold">
            생년월일
          </TextLegacy>
          <TextLegacy size={{ xs: 14, md: 16 }} color="gray400">
            (선택)
          </TextLegacy>
        </Col>
      </Row>
      <StyledRow align="middle" valid={isValid ? 1 : 0}>
        <Col span={8}>
          <InputText
            value={year}
            placeholder="YYYY"
            bordered={false}
            textAlign="center"
            onChange={(e) => _onInput(e.target.value, 'year')}
            onFocus={() => _onFocus('year')}
            onBlur={() => _onBlur('year')}
            maxLength={4}
            type="tel"
          />
        </Col>
        <CenterCol span={1}>
          <TextLegacy size={{ xs: 14, md: 16 }} color="gray200">
            /
          </TextLegacy>
        </CenterCol>
        <Col span={6}>
          <InputText
            value={month}
            placeholder="MM"
            bordered={false}
            textAlign="center"
            onChange={(e) => _onInput(e.target.value, 'month')}
            onFocus={() => _onFocus('month')}
            onBlur={() => _onBlur('month')}
            maxLength={2}
            type="tel"
          />
        </Col>
        <CenterCol span={1}>
          <TextLegacy size={{ xs: 14, md: 16 }} color="gray200">
            /
          </TextLegacy>
        </CenterCol>
        <Col span={8}>
          <InputText
            value={day}
            placeholder="DD"
            bordered={false}
            textAlign="center"
            onChange={(e) => _onInput(e.target.value, 'day')}
            onFocus={() => _onFocus('day')}
            onBlur={() => _onBlur('day')}
            maxLength={2}
            type="tel"
          />
        </Col>
      </StyledRow>
      {!isValid && errorMsg && (
        <Row>
          <Col span={24}>
            <BulletedText
              text={errorMsg}
              textStyle={{
                size: { xs: 12, md: 14 },
                color: 'red500',
              }}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

const StyledRow = styled(Row)<{ valid: number }>`
  border: ${({ valid }) => (valid ? `1px solid ${GlobalColorEnum.Gray200}` : '1px solid #f27272')};
  border-radius: 5px;
  margin-top: 6px;
`

const CenterCol = styled(Col)`
  text-align: center;
`

export default InputBirthday
