import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'

const ORDER_FRAGMENTS = {
  get orderListItem() {
    return gql`
      fragment orderListItem on OrderItemType {
        id
        orderStatus
        orderStatusText
        createdAt
        confirmedAt
        hasUserReview
        productName
        quantity
        originalPrice
        productDiscountPrice
        couponDiscountPrice
        amount
        mileage
        reviewMileage
        imageReviewMileage
        orderConfirmMileage
        reviewMileageRate
        imageReviewMileageRate
        orderConfirmMileageRate
        originalAmount
        productDiscountAmount
        refundAmount
        customerPrice
        customerAmount
        productPromotionCustomerPrice
        productPromotionDiscountPrice
        reviewWritableDatetime
        membershipMileage
        order {
          id
          orderNumber
          membership {
            id
          }
        }
        productImage {
          id
          image
          displayType
          thumbnail(productImageType: "SMALL")
        }
        productOption {
          id
          name
          customerPrice
          productId
          product {
            id
            productNumber
          }
          productOptionKinds {
            key
            value
          }
        }
        brand {
          id
          name
        }
        seller {
          id
          shopName
        }
        shipping {
          shippingCompany {
            id
            name
            sweetTrackerCode
          }
          shippingNumbers
          shippingCompanyAndNumbers
        }
        approvedTransaction {
          id
          payMethodType
          transactionStatus
        }
      }
    `
  },
  get orderDetail() {
    return gql`
      fragment orderDetail on OrderType {
        ...order
        user {
          id
          name
          email
          mobileNumber
          accountNumber
          accountHolderName
          bankCode
          bankName
        }
        customerAddress {
          id
          name
          mobileNumber
          postNumber
          basicAddress
          detailedAddress
          message
        }
        orderRefundInfo {
          originalAmount
          productDiscountAmount
          productPromotionDiscountAmount
          couponDiscountAmount
          orderCouponDiscountAmount
          mileageDiscountAmount
          soldAmount
          totalRefundAmount
          returnShippingFeeAmount
          refundShippingFeeAmount
          exchangeReturnShippingFeeAmount
          exchangeNewShippingFeeAmount
        }
        orderTransactions {
          totalCount
          edges {
            node {
              id
              amount
              transactionStatus
              createdAtMillis
              transactionStatusText
              updatedAt
              vbankAccountHolderName
              vbankAccountNumber
              vbankBankCode
              vbankBankName
              vbankEndedAt
              bankAccountHolderName
              bankAccountNumber
              bankCode
              bankName
              payMethodType
              paymentText
            }
          }
        }
        approvedTransaction {
          id
          amount
          transactionStatus
          createdAtMillis
          transactionStatusText
          updatedAt
          vbankAccountHolderName
          vbankAccountNumber
          vbankBankCode
          vbankBankName
          vbankEndedAt
          bankAccountHolderName
          bankAccountNumber
          bankCode
          bankName
          payMethodType
          paymentText
        }
        shippings {
          edges {
            node {
              couponInfo
              shippingOrderItems {
                edges {
                  node {
                    couponInfo
                    quantity
                    productOption {
                      id
                      name
                      productPromotionCustomerPrice
                      product {
                        id
                        name
                        categories {
                          edges {
                            node {
                              id
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      ${DEFAULT_FRAGMENTS.order}
    `
  },
  get orderItemDetail() {
    return gql`
      fragment orderItemDetail on OrderItemType {
        id
        orderStatus
        orderStatusText
        createdAt
        hasUserReview
        productName
        quantity
        mileage
        orderConfirmMileage
        orderConfirmMileageRate
        originalPrice
        productDiscountPrice
        couponDiscountPrice
        orderCouponDiscountPrice
        mileageDiscountPrice
        amount
        originalAmount
        productDiscountAmount
        refundAmount
        customerPrice
        customerAmount
        productPromotionCustomerPrice
        productPromotionDiscountPrice
        cancelRequestMessage
        cancelRequestType
        cancelResponsibility
        cancelRequestedAt
        canceledAt
        cancelGuideMessage
        reviewWritableDatetime
        actionPowerOrder {
          orderUrl
        }
        order {
          id
          orderNumber
        }
        productPromotion {
          id
          productPromotionType
        }
        refundShippingInfo {
          fee
          extraFee
        }
        exchangeShippingFee
        exchangeNewOrderItem {
          id
          orderStatus
          orderStatusText
          shipping {
            id
            amount
            extraFee
            fee
            sumFee
          }
        }
        returnResponsibility
        returnInfo {
          id
          responsibility
          returnShippingFee
          returnShippingExtraFee
          guideMessage
          requestType
          requestMessage
          createdAt
          acceptedAt
          returnedAt
          confirmedAt
          customerAddress {
            id
            name
            mobileNumber
            postNumber
            basicAddress
            detailedAddress
            message
          }
          returnInfoReturnInfoImages {
            totalCount
            edges {
              node {
                thumbnail(imageType: "SMALL")
              }
            }
          }
        }
        productImage {
          id
          image
          displayType
          thumbnail(productImageType: "SMALL")
        }
        productOption {
          id
          name
          customerPrice
          productId
          product {
            id
            name
            productNumber
            productType
            categories {
              edges {
                node {
                  id
                  name
                }
              }
            }
            productPromotion {
              productPromotionType
            }
          }
          productOptionKinds {
            key
            value
          }
        }
        brand {
          id
          name
        }
        seller {
          id
          shopName
        }
        shipping {
          id
          amount
          couponDiscountPrice
          discountPrice
          mileageDiscountPrice
          extraFee
          fee
          sumFee
          shippingCompany {
            id
            name
            sweetTrackerCode
          }
          shippingPreset {
            returnFee
            shippingFee
          }
          shippingNumbers
          shippingCompanyAndNumbers
        }
        approvedTransaction {
          id
          payMethodType
          transactionStatus
        }
      }
    `
  },
  get orderItemMinimum() {
    return gql`
      fragment orderItemMinimum on OrderItemType {
        id
        orderStatus
        orderStatusText
        hasUserReview
      }
    `
  },
  get order() {
    return gql`
      fragment order on OrderType {
        createdAt
        updatedAt
        id
        orderNumber
        originalAmount
        productDiscountAmount
        productPromotionDiscountAmount
        couponDiscountAmount
        orderCouponDiscountAmount
        mileageDiscountAmount
        shippingFees
        shippingExtraFees
        shippingDiscountAmount
        shippingMileageDiscountAmount
        shippingCouponDiscountAmount
        couponInfo
        productPromotionCustomerAmount
        amount
        shippingAmount
        mileage
        imageReviewMileage
        reviewMileage
        orderConfirmMileage
        totalItemQuantity
        canPartialCancel
      }
    `
  },
}

export default ORDER_FRAGMENTS
