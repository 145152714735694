import DOMPurify from 'dompurify'
import _ from 'lodash'

const toCamel = (s: string): string => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

export const keysToCamel: {} | Array<string> | any = (raw: any) => {
  if (_.isObject(raw as { [key: string]: any })) {
    const n: { [key: string]: any } = {}
    Object.keys(raw).forEach((k: any) => {
      n[toCamel(k)] = keysToCamel(raw[k])
    })
    return n
  }

  if (_.isArray(raw)) {
    return (raw as []).map((i: any) => keysToCamel(i))
  }
  return raw
}

export const isMobileNumberFormat = (mobileNumber: string) => {
  if (_.isEmpty(mobileNumber)) {
    return false
  }

  let _mobileNumber = ''
  if (mobileNumber) {
    _mobileNumber = mobileNumber.replace(/-/gi, '').replace(/ /, '').trim()
  }
  return /^(010|011|016|018|019)\d{7,8}$/.test(_mobileNumber)
}

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return '-'
  }

  if (phoneNumber.length === 11) {
    return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
  }
  if (phoneNumber.length === 8) {
    return phoneNumber.replace(/(\d{4})(\d{4})/, '$1-$2')
  }

  if (phoneNumber.indexOf('02') === 0) {
    return phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')
  }

  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
}

export function convertSanitizingHtmlString(dirtyHtmlString: string) {
  if (typeof window === 'undefined') return dirtyHtmlString

  return DOMPurify.sanitize(dirtyHtmlString, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  })
}
