import { FC } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { ImageAsset, TextLegacy } from '@/components/common'
import { ImageAssetNameType } from '@/components/common/assets/ImageAssetBase'
import { LinkButton } from '@/components/common/buttons'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { ZENDESK_URLS } from '@/constants/legacy/constEnv'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'

const MyPageCustomerCenterTab: FC = () => {
  const getUrl = (type: 'INQUIRY' | 'NOTICE' | 'CUSTOMER_CENTER') => {
    return ZENDESK_URLS[type]!
  }

  return (
    <StyledTabRow>
      <RenderCCTabCol title="1:1 문의" iconName="inquiry_new" url={getUrl('INQUIRY')} />
      <RenderCCTabCol title="공지사항" iconName="board_new" url={getUrl('NOTICE')} />
      <RenderCCTabCol title="고객센터" iconName="customer-center_new" url={getUrl('CUSTOMER_CENTER')} />
    </StyledTabRow>
  )
}

type RenderCCTabColType = {
  title: string
  iconName: ImageAssetNameType
  url: string
}

const RenderCCTabCol = ({ title, iconName, url }: RenderCCTabColType) => {
  const tracker = useTracker()
  const _onClickEvent = () => {
    let eventName
    if (iconName === 'inquiry_new') eventName = FitpetMallEventEnum.ClickMypageContact
    else if (iconName === 'board_new') eventName = FitpetMallEventEnum.ClickMypageNotice
    else if (iconName === 'customer-center_new') eventName = FitpetMallEventEnum.ClickMypageCustomercenter
    if (eventName) tracker.triggerCustomEvent(eventName)
  }

  return (
    <Col span={8}>
      <LinkButton url={url} onClick={_onClickEvent}>
        <Row justify="center">
          <ImageAsset name={iconName} size={24} svg />
        </Row>
        <ItemTitleRow justify="center">
          <TextLegacy>{title}</TextLegacy>
        </ItemTitleRow>
      </LinkButton>
    </Col>
  )
}

const StyledTabRow = styled(Row)`
  padding: 25px 0;
  background-color: ${(props) => props.theme.color.grayWhite};
`

const ItemTitleRow = styled(Row)`
  margin-top: 4px;
`

export default MyPageCustomerCenterTab
