import { FC } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { Modal, TextLegacy } from '@/components/common'
import { ModalBaseProps } from '@/components/common/modals/ModalBase'
import { Omit } from '@/utils/utilTs'

type ModalNoticeProps<T> = {} & Omit<ModalBaseProps<T>, 'buttonType'>

const ModalNotice: FC<ModalNoticeProps<any>> = (props) => {
  const { useModalProps, okText = '확인' } = props
  const noticeText = useModalProps.actionParams?.noticeText || '-'
  const subText = useModalProps.actionParams?.subText

  return (
    <Modal buttonType="ACTION" okText={okText} {...props}>
      <StyledRow justify="center">
        <Col span={24}>
          <Row justify="center">
            <TextLegacy align="center" weight="bold" size={{ xs: 16, md: 18 }}>
              {noticeText}
            </TextLegacy>
          </Row>
        </Col>

        {subText && (
          <Col span={24}>
            <SubTextRow justify="center">
              <TextLegacy size={{ xs: 14, md: 16 }} align="center">
                {subText}
              </TextLegacy>
            </SubTextRow>
          </Col>
        )}
      </StyledRow>
    </Modal>
  )
}

const StyledRow = styled(Row)`
  padding: 40px 0px;
`

const SubTextRow = styled(Row)`
  margin-top: 10px;
`

export default ModalNotice
