import copy from 'copy-to-clipboard'
import { isIOS as detectIsIos, isMacOs as detectIsMacOs } from 'react-device-detect'
import { PlatformDetailEnum } from '@/constants/platform-detail.enum'

export const enableScroll = (enable: boolean) => {
  const body = document.body

  if (enable) {
    body.style.removeProperty('overflow')
    body.style.removeProperty('width')
  } else {
    body.style.cssText = `
      overflow: hidden;
      width: 100%;
    `
  }
}

export function isProduction(): boolean {
  return process.env.FITPETMALL_ENV === 'production'
}

export const isServerSide = () => typeof window === 'undefined'

const isClient = () => typeof window !== 'undefined'

export const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0

const PlatformPattern = {
  RN_iOS: /fitpetApp_ios/,
  RN_Android: /fitpetApp_android/,
  iOS: /fitpetIosApp/,
  Android: /fitpetAndroidApp/,
  AndroidWeb: /Android/,
  iOSWeb: /iPhone/,
  MobileWeb: /Mobile|mobile/,
}

export const getPlatform = (userAgent: string = navigator?.userAgent) => {
  if (PlatformPattern.RN_iOS.test(userAgent)) {
    return PlatformDetailEnum.RnIos
  }
  if (PlatformPattern.RN_Android.test(userAgent)) {
    return PlatformDetailEnum.RnAndroid
  }
  if (PlatformPattern.iOS.test(userAgent)) {
    return PlatformDetailEnum.IosApp
  }
  if (PlatformPattern.Android.test(userAgent)) {
    return PlatformDetailEnum.AndroidApp
  }
  if (PlatformPattern.AndroidWeb.test(userAgent)) {
    return PlatformDetailEnum.AndroidWeb
  }
  if (PlatformPattern.iOSWeb.test(userAgent)) {
    return PlatformDetailEnum.IosWeb
  }
  if (!PlatformPattern.MobileWeb.test(userAgent)) {
    return PlatformDetailEnum.PcWeb
  }
  // PC 구분 어떻게?
  return PlatformDetailEnum.Etc
}

export const isInApp = () => isClient() && window?.isInApp && window.isInApp()
export const isInAndroid = () => isClient() && [PlatformDetailEnum.AndroidApp].includes(getPlatform())
export const isInIos = () => isClient() && [PlatformDetailEnum.IosApp].includes(getPlatform())
const isAndroidWeb = () => isClient() && [PlatformDetailEnum.AndroidWeb].includes(getPlatform())
export const isMobileWeb = () => isAndroidWeb() || isIOSWeb()
export const isIOSWeb = () => isClient() && [PlatformDetailEnum.IosWeb].includes(getPlatform())
export const isInRnAndroid = () => isClient() && [PlatformDetailEnum.RnAndroid].includes(getPlatform())
export const isIOS = () => detectIsIos
export const isMacOs = () => detectIsMacOs

let orderReturnFormImages: File[] | undefined
export const setOrderReturnFormImages = (files: File[] | undefined) => {
  orderReturnFormImages = files
}

export const getOrderReturnFormImages = () => {
  return orderReturnFormImages
}

// base를 대상으로 target이 부분집합인지 감별
export const isSubset = (base: any[], target: any[]) => {
  const uniqueItemsBase = [...new Set(base)]
  const uniqueItemsTarget = [...new Set(target)]
  return uniqueItemsTarget.every((item) => uniqueItemsBase.includes(item))
}

// base 와 target 이 교집이 있는지 감별
export const isInterSection = (base: any[], target: any[]) => {
  const uniqueItemsBase = [...new Set(base)]
  const uniqueItemsTarget = [...new Set(target)]
  return uniqueItemsTarget.some((item) => uniqueItemsBase.includes(item))
}

// 클립보드 copy
export const copy2clipboard = (text: string) => {
  copy(text)
}
