import { localUserToken } from '@/utils/utilLocalStorage'

const UtilToken = {
  getLocalStorageToken: () => {
    const userToken = localUserToken.load()
    return userToken !== undefined || userToken ? userToken.token : ''
  },
}

export { UtilToken }
