import isEmpty from 'lodash/isEmpty'
import { EstimateOrderRequestDtoV1, SearchProductResponseDtoV2 } from '@/apis/rest/generated/types'
import { FilterModalSelectListType } from '@/components/domains/search/types/filterModal.type'
import { SocialAuthType, SocialServiceUserProfileType } from '@/constants/legacy/constType'
import { CartProductOptionType } from '@/containers/types/CartProductOption'
import { CartShippingGroupType } from '@/containers/types/CartShippingGroup'
import { AdPetType } from '@/graphql/generated/schemas'
import { LocalUserTokenType } from '@/types/local-user-token.type'
import { isNowBefore } from '@/utils/utilDate'

export const KEY = {
  USER_TOKEN: 'USER_TOKEN',
  BANNER_TOP_SLIM_HIDDEN_DATE: 'BANNER_TOP_SLIM_HIDDEN_DATE',
  PET_TYPE: 'PET_TYPE',
  SHOW_PET_TYPE_TOOLTIP: 'SHOW_PET_TYPE_TOOLTIP',
  SHOW_HOSPITAL_TOOLTIP: 'SHOW_HOSPITAL_TOOLTIP',
  TEMP_SOCIAL_USER_PROFILE: 'TEMP_SOCIAL_USER_PROFILE',
  TEMP_NAVER_SOCIAL_USER_PROFILE: 'TEMP_NAVER_SOCIAL_USER_PROFILE',
  NON_LOGIN_CART_ITEMS: 'NON_LOGIN_CART_ITEMS',
  LOCAL_CART_PRODUCT_OPTIONS: 'LOCAL_CART_PRODUCT_OPTIONS',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  TEMP_TRANSACTION_ID: 'TEMP_TRANSACTION_ID',
  LOGIN_RETURN_URL: 'LOGIN_RETURN_URL',
  DEVICE_UUID: 'DEVICE_UUID',
  LOGIN_STORE_ID: 'LOGIN_STORE_ID',
  LOGIN_AUTO: 'LOGIN_AUTO',
  CART_SHIPPING_GROUPS: 'CART_SHIPPING_GROUPS',
  RECENTLY_PRODUCT_BY_PET_TYPE: 'RECENTLY_PRODUCT_BY_PET_TYPE',
  VIEWED_PRODUCT_LIST: 'VIEWED_PRODUCT_LIST',
  POST_CODE: 'POST_CODE',
  LIKE_PRODUCT_LIST: 'LIKE_PRODUCT_LIST',
  RECENTLY_SEARCH_KEYWORD_LIST: 'RECENTLY_SEARCH_KEYWORD_LIST',
  SEARCH_FILTER_SELECT_LIST: 'SEARCH_FILTER_SELECT_LIST',
  SEARCH_FILTER_LIST: 'SEARCH_FILTER_LIST',
  AB_TEST_FLAG: 'AB_TEST_FLAG',
  NAVER_AUTH_FOR_LOGIN: 'NAVER_AUTH_FOR_LOGIN',
  INVITATION_CODE: 'INVITATION_CODE',
  MEMBERSHIP_INVITATION_CODE: 'MEMBERSHIP_INVITATION_CODE',
  MEMBERSHIP_INVITE_TOOLTIP_HIDDEN_DATE: 'MEMBERSHIP_INVITE_TOOLTIP_HIDDEN_DATE',
  MEMBERSHIP_BUTTON_TOOLTIP_HIDDEN_DATE: 'MEMBERSHIP_BUTTON_TOOLTIP_HIDDEN_DATE', // 홈화면에서 멤버십 버튼에 표시되는 툴팁
  MEMBERSHIP_HAS_CANCELED: 'MEMBERSHIP_HAS_CANCELED', // 이전에 멤버십을 해지 했었는지 여부. 멤버십 가입 화면에서 설정하고, 멤버십 가입 완료 시 초기화 함
  LAST_CONNECTED_DATE: 'LAST_CONNECTED_DATE',
  LAST_ACTION_HOME_BOTTOM_BADGE: 'LAST_ACTION_HOME_BOTTOM_BADGE',
  LIKE_SYNC: 'LIKE_SYNC', // 앱환경에서 홈과 다른 페이지의 찜상태 동기화를 위한 플래그
  NEW_LISTING: 'NEW_LISTING',
  ESTIMATE_TARGET: 'ESTIMATE_TARGET',
}

function save(k: string, v: any) {
  if (typeof localStorage === 'undefined') return
  localStorage.setItem(k, JSON.stringify(v))
}

function load(k: string) {
  if (typeof localStorage === 'undefined') return {}
  const value = localStorage.getItem(k)
  if (value && value !== 'undefined') {
    return JSON.parse(value)
  }
  return {}
}

function remove(k: string) {
  if (typeof localStorage === 'undefined') return
  localStorage.removeItem(k)
}

export const localRemoveAll = async () => {
  if (typeof localStorage === 'undefined') return
  const REMOVE_KEYS = [
    KEY.USER_TOKEN,
    KEY.TEMP_SOCIAL_USER_PROFILE,
    KEY.TEMP_NAVER_SOCIAL_USER_PROFILE,
    KEY.NON_LOGIN_CART_ITEMS,
    KEY.LOCAL_CART_PRODUCT_OPTIONS,
    KEY.PAYMENT_METHOD,
    KEY.TEMP_TRANSACTION_ID,
    KEY.CART_SHIPPING_GROUPS,
    KEY.LIKE_PRODUCT_LIST,
    KEY.NAVER_AUTH_FOR_LOGIN,
    KEY.MEMBERSHIP_HAS_CANCELED,
  ]
  REMOVE_KEYS.forEach((key) => localStorage.removeItem(key))
  // localStorage.clear()
}

export const localPopupBannerHiddenDate = {
  save: (v: string) => save(KEY.BANNER_TOP_SLIM_HIDDEN_DATE, v),
  load: () => {
    const res = load(KEY.BANNER_TOP_SLIM_HIDDEN_DATE)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.BANNER_TOP_SLIM_HIDDEN_DATE),
}

export const localShowPetTypeTooltip = {
  save: (v: string) => save(KEY.SHOW_PET_TYPE_TOOLTIP, v),
  load: () => {
    const res = load(KEY.SHOW_PET_TYPE_TOOLTIP)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.SHOW_PET_TYPE_TOOLTIP),
}

export const localShowHospitalTooltip = {
  save: (v: string) => save(KEY.SHOW_HOSPITAL_TOOLTIP, v),
  load: () => {
    const res = load(KEY.SHOW_HOSPITAL_TOOLTIP)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.SHOW_HOSPITAL_TOOLTIP),
}

export const localLoginReturnUrl = {
  save: (v: any) => save(KEY.LOGIN_RETURN_URL, v),
  load: () => {
    const res = load(KEY.LOGIN_RETURN_URL)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.LOGIN_RETURN_URL),
}

export const localLoginStoreId = {
  save: (v: string) => save(KEY.LOGIN_STORE_ID, v),
  load: (): string | undefined => {
    const res = load(KEY.LOGIN_STORE_ID)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.LOGIN_STORE_ID),
}

export const localAutoLogin = {
  save: (v: boolean) => save(KEY.LOGIN_AUTO, JSON.stringify(v)),
  load: (): boolean | undefined => {
    const res = load(KEY.LOGIN_AUTO)
    return isEmpty(res) ? undefined : res === 'true'
  },
  remove: () => remove(KEY.LOGIN_AUTO),
}

export const localDeviceUuid = {
  save: (v: string) => save(KEY.DEVICE_UUID, v),
  load: (): string | undefined => {
    const res = load(KEY.DEVICE_UUID)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.DEVICE_UUID),
}

export const localTempTransactionId = {
  save: (v: string) => save(KEY.TEMP_TRANSACTION_ID, v),
  load: (): string | undefined => {
    const res = load(KEY.TEMP_TRANSACTION_ID)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.TEMP_TRANSACTION_ID),
}

export const localCartProductOptionsStorage = {
  save: (v: CartProductOptionType[]) => save(KEY.LOCAL_CART_PRODUCT_OPTIONS, v),
  load: (): CartProductOptionType[] => {
    const res = load(KEY.LOCAL_CART_PRODUCT_OPTIONS)
    return isEmpty(res) ? [] : res
  },
  remove: () => remove(KEY.LOCAL_CART_PRODUCT_OPTIONS),
}

export const localTempSocialUserProfile = {
  save: (v: SocialServiceUserProfileType) => save(KEY.TEMP_SOCIAL_USER_PROFILE, v),
  load: () => {
    const res = load(KEY.TEMP_SOCIAL_USER_PROFILE)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.TEMP_SOCIAL_USER_PROFILE),
}

export const localTempNaverSocialUserProfile = {
  save: (v: SocialServiceUserProfileType) => save(KEY.TEMP_NAVER_SOCIAL_USER_PROFILE, v),
  load: () => {
    const res = load(KEY.TEMP_NAVER_SOCIAL_USER_PROFILE)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.TEMP_NAVER_SOCIAL_USER_PROFILE),
}

export const localUserToken = {
  save: (v: LocalUserTokenType) => save(KEY.USER_TOKEN, v),
  load: (): LocalUserTokenType | undefined => {
    try {
      const res = load(KEY.USER_TOKEN)
      if (isEmpty(res)) return undefined

      const { expiredAt } = res
      const _isExpired = !isNowBefore(expiredAt)
      const autoLogin = localAutoLogin.load()

      if (_isExpired && !autoLogin) {
        localUserToken.remove()
        return undefined
      }

      return res
    } catch (e) {
      return undefined
    }
  },
  remove: () => remove(KEY.USER_TOKEN),
}

export const localPetType = {
  save: (v: AdPetType) => save(KEY.PET_TYPE, v),
  load: () => {
    const res = load(KEY.PET_TYPE)
    return isEmpty(res) ? undefined : (res as AdPetType)
  },
  remove: () => remove(KEY.PET_TYPE),
}

export const localCartShippingGroups = {
  save: (v: CartShippingGroupType[]) => save(KEY.CART_SHIPPING_GROUPS, v),
  load: () => {
    const res = load(KEY.CART_SHIPPING_GROUPS)
    return isEmpty(res) ? undefined : (res as CartShippingGroupType[])
  },
  remove: () => remove(KEY.CART_SHIPPING_GROUPS),
}

export const localPostCode = {
  save: (v: any) => save(KEY.POST_CODE, v),
  load: () => {
    const res = load(KEY.POST_CODE)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.POST_CODE),
}

export const localRecentlyProductItems = {
  save: (v: any) => save(KEY.VIEWED_PRODUCT_LIST, v),
  load: () => {
    const res = load(KEY.VIEWED_PRODUCT_LIST)
    return isEmpty(res) ? [] : res
  },
}

export const localRecentlyProductByPetType = {
  save: (v: any) => save(KEY.RECENTLY_PRODUCT_BY_PET_TYPE, v),
  load: () => {
    const res = load(KEY.RECENTLY_PRODUCT_BY_PET_TYPE)
    const defaultState = {
      [AdPetType.Dog]: '',
      [AdPetType.Cat]: '',
    }
    return isEmpty(res) ? defaultState : res
  },
}

export const localLikeProductList = {
  save: (v: any) => save(KEY.LIKE_PRODUCT_LIST, v),
  load: () => {
    const res = load(KEY.LIKE_PRODUCT_LIST)
    return isEmpty(res) ? [] : res
  },
  remove: () => remove(KEY.LIKE_PRODUCT_LIST),
}

export const localRecentSearchKeywordList = {
  save: (v: any) => save(KEY.RECENTLY_SEARCH_KEYWORD_LIST, v),
  load: () => {
    const res = load(KEY.RECENTLY_SEARCH_KEYWORD_LIST)
    return isEmpty(res) ? [] : res
  },
  remove: () => remove(KEY.RECENTLY_SEARCH_KEYWORD_LIST),
}

export const localSearchFilterSelectList = {
  save: (v: FilterModalSelectListType) => save(KEY.SEARCH_FILTER_SELECT_LIST, v),
  load: () => {
    const res = load(KEY.SEARCH_FILTER_SELECT_LIST)
    const defaultState: FilterModalSelectListType = {
      category: [],
      brand: [],
      price: {
        minPrice: 0,
        maxPrice: 0,
      },
    }
    return isEmpty(res) ? defaultState : res
  },
  remove: () => remove(KEY.SEARCH_FILTER_SELECT_LIST),
}

export const localSearchFilterList = {
  save: (v: SearchProductResponseDtoV2) => save(KEY.SEARCH_FILTER_LIST, v),
  load: () => {
    const res = load(KEY.SEARCH_FILTER_LIST)
    const defaultState: SearchProductResponseDtoV2 = {
      categories: [],
      brands: [],
      minPrice: 0,
      maxPrice: 0,
      totalCount: 0,
      soldOutCount: 0,
    }
    return isEmpty(res) ? defaultState : res
  },
  remove: () => remove(KEY.SEARCH_FILTER_LIST),
}

export const localMembershipInvitationCode = {
  save: (invitationCode: string) => save(KEY.MEMBERSHIP_INVITATION_CODE, invitationCode),
  load: () => {
    const res = load(KEY.MEMBERSHIP_INVITATION_CODE)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.MEMBERSHIP_INVITATION_CODE),
}

export const localSocialAuthType = {
  save: (v: SocialAuthType) => save(KEY.NAVER_AUTH_FOR_LOGIN, v),
  load: () => {
    const res = load(KEY.NAVER_AUTH_FOR_LOGIN)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.NAVER_AUTH_FOR_LOGIN),
}

export const localInvitationCode = {
  save: (v: string) => save(KEY.INVITATION_CODE, v),
  load: () => {
    const res = load(KEY.INVITATION_CODE)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.INVITATION_CODE),
}

export const localMembershipInviteTooltipHiddenDate = {
  save: (v: string) => save(KEY.MEMBERSHIP_INVITE_TOOLTIP_HIDDEN_DATE, v),
  load: () => {
    const res = load(KEY.MEMBERSHIP_INVITE_TOOLTIP_HIDDEN_DATE)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.MEMBERSHIP_INVITE_TOOLTIP_HIDDEN_DATE),
}

export const localMembershipHasCanceled = {
  save: (v: boolean) => save(KEY.MEMBERSHIP_HAS_CANCELED, v),
  load: () => {
    const res = load(KEY.MEMBERSHIP_HAS_CANCELED)
    return res === undefined ? undefined : res === 'true' || !!res
  },
  remove: () => remove(KEY.MEMBERSHIP_HAS_CANCELED),
}

export const localLastConnectedDate = {
  save: (v: string) => save(KEY.LAST_CONNECTED_DATE, v),
  load: () => {
    const res = load(KEY.LAST_CONNECTED_DATE)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.LAST_CONNECTED_DATE),
}

export const localLastActionHomeBottomBadge = {
  save: (v: string) => save(KEY.LAST_ACTION_HOME_BOTTOM_BADGE, v),
  load: () => {
    const res = load(KEY.LAST_ACTION_HOME_BOTTOM_BADGE)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.LAST_ACTION_HOME_BOTTOM_BADGE),
}

export const localEstimateTarget = {
  save: (v: EstimateOrderRequestDtoV1) => save(KEY.ESTIMATE_TARGET, v),
  load: () => {
    const res = load(KEY.ESTIMATE_TARGET)
    return isEmpty(res) ? undefined : (res as EstimateOrderRequestDtoV1)
  },
  remove: () => remove(KEY.ESTIMATE_TARGET),
}
