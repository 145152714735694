import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css, useTheme } from 'styled-components'
import { Image } from '@/components/common/images/Image'
import { Typo, TypoVariant } from '@/components/common/typography'
import { BasicLabel } from '@/components/domains/products/new-product-card/BasicLabel'
import LikeButton from '@/components/domains/products/new-product-card/LikeButton'
import ProductChevronRightIcon from '@/components/domains/products/new-product-card/ProductChevronRightIcon'
import { RankingLabel } from '@/components/domains/products/new-product-card/RankingLabel'
import {
  THUMBNAIL_SIZES,
  THUMBNAIL_STATES,
  ThumbnailDirection,
  ThumbnailSize,
  ThumbnailState,
  ThumbnailType,
} from '@/components/domains/products/new-product-card/new-product-card.type'
import { useDebounceCallback } from '@/containers/hooks/common/useDebounceCallback.hook'
import { useToggleLike } from '@/containers/hooks/like/useToggleLike'
import { UserContainer } from '@/containers/users/UserContainer'
import { doSetToggleModalLogin } from '@/stores/reduxUI'
import { RootState } from '@/stores/store'
import { encodeId } from '@/utils/utilApi'

const thumbnailSizeSettings: Record<ThumbnailSize, { stateTypoVariant: TypoVariant; descTypoVariant: TypoVariant }> = {
  [THUMBNAIL_SIZES['xs']]: {
    stateTypoVariant: TypoVariant.Body5Bold,
    descTypoVariant: TypoVariant.Body5Regular,
  },
  [THUMBNAIL_SIZES['sm']]: {
    stateTypoVariant: TypoVariant.Body5Bold,
    descTypoVariant: TypoVariant.Body5Regular,
  },
  [THUMBNAIL_SIZES['md']]: {
    stateTypoVariant: TypoVariant.Body3Bold,
    descTypoVariant: TypoVariant.Body5Regular,
  },
  [THUMBNAIL_SIZES['lg']]: {
    stateTypoVariant: TypoVariant.Body3Bold,
    descTypoVariant: TypoVariant.Body5Regular,
  },
  [THUMBNAIL_SIZES['half']]: {
    stateTypoVariant: TypoVariant.Body1Bold,
    descTypoVariant: TypoVariant.Body5Regular,
  },
  [THUMBNAIL_SIZES['full']]: {
    stateTypoVariant: TypoVariant.Body1Bold,
    descTypoVariant: TypoVariant.Body5Regular,
  },
}

export const NewThumbnail = ({
  productId,
  productName,
  productPromotionScheme,
  size,
  state,
  direction = 'vertical',
  imageUrl,
  isLiked: initialLiked,
  isRealSoldOut,
  isNotAvailable,
  rankingNumber,
  basicLabel,
  callbackLike,
  trackingData: { price, discountRate, discountPrice, reviewCount, reviewScore },
}: ThumbnailType) => {
  const { color } = useTheme()
  const dispatch = useDispatch()
  const { me } = UserContainer.useContainer()
  const { ref } = useInView({
    triggerOnce: true,
  })

  const isShowLikeButton = state !== THUMBNAIL_STATES.loading && size !== THUMBNAIL_SIZES['xs']
  const restockNotificationSizes: ThumbnailSize[] = [
    THUMBNAIL_SIZES['md'],
    THUMBNAIL_SIZES['lg'],
    THUMBNAIL_SIZES['half'],
  ]

  const likedList = useSelector((rootState: RootState) => rootState.reduxDataReducers.likeProductList)
  const { toggleLike } = useToggleLike({
    productName,
    price,
    discountRate,
    discountPrice,
    reviewCount,
    reviewScore,
  })
  const [isLiked, setIsLiked] = useState(initialLiked)

  const handleToggleLike = async () => {
    if (!me) {
      dispatch(doSetToggleModalLogin(true))
      return
    }
    const isPrevLiked = isLiked
    setIsLiked((prev) => !prev)
    try {
      await toggleLike({ productId, promotion: productPromotionScheme })
      if (callbackLike) {
        callbackLike()
      }
    } catch (error) {
      setIsLiked(isPrevLiked)
    }
  }

  const { debouncedFunction } = useDebounceCallback({
    callback: handleToggleLike,
    delay: 300,
  })

  useEffect(() => {
    const encodedId = encodeId('ProductType', productId)
    const isProductIdExists = likedList.some((item) => item.productId === encodedId)
    setIsLiked(isProductIdExists)
  }, [productId, likedList])

  return (
    <StyledThumbnailWrapper size={size} state={state} direction={direction} className="thumbnails" ref={ref}>
      <Image className="thumbnails__image" src={imageUrl} alt={productName} />
      {!isNotAvailable && isRealSoldOut && (
        <div className="thumbnails__text">
          <Typo variant={thumbnailSizeSettings[size].stateTypoVariant} color={color.grayWhite}>
            입고 예정
          </Typo>
          {restockNotificationSizes.includes(size) && (
            <div className="thumbnails__restock-alarm">
              <Typo variant={thumbnailSizeSettings[size].descTypoVariant} color={color.grayWhite}>
                재입고 알림 받기
              </Typo>
              <ProductChevronRightIcon />
            </div>
          )}
        </div>
      )}
      {isNotAvailable && (
        <div className="thumbnails__text">
          <Typo variant={thumbnailSizeSettings[size].stateTypoVariant} color={color.grayWhite}>
            상품 준비중
          </Typo>
        </div>
      )}
      {state === THUMBNAIL_STATES.basicLabel && basicLabel && (
        <span className="thumbnails__basic-label">
          <BasicLabel
            value={basicLabel.value}
            fontColor={basicLabel.fontColor}
            variant={basicLabel.variant}
            backgroundColor={basicLabel.backgroundColor}
          />
        </span>
      )}
      {state === THUMBNAIL_STATES.rankingLabel && rankingNumber && (
        <span className="thumbnails__ranking-label">
          <RankingLabel value={rankingNumber} />
        </span>
      )}
      {isShowLikeButton && <LikeButton onToggle={debouncedFunction} isLiked={isLiked} />}
    </StyledThumbnailWrapper>
  )
}

const StyledThumbnailWrapper = styled.div<{
  size: ThumbnailSize
  state: ThumbnailState
  direction?: ThumbnailDirection
}>`
  position: relative;
  overflow: hidden;
  border-radius: 0.8rem;
  border: 1px solid rgba(0, 0, 0, 0.03);
  width: 100%;
  background-color: ${({
    theme: {
      color: { gray50 },
    },
  }) => gray50};
  ${({ size }) =>
    size === THUMBNAIL_SIZES['full'] &&
    css`
      overflow: unset;
      border: none;
    `};

  .thumbnails {
    &__image {
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
    }
    &__text {
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      ${({ size }) => {
        if (size === THUMBNAIL_SIZES['xs']) {
          return css`
            position: absolute;
            height: 2.5rem;
            bottom: 0;
          `
        }
        if (size === THUMBNAIL_SIZES['full']) {
          return css`
            display: none;
          `
        }
      }}
      ${({ state }) => state === THUMBNAIL_STATES.soldOut && css``}
    }
    &__restock-alarm {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
    }
    &__basic-label {
      position: absolute;
      top: 0.8rem;
      left: 0.8rem;
      z-index: 2;
    }
    &__ranking-label {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }
`
