import { FC, ReactNode } from 'react'
import { List } from 'antd'
import { ListProps } from 'antd/lib/list'

type ListBaseProps<T> = ListProps<T> & {
  emptyText?: ReactNode
}

const ListBase: FC<ListBaseProps<any>> = ({ renderItem, dataSource, emptyText, ...restProps }) => {
  return (
    <List
      {...restProps}
      pagination={false}
      dataSource={dataSource}
      renderItem={(item: any, index: number) => {
        return renderItem && renderItem(item, index)
      }}
      locale={{
        emptyText: emptyText || <></>,
      }}
    />
  )
}

export default ListBase
