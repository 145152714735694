/**
 * 바팀시트에서 장바구니 담기, 바로구매, 장바구니 보러 가기 등의 이벤트에서 활용
 *
 * 최근 본 상품 recentproduct
 * 찜한 상품 likeproduct
 * 자주 산 상품 frequentproduct
 * 검색홈 search_home
 * 검색결과 search_result
 * 카테고리 category
 * 장바구니_찜한상품(탭) cart_likeproduct
 * 장바구니_자주산상품(탭) cart_frequentproduct
 * 바텀시트 bottomsheet
 */

const ProductLocation = {
  RecentProduct: 'RecentProduct',
  ProductDetail: 'ProductDetail',
  LikeProduct: 'LikeProduct',
  FrequentProduct: 'FrequentProduct',
  SearchHome: 'SearchHome',
  SearchResult: 'SearchResult',
  SearchResultFrequent: 'SearchResultFrequent',
  Category: 'Category',
  CartLikeProduct: 'CartLikeProduct',
  CartFrequentProduct: 'CartFrequentProduct',
  BottomSheet: 'BottomSheet',
  CartBottom: 'CartBottom',
  MoreList: 'MoreList',
} as const

type ProductLocation = typeof ProductLocation[keyof typeof ProductLocation]

const ProductLocationTrackerValue: { [key in ProductLocation]: string } = {
  RecentProduct: 'favorite_recentproduct',
  ProductDetail: 'productdetail',
  LikeProduct: 'favorite_likeproduct',
  FrequentProduct: 'frequentproduct',
  SearchHome: 'search_home',
  SearchResult: 'search_result',
  SearchResultFrequent: 'search_result_frequent',
  Category: 'category',
  CartLikeProduct: 'cart_likeproduct',
  CartFrequentProduct: 'cart_frequentproduct',
  BottomSheet: 'bottomsheet',
  CartBottom: 'cart_bottom',
  MoreList: 'more_list',
} as const

export { ProductLocation, ProductLocationTrackerValue }
