import { FC } from 'react'
import { Col, Row } from 'antd'
import { ImageAsset, TextEllipsis } from '@/components/common'
import { SocialServiceSocialType } from '@/graphql/generated/schemas'
import { getSocialCircleIcon } from '@/utils/utilLogin'

type SocialEmailAccountProps = {
  socialType?: SocialServiceSocialType
  email: string
}

const SocialEmailAccount: FC<SocialEmailAccountProps> = ({ socialType, email }) => {
  return (
    <Row gutter={10} align="middle" wrap={false}>
      <Col>
        <ImageAsset name={getSocialCircleIcon(socialType)} size={24} svg />
      </Col>
      <Col>
        <TextEllipsis lineNumber={1}>{email}</TextEllipsis>
      </Col>
    </Row>
  )
}

export default SocialEmailAccount
