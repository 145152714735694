import { UseInfiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'
import { getFrequentlyBought, getGetFrequentlyBoughtQueryKey } from '@/apis/rest/generated/hooks'
import { GetFrequentlyBoughtParams } from '@/apis/rest/generated/types'
import { UserContainer } from '@/containers/users/UserContainer'

interface UseFrequentlyBoughtProps extends GetFrequentlyBoughtParams {
  options?: Omit<UseInfiniteQueryOptions<any, any, any, any, any>, 'queryKey'>
  keySuffix?: string
}

const useFrequentlyBought = ({ page = 1, size = 8, options = {}, keySuffix }: UseFrequentlyBoughtProps) => {
  const { me } = UserContainer.useContainer()
  const { data, fetchNextPage, isLoading, hasNextPage, isFetching } = useInfiniteQuery(
    [
      ...getGetFrequentlyBoughtQueryKey({
        page,
        size,
      }),
      ...(keySuffix ? [keySuffix] : []),
    ],
    ({ pageParam = page }) => getFrequentlyBought({ page: pageParam, size }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.length < size) {
          return
        }
        return lastPage.page.page + 1
      },
      enabled: !!me,
      ...options,
    }
  )

  const frequentlyBoughtProducts = data?.pages.map((page) => page.data).flat()

  return {
    data: frequentlyBoughtProducts || [],
    fetchNextPage,
    isLoading,
    isFetching,
    hasNextPage,
  }
}

export default useFrequentlyBought
