import { FC } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import TextLegacy, { TextBaseProps } from '@/components/common/texts/TextBase'

type BulletedTextProps = {
  text: string
  textStyle?: TextBaseProps
}

const BulletedText: FC<BulletedTextProps> = ({ text, textStyle }) => {
  return (
    <Row>
      <BulletCol span="auto">
        <TextLegacy {...textStyle}>·</TextLegacy>
      </BulletCol>
      <Col span={23}>
        <TextLegacy {...textStyle}>{text}</TextLegacy>
      </Col>
    </Row>
  )
}

const BulletCol = styled(Col)`
  padding-right: 5px;
`

export default BulletedText
