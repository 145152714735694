import { FC } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { RootState } from '@/stores/store'

type ApiLoaderProps = {}

const ApiLoader: FC<ApiLoaderProps> = () => {
  const visible = useSelector((rootState: RootState) => rootState.reduxUIReducers.visibleLoader)
  const { asPath } = useRouter()

  // 장바구니에서 로더 2개 표기로 인한 탭 2개 예외처리
  const disabledPaths = ['/cart?tab=like', '/cart?tab=frequently']

  if (!visible || disabledPaths.includes(asPath)) {
    return null
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 30, color: GlobalColorEnum.Blue500 }} spin />
  return (
    <StyledContainer>
      <StyledFixed>
        <Spin indicator={antIcon} />
      </StyledFixed>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledFixed = styled.div`
  margin: 0;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
`

export default ApiLoader
