import { LayoutMain } from '@/components/domains/layouts'
import ROUTES from '@/constants/legacy/constRoutes'
import { FitpetMallProvider } from '@/containers/contexts/FitpetmallProvider'
import { useAppEventTracker } from '@/containers/event-tracker/app.event-tracker'
import { COMMON_QUERY_GQLS } from '@/containers/gqls/commons/queries'
import { usePetType, useUserInfo } from '@/containers/hooks'
import { useScrollRestoration } from '@/containers/hooks/common/useScrollRestoration.hook'
import { useAbGroup } from '@/containers/hooks/useAbGroup'
import { useGetLikeFromApp } from '@/containers/hooks/useGetLikeFromApp'
import useLikeProduct from '@/containers/hooks/useLikeProduct'
import { ModelInitData } from '@/containers/models'
import { UserContainer } from '@/containers/users/UserContainer'
import { queryClient } from '@/lib/queryClient'
import { doSetInitData } from '@/stores/reduxData'
import { RootState, wrapperStore } from '@/stores/store'
import { GlobalTheme } from '@/styles/globalTheme'
import '@/styles/globals.css'
import '@/styles/reset.css'
import '@/styles/slick-theme.css'
import '@/styles/slick.css'
import { shouldForwardProp } from '@/utils/should-forward-props'
import { useApollo } from '@/utils/utilApolloClient'
import { appBridgeLogin, appBridgeLogout, isAppStorageDisabled, isInAppFlag } from '@/utils/utilBridge'
import { enableScroll } from '@/utils/utilCommon'
import { ApolloProvider, useLazyQuery } from '@apollo/client'
import { Hydrate, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import 'fitpet-console'
import 'moment/locale/ko'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import App from 'next/app'
import { useRouter, withRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StyleSheetManager, ThemeProvider } from 'styled-components'

export const InitSetting = () => {
  const router = useRouter()
  useScrollRestoration(router)
  const { initTokenSignIn, clearUserInfo } = UserContainer.useContainer()
  const { hasSignedIn } = useUserInfo
  const dispatch = useDispatch()
  const { likeProductListQuery } = useLikeProduct()
  const { syncAppPetType } = usePetType()
  const initData = useSelector((rootState: RootState) => rootState.reduxDataReducers.initData)
  const { pathname } = useRouter()
  const { trackSearchEngineInflowEvent } = useAppEventTracker()
  useGetLikeFromApp()
  useAbGroup()

  const [initDataQuery] = useLazyQuery(COMMON_QUERY_GQLS.INIT_DATA, {
    onCompleted: (data) => {
      if (data && data.initData) {
        const initData = new ModelInitData(data.initData)
        dispatch(doSetInitData({ ...initData }))
      }
    },
    fetchPolicy: 'network-only',
  })

  const bridgeLogin = appBridgeLogin()
  const bridgeLogout = appBridgeLogout()

  useEffect(() => {
    trackSearchEngineInflowEvent()
    if (!initData) {
      initDataQuery()
    }

    /*
     * Todo : enableScroll 제거 해야합니다.
     * 페이지가 바뀌면 모달에서 스크롤 제거하는 문제를 초기화 합니다.
     * enableScroll 자체가 좋지 않습니다.
     */
    enableScroll(true)
  }, [pathname])

  useEffect(() => {
    if (isInAppFlag) {
      window.onlogin = () => {
        bridgeLogin(window.getToken())
        initTokenSignIn()
      }
      window.onlogout = () => {
        bridgeLogout()
        clearUserInfo()
      }
    }
    return () => {
      window.onlogin = null
      window.onlogout = null
    }
  }, [])

  useEffect(() => {
    initTokenSignIn()
  }, [])

  useEffect(() => {
    if (pathname === ROUTES.ORDER.BASE) {
      return
    }
    if (hasSignedIn()) {
      likeProductListQuery()
    }
  }, [hasSignedIn()])

  /*
   * DeepLink가 webview 로 바로떨어지는 화면에서는 petType Query가 있습니다.
   * 그렇지 않은 화면인 경우 항상 appStorage 에서 펫타입의 싱크를 맞춰줍니다.
   */
  useEffect(() => {
    if (!isAppStorageDisabled) {
      syncAppPetType()
    }
  }, [])

  return null
}

function MyApp({ Component, pageProps }: AppProps) {
  const client = useApollo(pageProps)
  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <UserContainer.Provider>
            <FitpetMallProvider>
              <InitSetting />
              <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                <ThemeProvider theme={GlobalTheme}>
                  <LayoutMain>
                    <Component {...pageProps} />
                  </LayoutMain>
                </ThemeProvider>
              </StyleSheetManager>
            </FitpetMallProvider>
          </UserContainer.Provider>
        </Hydrate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ApolloProvider>
  )
}

MyApp.getInitialProps = async (ctx: any) => {
  let pageProps

  pageProps = await App.getInitialProps(ctx)
  return { ...pageProps }
}

export default wrapperStore.withRedux(withRouter(appWithTranslation(MyApp)))
