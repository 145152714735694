import { PaymentMethodTypeKorean } from '@/constants/legacy/constData'
import { OrderStatusTypeEnum } from '@/constants/order-status-type.enum'
import { PaymentMethodKoreanEnum } from '@/constants/payment-method-korean.enum'
import { PaymentMethodTypeEnum } from '@/constants/payment-method-type.enum'
import { ResponsibilityEnum } from '@/constants/responsibility.enum'
import { IModelOrderItem } from '@/containers/models/modelOrderItem'
import { IModelTransaction } from '@/containers/models/modelTransaction'
import { TransactionTransactionStatus } from '@/graphql/generated/schemas'
/**
  주문내역으로 부터 파생되는 페이지에서 사용되는 유틸 함수들 입니다.
 */

export const classifyPayMethodType = (approvedTransaction: IModelTransaction): PaymentMethodTypeEnum => {
  return approvedTransaction.payMethodType.toLowerCase() as PaymentMethodTypeEnum
}

// 결제 대기중은 주문이 일어나지 않은 상태이므로 함께 주문한 상품들은 모두 결제 대기중이거나 그렇지 않은 상태입니다.
export const isUnConfirmedPayment = (approvedTransaction: IModelTransaction) => {
  return approvedTransaction.transactionStatus === TransactionTransactionStatus.VbankReady
}

export const isUnPaidCanceled = (approvedTransaction: IModelTransaction) => {
  return approvedTransaction.transactionStatus === TransactionTransactionStatus.UnpaidCanceled
}

export const makeOrderItemShippingInfo = (orderItem: IModelOrderItem, responsibility?: ResponsibilityEnum) => {
  const shippingPresetReturnFee = orderItem.shipping.shippingPreset.returnFee || 0
  /**
   * 2만원 이상 주문은 주문 시 무료배송
   * 무료배송일 경우 orderItem.shipping.discountPrice: 3000
   * 무료배송이 아닐 경우 orderItem.shipping.discountPrice: 0
   * 무료배송 받은 상품을 반품 시 주문시 할인받은 배송비를 반환해야 한다.
   */
  const shippingDiscountPrice = orderItem.shipping.discountPrice || 0
  return {
    // 교환/반품 신청시 예상 반품 배송비 금액
    requestReturnShippingFee:
      responsibility === ResponsibilityEnum.Customer ? shippingPresetReturnFee + shippingDiscountPrice : 0,
    // 실제 완료된 배송비
    returnShippingFee: orderItem.returnInfo.returnShippingFee || 0,
    returnExtraShippingFee: orderItem.returnInfo.returnShippingExtraFee || 0,
    shippingFee: orderItem.shipping.amount || 0,
    // Todo : 추후 backend수정
    extraShippingFee: orderItem.shipping.extraFee || 0,
  }
}

export const makeOrderItemsAmountInfo = (orderItems: IModelOrderItem[]) => {
  return orderItems.reduce(
    (info, item) => {
      return {
        paymentAmount: info.paymentAmount + item.amount || 0,
        refundAmount: info.refundAmount + item.refundAmount || 0,
        mileageRefund: info.mileageRefund + item.mileageDiscountPrice + item.shipping.mileageDiscountPrice || 0,
      }
    },
    {
      paymentAmount: 0,
      refundAmount: 0,
      mileageRefund: 0,
    }
  )
}

const isUncomfirmedPaymentItems = (orderItems: IModelOrderItem[]) => {
  return orderItems.some((item) => isUnConfirmedPayment(item.approvedTransaction))
}

export const extractItemsStatus = (orderItems: IModelOrderItem[]) => {
  const rawStatuses = [...new Set(orderItems.map((item) => item.orderStatus))]
  if (isUncomfirmedPaymentItems(orderItems)) {
    return [OrderStatusTypeEnum.UnconfirmedPayment, ...rawStatuses]
  }
  return rawStatuses
}

export const getPaymentMethodText = (paymentMethod: PaymentMethodTypeEnum, paymentText: string) => {
  if (paymentMethod === PaymentMethodTypeEnum.Point) {
    return paymentText
  }
  return paymentMethod === PaymentMethodTypeEnum.VirtualAccount
    ? PaymentMethodKoreanEnum.DepositAccount
    : PaymentMethodTypeKorean[paymentMethod]
}
