import { FC } from 'react'
import { Radio } from 'antd'
import { RadioProps } from 'antd/lib/radio'
import styled from 'styled-components'
import { SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'
import { ISelectOption, ISelectOptions } from '@/constants/legacy/constType'

type InputRadioProps = {
  layout?: 'vertical'
  options?: ISelectOptions
  innerSize?: 'sm' | 'lg'
} & RadioProps

const InputRadio: FC<InputRadioProps> = ({ options, layout, innerSize, ...props }) => {
  return (
    <Radio.Group {...props}>
      {options?.map((option: ISelectOption, index: number) => {
        return (
          <StyledRadio
            disabled={option.disabled}
            key={index}
            value={option.value}
            layout={layout}
            innerSize={innerSize}
          >
            {option.title}
          </StyledRadio>
        )
      })}
    </Radio.Group>
  )
}

const StyledRadio = styled(({ ...rest }) => <Radio {...rest} />)`
  display: ${({ layout }) => {
    if (layout === 'vertical') {
      return 'block'
    }
  }};
  height: ${({ layout }) => {
    if (layout === 'vertical') {
      return '40px'
    }
  }};
  line-height: ${({ layout }) => {
    if (layout === 'vertical') {
      return '40px'
    }
  }};

  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.color.gray700};

  .ant-radio {
    margin-bottom: -2px;
  }

  .ant-radio-inner {
    width: ${({ innerSize }) => {
      if (innerSize === 'sm') return '16px'
      if (innerSize === 'lg') return '24px'
      return '20px'
    }};
    height: ${({ innerSize }) => {
      if (innerSize === 'sm') return '16px'
      if (innerSize === 'lg') return '24px'
      return '20px'
    }};
    border-width: 2px;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) => props.theme.color.blue500};
    border-width: ${({ innerSize }) => {
      if (innerSize === 'sm') return '5px'
      if (innerSize === 'lg') return '7px'
      return '6px'
    }};
  }

  .ant-radio-checked {
    color: ${(props) => props.theme.color.blue500};
    font-weight: 600;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: transparent;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${(props) => props.theme.color.blue500};
  }

  @media ${SCREEN_MEDIA_QUERY.xs} {
    font-size: 14px;
  }
`

export default InputRadio
