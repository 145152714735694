import { FC, PropsWithChildren, useEffect } from 'react'
import { Col, Modal, Row } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { RectangleButtonLegacy } from '@/components/common/buttons'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { UseModalProps } from '@/containers/hooks/useModal'
import { RootState } from '@/stores/store'
import { enableScroll } from '@/utils/utilCommon'
import { Omit } from '@/utils/utilTs'

export type ModalButtonType = 'CANCEL' | 'ACTION' | 'ACTION_CANCEL' | 'NONE'

type RenderButtonProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  buttonType?: ModalButtonType
  // eslint-disable-next-line react/no-unused-prop-types
  buttonHtmlType?: 'button' | 'reset' | 'submit'
  // eslint-disable-next-line react/no-unused-prop-types
  form?: string
  // eslint-disable-next-line react/no-unused-prop-types
  loading?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  onOk?: () => void
  // eslint-disable-next-line react/no-unused-prop-types
  onCancel?: () => void
  // eslint-disable-next-line react/no-unused-prop-types
  okText?: string
  // eslint-disable-next-line react/no-unused-prop-types
  cancelText?: string
  // eslint-disable-next-line react/no-unused-prop-types
  okButtonDisabled?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  cancelButtonDisabled?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  afterClose?: () => void
  // eslint-disable-next-line react/no-unused-prop-types
  noBackBehavior?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  backAction?: boolean
}

export type ModalBaseProps<T> = {
  useModalProps: UseModalProps<T>
} & RenderButtonProps &
  Omit<ModalProps, 'onOk' | 'onCancel' | 'okText' | 'cancelText'>

const ModalBase: FC<PropsWithChildren<ModalBaseProps<any>>> = ({
  useModalProps,
  onOk,
  onCancel,
  afterClose,
  cancelText,
  okText,
  children,
  form,
  buttonHtmlType,
  loading,
  destroyOnClose = true,
  noBackBehavior = false,
  backAction = true,
  style,
  ...props
}) => {
  const { visible, hideModal, maskClosable = true } = useModalProps
  const bottomSheetData = useSelector((rootState: RootState) => rootState.reduxDataReducers.bottomSheetData)
  const isAnyVisible = Object.values(bottomSheetData).some((visible) => visible)

  useEffect(() => {
    if (!visible && isAnyVisible) {
      return
    }
    enableScroll(!visible)
  }, [visible])

  const _handleOk = async () => {
    if (form === '' || buttonHtmlType !== 'submit') {
      hideModal()
    }
    if (onOk) {
      onOk()
    }
  }

  const _handleCancel = () => {
    hideModal()
    if (onCancel) {
      onCancel()
    }
  }

  const _handleBackGroundCandle = () => {
    hideModal()
    if (onCancel) {
      onCancel()
    }
  }

  return (
    <>
      {/* @ts-ignore TODO typescript 오류 수정 필요 */}
      <StyledModal
        overflow={style?.overflow}
        getContainer={() => {
          // React에서 querySelector를 통한 컴포넌트를 불러오는 것은 자제 해야합니다.
          // -> https://ko.reactjs.org/docs/refs-and-the-dom.html#adding-a-ref-to-a-dom-element
          // + 라이프 사이클 예상이 안되는 가운데 불러올 컴포넌트가 없다면 치명적인 로직 에러가 발생합니다.
          // 아래 로직은 라이플 사이클 변경이 없는 엘리먼트 이므로 이상 없습니다.
          return document.body.querySelector('#fitpetMallBody') || document.body
        }}
        centered
        destroyOnClose={destroyOnClose}
        open={visible}
        onCancel={_handleBackGroundCandle}
        maskClosable={maskClosable}
        afterClose={afterClose}
        footer={
          <RenderButtons
            onOk={_handleOk}
            onCancel={_handleCancel}
            okText={okText}
            cancelText={cancelText}
            form={form}
            buttonHtmlType={buttonHtmlType}
            loading={loading}
            {...props}
          />
        }
        {...props}
      >
        <>{children}</>
      </StyledModal>
    </>
  )
}

const RenderButtons: FC<RenderButtonProps> = ({ buttonType = 'ACTION', ...restProps }) => {
  if (buttonType === 'CANCEL') {
    return (
      <ButtonContainer>
        <Row justify="center">
          <Col xs={24}>
            <RenderCancelButton {...restProps} />
          </Col>
        </Row>
      </ButtonContainer>
    )
  }

  if (buttonType === 'ACTION') {
    return (
      <ButtonContainer>
        <Row justify="center">
          <Col xs={24}>
            <RenderActionButton {...restProps} />
          </Col>
        </Row>
      </ButtonContainer>
    )
  }
  if (buttonType === 'ACTION_CANCEL') {
    return (
      <ButtonContainer>
        <Row justify="center">
          <Col span={12}>
            <RenderCancelButton {...restProps} />
          </Col>
          <Col span={12}>
            <RenderActionButton {...restProps} />
          </Col>
        </Row>
      </ButtonContainer>
    )
  }

  return null
}

const RenderCancelButton: FC<RenderButtonProps> = ({ onCancel, cancelText, cancelButtonDisabled = false }) => {
  const { color } = useTheme()

  return (
    <CancelButtonContainer>
      <RectangleButtonLegacy
        colorTheme={color.grayWhite}
        border={'none'}
        onClick={onCancel}
        disabled={cancelButtonDisabled}
        text={cancelText}
        height={54}
        textStyle={{
          size: { xs: 16, md: 16 },
          weight: 'bold',
          color: 'black',
        }}
        radius={0}
      />
    </CancelButtonContainer>
  )
}

const RenderActionButton: FC<RenderButtonProps> = ({
  onOk,
  okText,
  form,
  buttonHtmlType,
  loading,
  okButtonDisabled = false,
}) => {
  const { color } = useTheme()

  return (
    <ActionButtonContainer disabled={okButtonDisabled}>
      <RectangleButtonLegacy
        colorTheme={color.blue500}
        onClick={onOk}
        disabled={okButtonDisabled}
        text={okText}
        form={form}
        htmlType={buttonHtmlType}
        height={54}
        textStyle={{
          size: { xs: 16, md: 16 },
          weight: 'bold',
          color: 'white',
        }}
        radius={0}
        loading={loading}
      />
    </ActionButtonContainer>
  )
}

const StyledModal = styled(Modal)<{ overflow: string | undefined }>`
  .ant-modal-content {
    border-radius: 1rem;
    overflow: ${({ overflow }) => overflow || 'hidden'};
  }
  .ant-modal-body {
  }
  .ant-modal-footer {
    border-top: none;
    padding: 0;
  }
  .ant-modal-close-x {
    display: none;
  }
`

const ButtonContainer = styled.div``

const CancelButtonContainer = styled.div`
  border-top: 1px solid ${GlobalColorEnum.Gray200};
`

const ActionButtonContainer = styled.div<{ disabled: boolean }>`
  ${({ disabled }) => (disabled ? 'null' : `border-top: 1px solid ${GlobalColorEnum.Blue500};`)}
`

export default ModalBase
