import _ from 'lodash'
import { S3_HOST_URL } from '@/constants/legacy/constEnv'
import { ProductReviewSummaryType } from '@/constants/legacy/constType'
import { DataBase } from '@/containers/gqls/data'
import { IModelProductDefault, IModelProductOptionDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelProductDefault } from '@/containers/models/base/defaultModels'
import { IModelBrand } from '@/containers/models/modelBrand'
import { IModelCoupon } from '@/containers/models/modelCoupon'
import { IModelProductImage } from '@/containers/models/modelProductImage'
import { IModelProductOption } from '@/containers/models/modelProductOption'
import { IModelProductPromotion } from '@/containers/models/modelProductPromotion'
import { IModelReview } from '@/containers/models/modelReview'
import { IModelShippingPreset } from '@/containers/models/modelShippingPreset'
import { ProductProductType } from '@/graphql/generated/schemas'
import { isNowAfter } from '@/utils/utilDate'
import { currencyText } from '@/utils/utilNumber'

export interface IModelProduct extends IModelProductDefault {
  productProductImages: IModelProductImage[]
  productProductOptions: IModelProductOption[]
  productReviews: IModelReview[]
  shippingPreset: IModelShippingPreset
  brand: IModelBrand
  productPromotion: IModelProductPromotion
  productType: ProductProductType
  isDiscounted: () => boolean
  getCustomerPrice: () => number
  getCustomerPriceCurrencyText: () => string
  getReviewSummary: () => ProductReviewSummaryType
  getMainImageThumbnail: (absoluteUrl?: boolean) => string
  getMainImages: () => IModelProductImage[]
  getReturnExchangeGuide: () => string
  getFormattedAverageScore: () => number
  getTimeSaleStartedAt: () => string
  getTimeSaleEndedAt: () => string
  getProductOptionsForInquiry: () => IModelProductOption[]
  isDisabledByAdmin: () => boolean
  isOngoingPromotionExpired: () => boolean
  isProductSoldOut: () => boolean
  getSingleProductOption: () => IModelProductOptionDefault | undefined
  getIsRanking: () => boolean
  getIsSoldOut: () => boolean
  getIsNotAvailable: () => boolean
  getPricePerSku: () => number
  getMaxProductOptionSkuCount: () => number

  disabledByAdmin: boolean
  isPromotionExpired: boolean
  maxDiscountCoupon: IModelCoupon | null
  mileageRate?: string
  imageReviewMileageRate?: string

  availableSku: {
    /** SKU 코드 */
    skuCode: string
    /** 구매가능수량(카트에 담긴 수량 제외) */
    quantity: number
    /** 장바구니 보유 여부 */
    isCarted: boolean
  }[]
}

export default class ModelProduct extends ModelProductDefault {
  disabledByAdmin: boolean = false
  isPromotionExpired: boolean = true
  maxDiscountCoupon: IModelCoupon | null

  _isRank: boolean = false // isRank는 실제 서버에 있는 데이터가 아님, 순위를 나타내는 상품카드인지를 표현하기 위해 사용

  constructor(data: IModelProduct) {
    super(data)
    this.maxDiscountCoupon = data?.maxDiscountCoupon ? DataBase.getModel(data.maxDiscountCoupon) : null
    this.disabledByAdmin = this.status.value !== 'APPROVED'
    if (data) {
      this.isPromotionExpired = data.productPromotion ? isNowAfter(this.productPromotion.endedAt) : false
      if (data.isRealSoldOut || data.isSoldOut) {
        this.productProductOptions?.forEach((productOption) => {
          productOption.stockQuantity = 0
        })
      }
    }
  }

  isOngoingPromotionExpired = () => {
    if (!this.productPromotion) {
      return false
    }
    return isNowAfter(this.productPromotion.endedAt)
  }

  isDisabledByAdmin = () => {
    return this.status.value !== 'APPROVED'
  }

  isProductSoldOut = (): boolean => {
    return this.isRealSoldOut || this.isSoldOut
  }

  isDiscounted = () => {
    return this.finalDiscountRate > 0 || this.discountRate > 0
  }

  getPrice = () => {
    return currencyText(this.price)
  }

  getCustomerPrice = () => {
    if (this.productPromotionCustomerPrice > 0) {
      return this.productPromotionCustomerPrice
    }

    return this.customerPrice
  }

  getCustomerPriceCurrencyText = () => {
    return currencyText(this.getCustomerPrice())
  }

  getMainImageThumbnail = (isRelativePath = false) => {
    if (isRelativePath) {
      return `${S3_HOST_URL}/${this.mainImage.thumbnail}`
    }

    return this.mainImage.thumbnail
  }

  getMainImages = () => {
    const mainImage = this.productProductImages.find((item) => item.displayType === 'MAIN')
    const subImages = this.productProductImages.filter((item) => item.displayType === 'SUB')
    return [mainImage, ...subImages]
  }

  getReviewSummary = () => {
    return {
      averageScore: this.getFormattedAverageScore(),
      totalCount: this.reviewCount,
    }
  }

  getFormattedAverageScore = () => {
    if (!this.averageReviewScore) {
      return 0
    }

    return _.round(this.averageReviewScore, 1)
  }

  getReturnExchangeGuide = () => {
    return this.seller.returnExchangeGuide
  }

  getTimeSaleStartedAt = () => {
    return this.productPromotion.startedAt
  }

  getTimeSaleEndedAt = () => {
    return this.productPromotion.endedAt
  }

  getProductOptionsForInquiry = () => {
    return _.filter(this.productProductOptions, (o) => o.name !== null)
  }

  getSingleProductOption = (): IModelProductOptionDefault | undefined => {
    if (!this.isSingleOption) {
      return undefined
    }
    const isDefault = this.productProductOptions.length === 1
    return this.productProductOptions.find((option) => option.isDefault === isDefault)
  }

  /**
   * 랭킹 상품 조건
   * @desc 메인홈에서 타임세일하는 품목에만 적용
   */
  getIsRanking: IModelProduct['getIsRanking'] = () => {
    return this._isRank
  }

  /**
   * 입고 예정 조건
   * @returns 품절이 된 상품인 경우 True
   */
  getIsSoldOut: IModelProduct['getIsSoldOut'] = () => {
    return this.isRealSoldOut || this.isSoldOut
  }

  /**
   * 상품 준비중 조건
   * @returns 홈화면에서는 승인되지 않은 상품은 내려주지 않으므로 항상 False
   */
  getIsNotAvailable: IModelProduct['getIsNotAvailable'] = () => {
    return this.status.value && this.disabledByAdmin
  }

  getPricePerSku = () => {
    if (!this.pricePerSku || this.isRealSoldOut) {
      return 0
    }
    return this.pricePerSku
  }

  getMaxProductOptionSkuCount = () => {
    return this.maxProductOptionSkuCount || 0
  }
}
