import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from './operations'

const defaultOptions = { fetchPolicy: 'network-only' } as const
export const CustomerTemplateAddressFragmentDoc = gql`
  fragment customerTemplateAddress on CustomerTemplateAddressType {
    id
    postNumber
    basicAddress
    detailedAddress
    isDefault
    templateName
    name
    mobileNumber
    message
  }
`
export const MeFragmentDoc = gql`
  fragment me on UserType {
    __typename
    id
    email
    lastLogin
    isSuperuser
    firstName
    lastName
    username
    name
    isStaff
    isActive
    isTest
    mobileNumber
    isBlocked
    displayPetType
    cartItemQuantity
    isMembership
    hasMembership
    canSubscribeMembership
    membershipStatus
    gender
    birthday
    agreeMarketing
    agreePrMarketing
    agreeNightMarketing
    agreeThirdPartyProvision
    invitationCode
    inviteCount
    invitationCode
    invitedFirstOrderUserCount
    inviteAccumulateCouponAmount
    mileagePoint
    willExpiredMileageIn30Days
    canBuyDeal100
    payMethodType
    dateJoined
    lastOrderedAt
    ongoingOrderCount
    writableReviewCount
    couponCount
    likedCount
    membership {
      id
      startedAt
      endedAt
      nextPaidAt
      nextAmount
      nextStartedAt
      nextEndedAt
      creditCard {
        id
        cardName
        lastFourDigitCardNumber
        cardStatus
      }
    }
  }
`
export const UserDeviceFragmentDoc = gql`
  fragment userDevice on DeviceType {
    token
    refreshToken
    expiredAt
    lastLoggedAt
    socialService {
      socialType
    }
  }
`
export const UserCertificationFragmentDoc = gql`
  fragment userCertification on UserCertificationType {
    createdAt
    updatedAt
    id
    mobileNumber
    email
    confirmedAt
    certType
    expiredAt
  }
`
export const CategoryMinimumFragmentDoc = gql`
  fragment categoryMinimum on CategoryType {
    id
    name
    text
    isVisible
    listImage
    slug
  }
`
export const PageInfoFragmentDoc = gql`
  fragment pageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`
export const CouponFragmentDoc = gql`
  fragment coupon on CouponType {
    createdAt
    updatedAt
    id
    code
    registeredAt
    startedAt
    expiredAt
    usedAt
    message
    usedStatus
    issuedFrom
    issueCount
    isMembershipCoupon
  }
`
export const CouponGroupInCouponListFragmentDoc = gql`
  fragment couponGroupInCouponList on CouponGroupType {
    id
    name
    description
    discountType
    discountRate
    discountAmount
    maxDiscountAmount
    hasMinOrderAmount
    minOrderAmount
    coverageOsType
    coverageType
    canConjunctUse
    couponType
    hasUsingPeriod
    usingPeriod
    usingStartedAt
    usingEndedAt
    canIssue
    issueType
    isMembershipCoupon
  }
`
export const BannerFragmentDoc = gql`
  fragment banner on TypedBannerType {
    rawId
    id
    name
    startedAt
    endedAt
    displayOrder
    bannerType
    bannerInfo {
      mobileImage {
        rawId
        link
        image
        thumbnail
        index
        id
        displayDevice
      }
    }
  }
`
export const PromotionFragmentDoc = gql`
  fragment promotion on TypedProductPromotionType {
    id
    productPromotionType
  }
`
export const ProductFragmentDoc = gql`
  fragment product on TypedProductType {
    id
    name
    brand {
      id
      name
    }
    supplyType
    isShown
    isRealSoldOut
    productRealId
    price
    createdAt
    productPromotionDiscountRate
    customerPrice
    productPromotionDiscountRate
    productPromotionCustomerPrice
    discountRate
    discountPrice
    finalDiscountRate
    productPromotionStartedAt
    productPromotionEndedAt
    productNumber
    reviewCount
    averageReviewScore
    isTimeSale
    pricePerSku
    maxProductOptionSkuCount
    productPromotion {
      ...promotion
    }
    mainImage {
      id
      thumbnail
    }
  }
  ${PromotionFragmentDoc}
`
export const DisplayProductInfoFragmentDoc = gql`
  fragment displayProductInfo on TypedProductInfoType {
    id
    promotionStartedAt
    promotionEndedAt
    product {
      ...product
    }
  }
  ${ProductFragmentDoc}
`
export const DisplayFragmentDoc = gql`
  fragment display on TypedDisplayCollectionType {
    id
    name
    startedAt
    endedAt
    updatedAt
    collectionType
    description
    backgroundImage
    displayProductInfos {
      ...displayProductInfo
    }
  }
  ${DisplayProductInfoFragmentDoc}
`
export const ItemFragmentDoc = gql`
  fragment item on TypedProductInfoType {
    id
    promotionStartedAt
    promotionEndedAt
    product {
      ...product
    }
  }
  ${ProductFragmentDoc}
`
export const WeeklyFragmentDoc = gql`
  fragment weekly on TypedWeeklyBestCollectionType {
    id
    rawId
    name
    startedAt
    endedAt
    createdAt
    updatedAt
    collectionType
    displayProductInfos {
      ...item
    }
  }
  ${ItemFragmentDoc}
`
export const BrandFragmentDoc = gql`
  fragment brand on TypedBrandType {
    id
    rawId
    name
    listImage
  }
`
export const BestReviewFragmentDoc = gql`
  fragment bestReview on TypedBestReviewType {
    id
    rawId
    name
    collectionType
    displayProductInfos {
      id
      product {
        id
        name
        brand {
          id
          name
        }
        supplyType
        price
        customerPrice
        discountRate
        productNumber
        bestReview
        averageReviewScore
        productPromotion {
          id
        }
        mainImage {
          thumbnail
        }
      }
    }
  }
`
export const MembershipBenefitMonthlyAtHomeFragmentDoc = gql`
  fragment membershipBenefitMonthlyAtHome on MembershipBenefitMonthlyType {
    __typename
    startedAt
    endedAt
    confirmMileageAmount
    membershipConfirmMileageAmount
    membershipConfirmDiffMileageAmount
    shippingCount
    shippingDiscountAmount
  }
`
export const MembershipRefundInfoFragmentDoc = gql`
  fragment membershipRefundInfo on MembershipType {
    id
    isOwner
    canRefund
    expectRefundAmount
  }
`
export const ReviewOrderItemsFragmentDoc = gql`
  fragment reviewOrderItems on OrderItemType {
    __typename
    id
    orderItemNumber
    productName
    orderStatus
    hasUserReview
    imageReviewDiffReviewMileage
    imageReviewDiffReviewMileageRate
    imageReviewMileage
    imageReviewMileageRate
    mileage
    mileageRate
    reviewMileage
    reviewMileageRate
    orderConfirmMileage
    orderConfirmMileageRate
    membershipMileage
    membershipOrderConfirmDiffMileage
    membershipOrderConfirmDiffMileageRate
    approvedTransaction {
      pgProviderType
      payMethodType
    }
    order {
      __typename
      id
      orderNumber
      membership {
        id
      }
      user {
        id
        writableReviewCount
      }
    }
    productOption {
      __typename
      id
      name
      productId
    }
    productImage {
      thumbnail
    }
    productPromotion {
      id
    }
  }
`
export const ProductListDefaultItemFragmentDoc = gql`
  fragment productListDefaultItem on ProductType {
    id
    name
    price
    customerPrice
    discountPrice
    discountRate
    isRealSoldOut
    isSoldOut
    averageReviewScore
    finalDiscountRate
    reviewCount
    productPromotionCustomerPrice
    productNumber
    isTimesale
    isLiked
    likedCount
    pricePerSku
    maxProductOptionSkuCount
    mainImage {
      id
      thumbnail(productImageType: "NORMAL")
    }
    status {
      value
    }
    productPromotion {
      id
      productPromotionType
    }
  }
`
export const BundledProductItemFragmentDoc = gql`
  fragment bundledProductItem on ProductType {
    id
    name
    price
    customerPrice
    discountPrice
    discountRate
    isRealSoldOut
    isSoldOut
    averageReviewScore
    finalDiscountRate
    reviewCount
    productPromotionCustomerPrice
    productNumber
    isLiked
    likedCount
    pricePerSku
    supplyType
    brand {
      id
      name
    }
    categories {
      edges {
        node {
          id
          name
        }
      }
    }
    maxProductOptionSkuCount
    mainImage {
      id
      thumbnail(productImageType: "NORMAL")
    }
    status {
      value
    }
  }
`
export const LikedProductsItemFragmentDoc = gql`
  fragment likedProductsItem on LikedProductType {
    id
    product {
      id
      name
      price
      customerPrice
      discountPrice
      discountRate
      isRealSoldOut
      isSoldOut
      averageReviewScore
      finalDiscountRate
      reviewCount
      productPromotionCustomerPrice
      productNumber
      isTimesale
      isLiked
      likedCount
      pricePerSku
      maxProductOptionSkuCount
      mainImage {
        id
        thumbnail(productImageType: "NORMAL")
      }
      status {
        value
      }
      productPromotion {
        id
        productPromotionType
      }
    }
    likedAt
    productPromotion {
      id
      productPromotionType
    }
  }
`
export const IngredientProductItemFragmentDoc = gql`
  fragment ingredientProductItem on ProductType {
    id
    name
    price
    customerPrice
    discountPrice
    discountRate
    isRealSoldOut
    isSoldOut
    averageReviewScore
    finalDiscountRate
    reviewCount
    productPromotionCustomerPrice
    productNumber
    isLiked
    likedCount
    pricePerSku
    brand {
      id
      name
    }
    mainImage {
      id
      thumbnail(productImageType: "NORMAL")
    }
    status {
      value
    }
    productPromotion {
      id
      productPromotionType
    }
    feedCategoryType
    ingredientTypes
  }
`
export const RecommendedSimilarProductItemFragmentDoc = gql`
  fragment recommendedSimilarProductItem on ProductType {
    id
    name
    price
    customerPrice
    discountPrice
    discountRate
    isRealSoldOut
    isSoldOut
    averageReviewScore
    finalDiscountRate
    reviewCount
    productPromotionCustomerPrice
    productNumber
    isTimesale
    isLiked
    likedCount
    pricePerSku
    mainImage {
      id
      thumbnail(productImageType: "NORMAL")
    }
    status {
      value
    }
    productPromotion {
      id
      productPromotionType
    }
  }
`
export const RecommendedProductDetailProductItemFragmentDoc = gql`
  fragment recommendedProductDetailProductItem on ProductType {
    id
    name
    price
    customerPrice
    discountPrice
    discountRate
    isRealSoldOut
    isSoldOut
    averageReviewScore
    finalDiscountRate
    reviewCount
    productPromotionCustomerPrice
    productNumber
    isTimesale
    supplyType
    brand {
      id
      name
    }
    categories {
      edges {
        node {
          id
          name
        }
      }
    }
    isLiked
    likedCount
    pricePerSku
    maxProductOptionSkuCount
    mainImage {
      id
      thumbnail(productImageType: "NORMAL")
    }
    status {
      value
    }
    productPromotion {
      id
      productPromotionType
    }
  }
`
export const RootCategoryProductItemFragmentDoc = gql`
  fragment rootCategoryProductItem on ProductType {
    id
    name
    price
    customerPrice
    discountPrice
    discountRate
    isRealSoldOut
    isSoldOut
    averageReviewScore
    finalDiscountRate
    reviewCount
    productPromotionCustomerPrice
    productNumber
    isTimesale
    isLiked
    likedCount
    pricePerSku
    maxProductOptionSkuCount
    mainImage {
      id
      thumbnail(productImageType: "PRODUCT_LIST")
    }
    status {
      value
    }
    productPromotion {
      id
      productPromotionType
    }
  }
`
export const SubCategoryProductItemFragmentDoc = gql`
  fragment subCategoryProductItem on ProductType {
    id
    name
    price
    customerPrice
    discountPrice
    discountRate
    isRealSoldOut
    isSoldOut
    averageReviewScore
    finalDiscountRate
    reviewCount
    productPromotionCustomerPrice
    productNumber
    isTimesale
    isLiked
    likedCount
    pricePerSku
    maxProductOptionSkuCount
    mainImage {
      id
      thumbnail(productImageType: "PRODUCT_LIST")
    }
    status {
      value
    }
    productPromotion {
      id
      productPromotionType
    }
  }
`
export const BrandProductItemFragmentDoc = gql`
  fragment brandProductItem on ProductType {
    id
    name
    price
    customerPrice
    discountPrice
    discountRate
    isRealSoldOut
    isSoldOut
    averageReviewScore
    finalDiscountRate
    reviewCount
    productPromotionCustomerPrice
    productNumber
    isTimesale
    isLiked
    likedCount
    pricePerSku
    maxProductOptionSkuCount
    mainImage {
      id
      thumbnail(productImageType: "PRODUCT_LIST")
    }
    status {
      value
    }
    productPromotion {
      id
      productPromotionType
    }
  }
`
export const RecommendedRelatedProductsFragmentDoc = gql`
  fragment recommendedRelatedProducts on ProductType {
    id
    name
    price
    customerPrice
    discountPrice
    discountRate
    isRealSoldOut
    averageReviewScore
    finalDiscountRate
    reviewCount
    productPromotionCustomerPrice
    productNumber
    isLiked
    likedCount
    pricePerSku
    maxProductOptionSkuCount
    mainImage {
      id
      thumbnail(productImageType: "NORMAL")
    }
    productPromotion {
      id
      productPromotionType
    }
  }
`
export const RecentlyProductsItemFragmentDoc = gql`
  fragment RecentlyProductsItem on ProductType {
    id
    name
    price
    customerPrice
    discountPrice
    discountRate
    isRealSoldOut
    averageReviewScore
    finalDiscountRate
    reviewCount
    productPromotionCustomerPrice
    productNumber
    isLiked
    pricePerSku
    supplyType
    maxProductOptionSkuCount
    mainImage {
      id
      thumbnail(productImageType: "NORMAL")
    }
    status {
      value
    }
    productPromotion {
      id
      productPromotionType
    }
  }
`
export const ProductOptionFragmentFragmentDoc = gql`
  fragment ProductOptionFragment on ProductOptionType {
    id
    series
    price
    discountPrice
    isDefault
    isUse
    isDelete
    name
    customerPrice
    difference
    productPromotionCustomerPrice
    productPromotionDiscountPrice
    productOptionPrice
    stockQuantity
    purchasePrice
    productId
    product {
      shippingPreset {
        id
      }
    }
    mileage
  }
`
export const SearchProductItemFragmentDoc = gql`
  fragment searchProductItem on SearchProductsItemType {
    id
    name
    price
    customerPrice
    discountPrice
    discountRate
    isRealSoldOut
    isSoldOut
    finalDiscountRate
    averageReviewScore
    reviewCount
    productPromotionDiscountRate
    productPromotionCustomerPrice
    productNumber
    isTimesale
    mainImage {
      id
      thumbnail(productImageType: "PRODUCT_LIST")
    }
    productPromotion {
      id
    }
    brand {
      id
      name
      listImage
    }
    categories {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`
export const SearchFilterCategoryFragmentDoc = gql`
  fragment searchFilterCategory on SearchCategoryConnection {
    edges {
      node {
        id
        name
        code
        categoryChildren {
          edges {
            node {
              id
              name
              code
            }
          }
        }
      }
    }
  }
`
export const SearchFilterBrandFragmentDoc = gql`
  fragment searchFilterBrand on SearchBrandConnection {
    edges {
      node {
        id
        name
        listImage
      }
    }
  }
`
export const DisplayCollectionProductsFragmentDoc = gql`
  fragment displayCollectionProducts on ProductType {
    id
    name
    price
    customerPrice
    discountRate
    isRealSoldOut
    averageReviewScore
    finalDiscountRate
    reviewCount
    productPromotionCustomerPrice
    productNumber
    isLiked
    pricePerSku
    maxProductOptionSkuCount
    mainImage {
      id
      thumbnail(productImageType: "NORMAL")
    }
    productPromotion {
      id
      productPromotionType
    }
  }
`
export const CustomerAddressDocument = gql`
  query customerAddress($id: ID!) {
    customerTemplateAddress(id: $id) {
      ...customerTemplateAddress
    }
  }
  ${CustomerTemplateAddressFragmentDoc}
`

/**
 * __useCustomerAddressQuery__
 *
 * To run a query within a React component, call `useCustomerAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerAddressQuery(
  baseOptions: Apollo.QueryHookOptions<Types.CustomerAddressQuery, Types.CustomerAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CustomerAddressQuery, Types.CustomerAddressQueryVariables>(
    CustomerAddressDocument,
    options
  )
}
export function useCustomerAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.CustomerAddressQuery, Types.CustomerAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.CustomerAddressQuery, Types.CustomerAddressQueryVariables>(
    CustomerAddressDocument,
    options
  )
}
export type CustomerAddressQueryHookResult = ReturnType<typeof useCustomerAddressQuery>
export type CustomerAddressLazyQueryHookResult = ReturnType<typeof useCustomerAddressLazyQuery>
export type CustomerAddressQueryResult = Apollo.QueryResult<
  Types.CustomerAddressQuery,
  Types.CustomerAddressQueryVariables
>
export const ConfirmUserCertNumberDocument = gql`
  mutation confirmUserCertNumber($id: ID!, $input: ConfirmUserCertNumberInput!) {
    confirmUserCertNumber(id: $id, input: $input) {
      userCertification {
        ...userCertification
      }
      userInfo {
        id
        email
        userStatus
        accounts {
          loginAccountType
          lastLoggedAt
        }
      }
    }
  }
  ${UserCertificationFragmentDoc}
`
export type ConfirmUserCertNumberMutationFn = Apollo.MutationFunction<
  Types.ConfirmUserCertNumberMutation,
  Types.ConfirmUserCertNumberMutationVariables
>

/**
 * __useConfirmUserCertNumberMutation__
 *
 * To run a mutation, you first call `useConfirmUserCertNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserCertNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserCertNumberMutation, { data, loading, error }] = useConfirmUserCertNumberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmUserCertNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ConfirmUserCertNumberMutation,
    Types.ConfirmUserCertNumberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.ConfirmUserCertNumberMutation, Types.ConfirmUserCertNumberMutationVariables>(
    ConfirmUserCertNumberDocument,
    options
  )
}
export type ConfirmUserCertNumberMutationHookResult = ReturnType<typeof useConfirmUserCertNumberMutation>
export type ConfirmUserCertNumberMutationResult = Apollo.MutationResult<Types.ConfirmUserCertNumberMutation>
export type ConfirmUserCertNumberMutationOptions = Apollo.BaseMutationOptions<
  Types.ConfirmUserCertNumberMutation,
  Types.ConfirmUserCertNumberMutationVariables
>
export const EmailSigninDocument = gql`
  mutation emailSignin($input: EmailSigninInput!) {
    emailSignin(input: $input) {
      user {
        ...me
        coupons {
          edges {
            node {
              usedStatus
              couponGroup {
                id
                couponType
                discountAmount
                discountRate
              }
            }
          }
        }
      }
      device {
        ...userDevice
      }
    }
  }
  ${MeFragmentDoc}
  ${UserDeviceFragmentDoc}
`
export type EmailSigninMutationFn = Apollo.MutationFunction<
  Types.EmailSigninMutation,
  Types.EmailSigninMutationVariables
>

/**
 * __useEmailSigninMutation__
 *
 * To run a mutation, you first call `useEmailSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailSigninMutation, { data, loading, error }] = useEmailSigninMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailSigninMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.EmailSigninMutation, Types.EmailSigninMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.EmailSigninMutation, Types.EmailSigninMutationVariables>(EmailSigninDocument, options)
}
export type EmailSigninMutationHookResult = ReturnType<typeof useEmailSigninMutation>
export type EmailSigninMutationResult = Apollo.MutationResult<Types.EmailSigninMutation>
export type EmailSigninMutationOptions = Apollo.BaseMutationOptions<
  Types.EmailSigninMutation,
  Types.EmailSigninMutationVariables
>
export const ResetPasswordDocument = gql`
  mutation resetPassword($id: ID!, $input: ResetPasswordInput!) {
    resetPassword(id: $id, input: $input) {
      user {
        id
      }
    }
  }
`
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  Types.ResetPasswordMutation,
  Types.ResetPasswordMutationVariables
>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options
  )
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>
export type ResetPasswordMutationResult = Apollo.MutationResult<Types.ResetPasswordMutation>
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.ResetPasswordMutation,
  Types.ResetPasswordMutationVariables
>
export const SendUserCertNumberDocument = gql`
  mutation sendUserCertNumber($input: SendUserCertNumberInput!) {
    sendUserCertNumber(input: $input) {
      userCertification {
        ...userCertification
      }
    }
  }
  ${UserCertificationFragmentDoc}
`
export type SendUserCertNumberMutationFn = Apollo.MutationFunction<
  Types.SendUserCertNumberMutation,
  Types.SendUserCertNumberMutationVariables
>

/**
 * __useSendUserCertNumberMutation__
 *
 * To run a mutation, you first call `useSendUserCertNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUserCertNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUserCertNumberMutation, { data, loading, error }] = useSendUserCertNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendUserCertNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SendUserCertNumberMutation, Types.SendUserCertNumberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SendUserCertNumberMutation, Types.SendUserCertNumberMutationVariables>(
    SendUserCertNumberDocument,
    options
  )
}
export type SendUserCertNumberMutationHookResult = ReturnType<typeof useSendUserCertNumberMutation>
export type SendUserCertNumberMutationResult = Apollo.MutationResult<Types.SendUserCertNumberMutation>
export type SendUserCertNumberMutationOptions = Apollo.BaseMutationOptions<
  Types.SendUserCertNumberMutation,
  Types.SendUserCertNumberMutationVariables
>
export const SignOutDocument = gql`
  mutation signOut($input: SignOutInput!) {
    signOut(input: $input) {
      signOut
    }
  }
`
export type SignOutMutationFn = Apollo.MutationFunction<Types.SignOutMutation, Types.SignOutMutationVariables>

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignOutMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SignOutMutation, Types.SignOutMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SignOutMutation, Types.SignOutMutationVariables>(SignOutDocument, options)
}
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>
export type SignOutMutationResult = Apollo.MutationResult<Types.SignOutMutation>
export type SignOutMutationOptions = Apollo.BaseMutationOptions<Types.SignOutMutation, Types.SignOutMutationVariables>
export const TokenSignInDocument = gql`
  mutation tokenSignIn($input: TokenSigninInput!) {
    tokenSignin(input: $input) {
      user {
        ...me
      }
      device {
        ...userDevice
      }
    }
  }
  ${MeFragmentDoc}
  ${UserDeviceFragmentDoc}
`
export type TokenSignInMutationFn = Apollo.MutationFunction<
  Types.TokenSignInMutation,
  Types.TokenSignInMutationVariables
>

/**
 * __useTokenSignInMutation__
 *
 * To run a mutation, you first call `useTokenSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenSignInMutation, { data, loading, error }] = useTokenSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTokenSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.TokenSignInMutation, Types.TokenSignInMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.TokenSignInMutation, Types.TokenSignInMutationVariables>(TokenSignInDocument, options)
}
export type TokenSignInMutationHookResult = ReturnType<typeof useTokenSignInMutation>
export type TokenSignInMutationResult = Apollo.MutationResult<Types.TokenSignInMutation>
export type TokenSignInMutationOptions = Apollo.BaseMutationOptions<
  Types.TokenSignInMutation,
  Types.TokenSignInMutationVariables
>
export const CheckInvitationCodeDocument = gql`
  query checkInvitationCode($invitationCode: String!) {
    checkInvitationCode(invitationCode: $invitationCode)
  }
`

/**
 * __useCheckInvitationCodeQuery__
 *
 * To run a query within a React component, call `useCheckInvitationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckInvitationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckInvitationCodeQuery({
 *   variables: {
 *      invitationCode: // value for 'invitationCode'
 *   },
 * });
 */
export function useCheckInvitationCodeQuery(
  baseOptions: Apollo.QueryHookOptions<Types.CheckInvitationCodeQuery, Types.CheckInvitationCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CheckInvitationCodeQuery, Types.CheckInvitationCodeQueryVariables>(
    CheckInvitationCodeDocument,
    options
  )
}
export function useCheckInvitationCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckInvitationCodeQuery, Types.CheckInvitationCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.CheckInvitationCodeQuery, Types.CheckInvitationCodeQueryVariables>(
    CheckInvitationCodeDocument,
    options
  )
}
export type CheckInvitationCodeQueryHookResult = ReturnType<typeof useCheckInvitationCodeQuery>
export type CheckInvitationCodeLazyQueryHookResult = ReturnType<typeof useCheckInvitationCodeLazyQuery>
export type CheckInvitationCodeQueryResult = Apollo.QueryResult<
  Types.CheckInvitationCodeQuery,
  Types.CheckInvitationCodeQueryVariables
>
export const IsAppCouponDownloadDocument = gql`
  query isAppCouponDownload($id: ID!) {
    user(id: $id) {
      isAppCouponDownload
    }
  }
`

/**
 * __useIsAppCouponDownloadQuery__
 *
 * To run a query within a React component, call `useIsAppCouponDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAppCouponDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAppCouponDownloadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIsAppCouponDownloadQuery(
  baseOptions: Apollo.QueryHookOptions<Types.IsAppCouponDownloadQuery, Types.IsAppCouponDownloadQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.IsAppCouponDownloadQuery, Types.IsAppCouponDownloadQueryVariables>(
    IsAppCouponDownloadDocument,
    options
  )
}
export function useIsAppCouponDownloadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.IsAppCouponDownloadQuery, Types.IsAppCouponDownloadQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.IsAppCouponDownloadQuery, Types.IsAppCouponDownloadQueryVariables>(
    IsAppCouponDownloadDocument,
    options
  )
}
export type IsAppCouponDownloadQueryHookResult = ReturnType<typeof useIsAppCouponDownloadQuery>
export type IsAppCouponDownloadLazyQueryHookResult = ReturnType<typeof useIsAppCouponDownloadLazyQuery>
export type IsAppCouponDownloadQueryResult = Apollo.QueryResult<
  Types.IsAppCouponDownloadQuery,
  Types.IsAppCouponDownloadQueryVariables
>
export const JwtDocument = gql`
  query jwt {
    jwt
  }
`

/**
 * __useJwtQuery__
 *
 * To run a query within a React component, call `useJwtQuery` and pass it any options that fit your needs.
 * When your component renders, `useJwtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJwtQuery({
 *   variables: {
 *   },
 * });
 */
export function useJwtQuery(baseOptions?: Apollo.QueryHookOptions<Types.JwtQuery, Types.JwtQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.JwtQuery, Types.JwtQueryVariables>(JwtDocument, options)
}
export function useJwtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JwtQuery, Types.JwtQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.JwtQuery, Types.JwtQueryVariables>(JwtDocument, options)
}
export type JwtQueryHookResult = ReturnType<typeof useJwtQuery>
export type JwtLazyQueryHookResult = ReturnType<typeof useJwtLazyQuery>
export type JwtQueryResult = Apollo.QueryResult<Types.JwtQuery, Types.JwtQueryVariables>
export const UserInfoDocument = gql`
  query userInfo($id: ID!) {
    user(id: $id) {
      ...me
    }
  }
  ${MeFragmentDoc}
`

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserInfoQuery(
  baseOptions: Apollo.QueryHookOptions<Types.UserInfoQuery, Types.UserInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.UserInfoQuery, Types.UserInfoQueryVariables>(UserInfoDocument, options)
}
export function useUserInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.UserInfoQuery, Types.UserInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.UserInfoQuery, Types.UserInfoQueryVariables>(UserInfoDocument, options)
}
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>
export type UserInfoLazyQueryHookResult = ReturnType<typeof useUserInfoLazyQuery>
export type UserInfoQueryResult = Apollo.QueryResult<Types.UserInfoQuery, Types.UserInfoQueryVariables>
export const UserInfoForFindIdDocument = gql`
  query userInfoForFindId($userCertification: ID!) {
    userInfo(userCertification: $userCertification) {
      id
      email
      accounts {
        loginAccountType
        lastLoggedAt
      }
    }
  }
`

/**
 * __useUserInfoForFindIdQuery__
 *
 * To run a query within a React component, call `useUserInfoForFindIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoForFindIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoForFindIdQuery({
 *   variables: {
 *      userCertification: // value for 'userCertification'
 *   },
 * });
 */
export function useUserInfoForFindIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.UserInfoForFindIdQuery, Types.UserInfoForFindIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.UserInfoForFindIdQuery, Types.UserInfoForFindIdQueryVariables>(
    UserInfoForFindIdDocument,
    options
  )
}
export function useUserInfoForFindIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.UserInfoForFindIdQuery, Types.UserInfoForFindIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.UserInfoForFindIdQuery, Types.UserInfoForFindIdQueryVariables>(
    UserInfoForFindIdDocument,
    options
  )
}
export type UserInfoForFindIdQueryHookResult = ReturnType<typeof useUserInfoForFindIdQuery>
export type UserInfoForFindIdLazyQueryHookResult = ReturnType<typeof useUserInfoForFindIdLazyQuery>
export type UserInfoForFindIdQueryResult = Apollo.QueryResult<
  Types.UserInfoForFindIdQuery,
  Types.UserInfoForFindIdQueryVariables
>
export const UserSocialInfoDocument = gql`
  query userSocialInfo($id: ID!) {
    user(id: $id) {
      userSocialServices {
        edges {
          node {
            socialType
          }
        }
      }
    }
  }
`

/**
 * __useUserSocialInfoQuery__
 *
 * To run a query within a React component, call `useUserSocialInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSocialInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSocialInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSocialInfoQuery(
  baseOptions: Apollo.QueryHookOptions<Types.UserSocialInfoQuery, Types.UserSocialInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.UserSocialInfoQuery, Types.UserSocialInfoQueryVariables>(UserSocialInfoDocument, options)
}
export function useUserSocialInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.UserSocialInfoQuery, Types.UserSocialInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.UserSocialInfoQuery, Types.UserSocialInfoQueryVariables>(
    UserSocialInfoDocument,
    options
  )
}
export type UserSocialInfoQueryHookResult = ReturnType<typeof useUserSocialInfoQuery>
export type UserSocialInfoLazyQueryHookResult = ReturnType<typeof useUserSocialInfoLazyQuery>
export type UserSocialInfoQueryResult = Apollo.QueryResult<
  Types.UserSocialInfoQuery,
  Types.UserSocialInfoQueryVariables
>
export const BrandNameDocument = gql`
  query brandName($id: ID!) {
    brand(id: $id) {
      id
      name
      homeImage
      backgroundImage
    }
  }
`

/**
 * __useBrandNameQuery__
 *
 * To run a query within a React component, call `useBrandNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBrandNameQuery(
  baseOptions: Apollo.QueryHookOptions<Types.BrandNameQuery, Types.BrandNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.BrandNameQuery, Types.BrandNameQueryVariables>(BrandNameDocument, options)
}
export function useBrandNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.BrandNameQuery, Types.BrandNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.BrandNameQuery, Types.BrandNameQueryVariables>(BrandNameDocument, options)
}
export type BrandNameQueryHookResult = ReturnType<typeof useBrandNameQuery>
export type BrandNameLazyQueryHookResult = ReturnType<typeof useBrandNameLazyQuery>
export type BrandNameQueryResult = Apollo.QueryResult<Types.BrandNameQuery, Types.BrandNameQueryVariables>
export const CreateCartItemsDocument = gql`
  mutation createCartItems($input: CreateCartItemsInput!) {
    createCartItems(input: $input) {
      cartItems {
        id
      }
    }
  }
`
export type CreateCartItemsMutationFn = Apollo.MutationFunction<
  Types.CreateCartItemsMutation,
  Types.CreateCartItemsMutationVariables
>

/**
 * __useCreateCartItemsMutation__
 *
 * To run a mutation, you first call `useCreateCartItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCartItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCartItemsMutation, { data, loading, error }] = useCreateCartItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCartItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateCartItemsMutation, Types.CreateCartItemsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.CreateCartItemsMutation, Types.CreateCartItemsMutationVariables>(
    CreateCartItemsDocument,
    options
  )
}
export type CreateCartItemsMutationHookResult = ReturnType<typeof useCreateCartItemsMutation>
export type CreateCartItemsMutationResult = Apollo.MutationResult<Types.CreateCartItemsMutation>
export type CreateCartItemsMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateCartItemsMutation,
  Types.CreateCartItemsMutationVariables
>
export const UpdateCartItemDocument = gql`
  mutation updateCartItem($id: ID!, $input: UpdateCartItemInput!) {
    updateCartItem(id: $id, input: $input) {
      cartItem {
        id
      }
    }
  }
`
export type UpdateCartItemMutationFn = Apollo.MutationFunction<
  Types.UpdateCartItemMutation,
  Types.UpdateCartItemMutationVariables
>

/**
 * __useUpdateCartItemMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemMutation, { data, loading, error }] = useUpdateCartItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartItemMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateCartItemMutation, Types.UpdateCartItemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UpdateCartItemMutation, Types.UpdateCartItemMutationVariables>(
    UpdateCartItemDocument,
    options
  )
}
export type UpdateCartItemMutationHookResult = ReturnType<typeof useUpdateCartItemMutation>
export type UpdateCartItemMutationResult = Apollo.MutationResult<Types.UpdateCartItemMutation>
export type UpdateCartItemMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateCartItemMutation,
  Types.UpdateCartItemMutationVariables
>
export const RecommendedCartProductsDocument = gql`
  query recommendedCartProducts($ids: [ID]!) {
    recommendedCartProducts(ids: $ids) {
      edges {
        node {
          id
          name
          price
          productNumber
          customerPrice
          discountRate
          description
          averageReviewScore
          finalDiscountRate
          reviewCount
          productPromotionCustomerPrice
          isRealSoldOut
          shippingGuide
          shippingNotice
          isSingleOption
          mileage
          pricePerSku
          supplyType
          maxProductOptionSkuCount
          mainImage {
            thumbnail
          }
        }
      }
    }
  }
`

/**
 * __useRecommendedCartProductsQuery__
 *
 * To run a query within a React component, call `useRecommendedCartProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedCartProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedCartProductsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRecommendedCartProductsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.RecommendedCartProductsQuery, Types.RecommendedCartProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.RecommendedCartProductsQuery, Types.RecommendedCartProductsQueryVariables>(
    RecommendedCartProductsDocument,
    options
  )
}
export function useRecommendedCartProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.RecommendedCartProductsQuery,
    Types.RecommendedCartProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.RecommendedCartProductsQuery, Types.RecommendedCartProductsQueryVariables>(
    RecommendedCartProductsDocument,
    options
  )
}
export type RecommendedCartProductsQueryHookResult = ReturnType<typeof useRecommendedCartProductsQuery>
export type RecommendedCartProductsLazyQueryHookResult = ReturnType<typeof useRecommendedCartProductsLazyQuery>
export type RecommendedCartProductsQueryResult = Apollo.QueryResult<
  Types.RecommendedCartProductsQuery,
  Types.RecommendedCartProductsQueryVariables
>
export const RootCategoriesDocument = gql`
  query rootCategories($filter: GenericScalar) {
    categories(filter: $filter, pageInfo: { isUnlimited: true }) {
      totalCount
      edges {
        node {
          ...categoryMinimum
          categoryChildren {
            edges {
              node {
                ...categoryMinimum
                categoryChildren {
                  edges {
                    node {
                      ...categoryMinimum
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CategoryMinimumFragmentDoc}
`

/**
 * __useRootCategoriesQuery__
 *
 * To run a query within a React component, call `useRootCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRootCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRootCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRootCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.RootCategoriesQuery, Types.RootCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.RootCategoriesQuery, Types.RootCategoriesQueryVariables>(RootCategoriesDocument, options)
}
export function useRootCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.RootCategoriesQuery, Types.RootCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.RootCategoriesQuery, Types.RootCategoriesQueryVariables>(
    RootCategoriesDocument,
    options
  )
}
export type RootCategoriesQueryHookResult = ReturnType<typeof useRootCategoriesQuery>
export type RootCategoriesLazyQueryHookResult = ReturnType<typeof useRootCategoriesLazyQuery>
export type RootCategoriesQueryResult = Apollo.QueryResult<
  Types.RootCategoriesQuery,
  Types.RootCategoriesQueryVariables
>
export const RegisterCouponDocument = gql`
  mutation registerCoupon($input: RegisterCouponInput!) {
    registerCoupon(input: $input) {
      coupon {
        couponGroup {
          name
        }
      }
    }
  }
`
export type RegisterCouponMutationFn = Apollo.MutationFunction<
  Types.RegisterCouponMutation,
  Types.RegisterCouponMutationVariables
>

/**
 * __useRegisterCouponMutation__
 *
 * To run a mutation, you first call `useRegisterCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCouponMutation, { data, loading, error }] = useRegisterCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.RegisterCouponMutation, Types.RegisterCouponMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.RegisterCouponMutation, Types.RegisterCouponMutationVariables>(
    RegisterCouponDocument,
    options
  )
}
export type RegisterCouponMutationHookResult = ReturnType<typeof useRegisterCouponMutation>
export type RegisterCouponMutationResult = Apollo.MutationResult<Types.RegisterCouponMutation>
export type RegisterCouponMutationOptions = Apollo.BaseMutationOptions<
  Types.RegisterCouponMutation,
  Types.RegisterCouponMutationVariables
>
export const UserCouponsDocument = gql`
  query userCoupons(
    $pageInfo: PageInfoInputType
    $filter: GenericScalar
    $orderBy: String
    $first: Int
    $after: String
  ) {
    coupons(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy, first: $first, after: $after) {
      totalCount
      pageInfo {
        ...pageInfo
      }
      edges {
        cursor
        node {
          ...coupon
          couponGroup {
            ...couponGroupInCouponList
            issueStartedAt
            issueEndedAt
            includedBrands {
              edges {
                node {
                  name
                }
              }
            }
            includedCategories {
              edges {
                node {
                  name
                }
              }
            }
            includedProducts {
              edges {
                node {
                  name
                }
              }
            }
            excludedBrands {
              edges {
                node {
                  name
                }
              }
            }
            excludedCategories {
              edges {
                node {
                  name
                }
              }
            }
            excludedProducts {
              edges {
                node {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${CouponFragmentDoc}
  ${CouponGroupInCouponListFragmentDoc}
`

/**
 * __useUserCouponsQuery__
 *
 * To run a query within a React component, call `useUserCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCouponsQuery({
 *   variables: {
 *      pageInfo: // value for 'pageInfo'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserCouponsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.UserCouponsQuery, Types.UserCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.UserCouponsQuery, Types.UserCouponsQueryVariables>(UserCouponsDocument, options)
}
export function useUserCouponsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.UserCouponsQuery, Types.UserCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.UserCouponsQuery, Types.UserCouponsQueryVariables>(UserCouponsDocument, options)
}
export type UserCouponsQueryHookResult = ReturnType<typeof useUserCouponsQuery>
export type UserCouponsLazyQueryHookResult = ReturnType<typeof useUserCouponsLazyQuery>
export type UserCouponsQueryResult = Apollo.QueryResult<Types.UserCouponsQuery, Types.UserCouponsQueryVariables>
export const UserCouponsInfoDocument = gql`
  query userCouponsInfo($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
    coupons(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
      totalCount
      pageInfo {
        ...pageInfo
      }
      edges {
        node {
          couponGroup {
            id
            name
            couponType
            discountAmount
            discountRate
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useUserCouponsInfoQuery__
 *
 * To run a query within a React component, call `useUserCouponsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCouponsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCouponsInfoQuery({
 *   variables: {
 *      pageInfo: // value for 'pageInfo'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUserCouponsInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.UserCouponsInfoQuery, Types.UserCouponsInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.UserCouponsInfoQuery, Types.UserCouponsInfoQueryVariables>(
    UserCouponsInfoDocument,
    options
  )
}
export function useUserCouponsInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.UserCouponsInfoQuery, Types.UserCouponsInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.UserCouponsInfoQuery, Types.UserCouponsInfoQueryVariables>(
    UserCouponsInfoDocument,
    options
  )
}
export type UserCouponsInfoQueryHookResult = ReturnType<typeof useUserCouponsInfoQuery>
export type UserCouponsInfoLazyQueryHookResult = ReturnType<typeof useUserCouponsInfoLazyQuery>
export type UserCouponsInfoQueryResult = Apollo.QueryResult<
  Types.UserCouponsInfoQuery,
  Types.UserCouponsInfoQueryVariables
>
export const CreateCustomerTemplateAddressDocument = gql`
  mutation createCustomerTemplateAddress($input: CreateCustomerTemplateAddressInput!) {
    createCustomerTemplateAddress(input: $input) {
      customerTemplateAddress {
        ...customerTemplateAddress
      }
    }
  }
  ${CustomerTemplateAddressFragmentDoc}
`
export type CreateCustomerTemplateAddressMutationFn = Apollo.MutationFunction<
  Types.CreateCustomerTemplateAddressMutation,
  Types.CreateCustomerTemplateAddressMutationVariables
>

/**
 * __useCreateCustomerTemplateAddressMutation__
 *
 * To run a mutation, you first call `useCreateCustomerTemplateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerTemplateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerTemplateAddressMutation, { data, loading, error }] = useCreateCustomerTemplateAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerTemplateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateCustomerTemplateAddressMutation,
    Types.CreateCustomerTemplateAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateCustomerTemplateAddressMutation,
    Types.CreateCustomerTemplateAddressMutationVariables
  >(CreateCustomerTemplateAddressDocument, options)
}
export type CreateCustomerTemplateAddressMutationHookResult = ReturnType<
  typeof useCreateCustomerTemplateAddressMutation
>
export type CreateCustomerTemplateAddressMutationResult =
  Apollo.MutationResult<Types.CreateCustomerTemplateAddressMutation>
export type CreateCustomerTemplateAddressMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateCustomerTemplateAddressMutation,
  Types.CreateCustomerTemplateAddressMutationVariables
>
export const DeleteCustomerTemplateAddressDocument = gql`
  mutation deleteCustomerTemplateAddress($id: ID!) {
    deleteCustomerTemplateAddress(id: $id) {
      customerTemplateAddress {
        ...customerTemplateAddress
      }
    }
  }
  ${CustomerTemplateAddressFragmentDoc}
`
export type DeleteCustomerTemplateAddressMutationFn = Apollo.MutationFunction<
  Types.DeleteCustomerTemplateAddressMutation,
  Types.DeleteCustomerTemplateAddressMutationVariables
>

/**
 * __useDeleteCustomerTemplateAddressMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerTemplateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerTemplateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerTemplateAddressMutation, { data, loading, error }] = useDeleteCustomerTemplateAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerTemplateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteCustomerTemplateAddressMutation,
    Types.DeleteCustomerTemplateAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteCustomerTemplateAddressMutation,
    Types.DeleteCustomerTemplateAddressMutationVariables
  >(DeleteCustomerTemplateAddressDocument, options)
}
export type DeleteCustomerTemplateAddressMutationHookResult = ReturnType<
  typeof useDeleteCustomerTemplateAddressMutation
>
export type DeleteCustomerTemplateAddressMutationResult =
  Apollo.MutationResult<Types.DeleteCustomerTemplateAddressMutation>
export type DeleteCustomerTemplateAddressMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteCustomerTemplateAddressMutation,
  Types.DeleteCustomerTemplateAddressMutationVariables
>
export const UpdateCustomerTemplateAddressDocument = gql`
  mutation updateCustomerTemplateAddress($id: ID!, $input: UpdateCustomerTemplateAddressInput!) {
    updateCustomerTemplateAddress(id: $id, input: $input) {
      customerTemplateAddress {
        ...customerTemplateAddress
      }
    }
  }
  ${CustomerTemplateAddressFragmentDoc}
`
export type UpdateCustomerTemplateAddressMutationFn = Apollo.MutationFunction<
  Types.UpdateCustomerTemplateAddressMutation,
  Types.UpdateCustomerTemplateAddressMutationVariables
>

/**
 * __useUpdateCustomerTemplateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerTemplateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerTemplateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerTemplateAddressMutation, { data, loading, error }] = useUpdateCustomerTemplateAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerTemplateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateCustomerTemplateAddressMutation,
    Types.UpdateCustomerTemplateAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateCustomerTemplateAddressMutation,
    Types.UpdateCustomerTemplateAddressMutationVariables
  >(UpdateCustomerTemplateAddressDocument, options)
}
export type UpdateCustomerTemplateAddressMutationHookResult = ReturnType<
  typeof useUpdateCustomerTemplateAddressMutation
>
export type UpdateCustomerTemplateAddressMutationResult =
  Apollo.MutationResult<Types.UpdateCustomerTemplateAddressMutation>
export type UpdateCustomerTemplateAddressMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateCustomerTemplateAddressMutation,
  Types.UpdateCustomerTemplateAddressMutationVariables
>
export const CustomerAddressesDocument = gql`
  query customerAddresses($filter: GenericScalar, $pageInfo: PageInfoInputType, $orderBy: String) {
    customerTemplateAddresses(filter: $filter, pageInfo: $pageInfo, orderBy: $orderBy) {
      totalCount
      pageInfo {
        ...pageInfo
      }
      edges {
        node {
          ...customerTemplateAddress
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${CustomerTemplateAddressFragmentDoc}
`

/**
 * __useCustomerAddressesQuery__
 *
 * To run a query within a React component, call `useCustomerAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerAddressesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pageInfo: // value for 'pageInfo'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCustomerAddressesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.CustomerAddressesQuery, Types.CustomerAddressesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CustomerAddressesQuery, Types.CustomerAddressesQueryVariables>(
    CustomerAddressesDocument,
    options
  )
}
export function useCustomerAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.CustomerAddressesQuery, Types.CustomerAddressesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.CustomerAddressesQuery, Types.CustomerAddressesQueryVariables>(
    CustomerAddressesDocument,
    options
  )
}
export type CustomerAddressesQueryHookResult = ReturnType<typeof useCustomerAddressesQuery>
export type CustomerAddressesLazyQueryHookResult = ReturnType<typeof useCustomerAddressesLazyQuery>
export type CustomerAddressesQueryResult = Apollo.QueryResult<
  Types.CustomerAddressesQuery,
  Types.CustomerAddressesQueryVariables
>
export const DownloadEventCouponsDocument = gql`
  mutation downloadEventCoupons($input: DownloadEventCouponsInput!) {
    downloadEventCoupons(input: $input) {
      coupons {
        id
      }
    }
  }
`
export type DownloadEventCouponsMutationFn = Apollo.MutationFunction<
  Types.DownloadEventCouponsMutation,
  Types.DownloadEventCouponsMutationVariables
>

/**
 * __useDownloadEventCouponsMutation__
 *
 * To run a mutation, you first call `useDownloadEventCouponsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadEventCouponsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadEventCouponsMutation, { data, loading, error }] = useDownloadEventCouponsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadEventCouponsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DownloadEventCouponsMutation,
    Types.DownloadEventCouponsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DownloadEventCouponsMutation, Types.DownloadEventCouponsMutationVariables>(
    DownloadEventCouponsDocument,
    options
  )
}
export type DownloadEventCouponsMutationHookResult = ReturnType<typeof useDownloadEventCouponsMutation>
export type DownloadEventCouponsMutationResult = Apollo.MutationResult<Types.DownloadEventCouponsMutation>
export type DownloadEventCouponsMutationOptions = Apollo.BaseMutationOptions<
  Types.DownloadEventCouponsMutation,
  Types.DownloadEventCouponsMutationVariables
>
export const CreateParticipantResultDocument = gql`
  mutation createParticipantResult($input: CreateParticipantResultInput!) {
    createParticipantResult(input: $input) {
      participantResult {
        id
      }
    }
  }
`
export type CreateParticipantResultMutationFn = Apollo.MutationFunction<
  Types.CreateParticipantResultMutation,
  Types.CreateParticipantResultMutationVariables
>

/**
 * __useCreateParticipantResultMutation__
 *
 * To run a mutation, you first call `useCreateParticipantResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParticipantResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParticipantResultMutation, { data, loading, error }] = useCreateParticipantResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateParticipantResultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateParticipantResultMutation,
    Types.CreateParticipantResultMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.CreateParticipantResultMutation, Types.CreateParticipantResultMutationVariables>(
    CreateParticipantResultDocument,
    options
  )
}
export type CreateParticipantResultMutationHookResult = ReturnType<typeof useCreateParticipantResultMutation>
export type CreateParticipantResultMutationResult = Apollo.MutationResult<Types.CreateParticipantResultMutation>
export type CreateParticipantResultMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateParticipantResultMutation,
  Types.CreateParticipantResultMutationVariables
>
export const AdCouponDownloadButtonDocument = gql`
  query adCouponDownloadButton($slug: String!) {
    adBySlug(slug: $slug) {
      isAllCouponsDownloaded
      couponGroups {
        edges {
          node {
            name
            canIssue
          }
        }
      }
    }
  }
`

/**
 * __useAdCouponDownloadButtonQuery__
 *
 * To run a query within a React component, call `useAdCouponDownloadButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdCouponDownloadButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdCouponDownloadButtonQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAdCouponDownloadButtonQuery(
  baseOptions: Apollo.QueryHookOptions<Types.AdCouponDownloadButtonQuery, Types.AdCouponDownloadButtonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.AdCouponDownloadButtonQuery, Types.AdCouponDownloadButtonQueryVariables>(
    AdCouponDownloadButtonDocument,
    options
  )
}
export function useAdCouponDownloadButtonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AdCouponDownloadButtonQuery,
    Types.AdCouponDownloadButtonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.AdCouponDownloadButtonQuery, Types.AdCouponDownloadButtonQueryVariables>(
    AdCouponDownloadButtonDocument,
    options
  )
}
export type AdCouponDownloadButtonQueryHookResult = ReturnType<typeof useAdCouponDownloadButtonQuery>
export type AdCouponDownloadButtonLazyQueryHookResult = ReturnType<typeof useAdCouponDownloadButtonLazyQuery>
export type AdCouponDownloadButtonQueryResult = Apollo.QueryResult<
  Types.AdCouponDownloadButtonQuery,
  Types.AdCouponDownloadButtonQueryVariables
>
export const CouponGroupIncludesDocument = gql`
  query couponGroupIncludes($id: ID!) {
    couponGroup(id: $id) {
      id
      includedBrands {
        totalCount
        edges {
          node {
            name
          }
        }
      }
      includedCategories {
        totalCount
        edges {
          node {
            name
          }
        }
      }
      includedProducts {
        totalCount
        edges {
          node {
            name
          }
        }
      }
    }
  }
`

/**
 * __useCouponGroupIncludesQuery__
 *
 * To run a query within a React component, call `useCouponGroupIncludesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponGroupIncludesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponGroupIncludesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCouponGroupIncludesQuery(
  baseOptions: Apollo.QueryHookOptions<Types.CouponGroupIncludesQuery, Types.CouponGroupIncludesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CouponGroupIncludesQuery, Types.CouponGroupIncludesQueryVariables>(
    CouponGroupIncludesDocument,
    options
  )
}
export function useCouponGroupIncludesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.CouponGroupIncludesQuery, Types.CouponGroupIncludesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.CouponGroupIncludesQuery, Types.CouponGroupIncludesQueryVariables>(
    CouponGroupIncludesDocument,
    options
  )
}
export type CouponGroupIncludesQueryHookResult = ReturnType<typeof useCouponGroupIncludesQuery>
export type CouponGroupIncludesLazyQueryHookResult = ReturnType<typeof useCouponGroupIncludesLazyQuery>
export type CouponGroupIncludesQueryResult = Apollo.QueryResult<
  Types.CouponGroupIncludesQuery,
  Types.CouponGroupIncludesQueryVariables
>
export const AdCouponGroupsDocument = gql`
  query adCouponGroups($slug: String!) {
    adBySlug(slug: $slug) {
      isAllCouponsDownloaded
      id
      slug
      name
      couponGroups {
        totalCount
        edges {
          node {
            id
            name
            description
            discountType
            discountRate
            discountAmount
            maxDiscountAmount
            hasMinOrderAmount
            minOrderAmount
            coverageOsType
            coverageType
            canConjunctUse
            couponType
            hasUsingPeriod
            createdAt
            usingPeriod
            usingStartedAt
            usingEndedAt
            issueStartedAt
            issueEndedAt
            canIssue
            issueType
            includedBrands {
              edges {
                node {
                  name
                }
              }
            }
            includedCategories {
              edges {
                node {
                  name
                }
              }
            }
            includedProducts {
              edges {
                node {
                  name
                }
              }
            }
            excludedBrands {
              edges {
                node {
                  name
                }
              }
            }
            excludedCategories {
              edges {
                node {
                  name
                }
              }
            }
            excludedProducts {
              edges {
                node {
                  name
                }
              }
            }
            canNotIssueReason
            isMembershipCoupon
            __typename
          }
        }
      }
    }
  }
`

/**
 * __useAdCouponGroupsQuery__
 *
 * To run a query within a React component, call `useAdCouponGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdCouponGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdCouponGroupsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAdCouponGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.AdCouponGroupsQuery, Types.AdCouponGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.AdCouponGroupsQuery, Types.AdCouponGroupsQueryVariables>(AdCouponGroupsDocument, options)
}
export function useAdCouponGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.AdCouponGroupsQuery, Types.AdCouponGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.AdCouponGroupsQuery, Types.AdCouponGroupsQueryVariables>(
    AdCouponGroupsDocument,
    options
  )
}
export type AdCouponGroupsQueryHookResult = ReturnType<typeof useAdCouponGroupsQuery>
export type AdCouponGroupsLazyQueryHookResult = ReturnType<typeof useAdCouponGroupsLazyQuery>
export type AdCouponGroupsQueryResult = Apollo.QueryResult<
  Types.AdCouponGroupsQuery,
  Types.AdCouponGroupsQueryVariables
>
export const AdsDocument = gql`
  query ads($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
    ads(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
      pageInfo {
        ...pageInfo
      }
      totalCount
      edges {
        cursor
        node {
          id
          isShown
          endedAt
          name
          slug
          adType
          style
          mainImage {
            image
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useAdsQuery__
 *
 * To run a query within a React component, call `useAdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdsQuery({
 *   variables: {
 *      pageInfo: // value for 'pageInfo'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAdsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AdsQuery, Types.AdsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.AdsQuery, Types.AdsQueryVariables>(AdsDocument, options)
}
export function useAdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdsQuery, Types.AdsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.AdsQuery, Types.AdsQueryVariables>(AdsDocument, options)
}
export type AdsQueryHookResult = ReturnType<typeof useAdsQuery>
export type AdsLazyQueryHookResult = ReturnType<typeof useAdsLazyQuery>
export type AdsQueryResult = Apollo.QueryResult<Types.AdsQuery, Types.AdsQueryVariables>
export const EventByAdSlugDocument = gql`
  query eventByAdSlug($slug: String!, $filter: GenericScalar) {
    adBySlug(slug: $slug) {
      id
      name
      description
      petType
      startedAt
      endedAt
      slug
      isCouponDownloaded
      couponGroups {
        totalCount
        edges {
          node {
            ...couponGroupInCouponList
          }
        }
      }
      adDisplayCollections {
        totalCount
        edges {
          node {
            id
            name
            tabName
            backgroundImage
            displayProductInfos(filter: $filter) {
              totalCount
            }
          }
        }
      }
      mainImage {
        image
      }
    }
  }
  ${CouponGroupInCouponListFragmentDoc}
`

/**
 * __useEventByAdSlugQuery__
 *
 * To run a query within a React component, call `useEventByAdSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventByAdSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventByAdSlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEventByAdSlugQuery(
  baseOptions: Apollo.QueryHookOptions<Types.EventByAdSlugQuery, Types.EventByAdSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.EventByAdSlugQuery, Types.EventByAdSlugQueryVariables>(EventByAdSlugDocument, options)
}
export function useEventByAdSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.EventByAdSlugQuery, Types.EventByAdSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.EventByAdSlugQuery, Types.EventByAdSlugQueryVariables>(
    EventByAdSlugDocument,
    options
  )
}
export type EventByAdSlugQueryHookResult = ReturnType<typeof useEventByAdSlugQuery>
export type EventByAdSlugLazyQueryHookResult = ReturnType<typeof useEventByAdSlugLazyQuery>
export type EventByAdSlugQueryResult = Apollo.QueryResult<Types.EventByAdSlugQuery, Types.EventByAdSlugQueryVariables>
export const InvitationUserInfoDocument = gql`
  query invitationUserInfo($id: ID!) {
    user(id: $id) {
      id
      email
      lastLogin
      isSuperuser
      firstName
      lastName
      username
      name
      inviteCount
      invitationCode
      invitedFirstOrderUserCount
      inviteAccumulateCouponAmount
    }
  }
`

/**
 * __useInvitationUserInfoQuery__
 *
 * To run a query within a React component, call `useInvitationUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationUserInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvitationUserInfoQuery(
  baseOptions: Apollo.QueryHookOptions<Types.InvitationUserInfoQuery, Types.InvitationUserInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.InvitationUserInfoQuery, Types.InvitationUserInfoQueryVariables>(
    InvitationUserInfoDocument,
    options
  )
}
export function useInvitationUserInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.InvitationUserInfoQuery, Types.InvitationUserInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.InvitationUserInfoQuery, Types.InvitationUserInfoQueryVariables>(
    InvitationUserInfoDocument,
    options
  )
}
export type InvitationUserInfoQueryHookResult = ReturnType<typeof useInvitationUserInfoQuery>
export type InvitationUserInfoLazyQueryHookResult = ReturnType<typeof useInvitationUserInfoLazyQuery>
export type InvitationUserInfoQueryResult = Apollo.QueryResult<
  Types.InvitationUserInfoQuery,
  Types.InvitationUserInfoQueryVariables
>
export const ShareLinkEventOgDocument = gql`
  query shareLinkEventOg($slug: String!) {
    adBySlug(slug: $slug) {
      name
      mainImage {
        image
      }
    }
  }
`

/**
 * __useShareLinkEventOgQuery__
 *
 * To run a query within a React component, call `useShareLinkEventOgQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareLinkEventOgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareLinkEventOgQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useShareLinkEventOgQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ShareLinkEventOgQuery, Types.ShareLinkEventOgQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ShareLinkEventOgQuery, Types.ShareLinkEventOgQueryVariables>(
    ShareLinkEventOgDocument,
    options
  )
}
export function useShareLinkEventOgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ShareLinkEventOgQuery, Types.ShareLinkEventOgQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ShareLinkEventOgQuery, Types.ShareLinkEventOgQueryVariables>(
    ShareLinkEventOgDocument,
    options
  )
}
export type ShareLinkEventOgQueryHookResult = ReturnType<typeof useShareLinkEventOgQuery>
export type ShareLinkEventOgLazyQueryHookResult = ReturnType<typeof useShareLinkEventOgLazyQuery>
export type ShareLinkEventOgQueryResult = Apollo.QueryResult<
  Types.ShareLinkEventOgQuery,
  Types.ShareLinkEventOgQueryVariables
>
export const ProductReturnExchangeGuideDocument = gql`
  query productReturnExchangeGuide($id: ID!) {
    product(id: $id) {
      seller {
        returnExchangeGuide
      }
    }
  }
`

/**
 * __useProductReturnExchangeGuideQuery__
 *
 * To run a query within a React component, call `useProductReturnExchangeGuideQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductReturnExchangeGuideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductReturnExchangeGuideQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductReturnExchangeGuideQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ProductReturnExchangeGuideQuery,
    Types.ProductReturnExchangeGuideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ProductReturnExchangeGuideQuery, Types.ProductReturnExchangeGuideQueryVariables>(
    ProductReturnExchangeGuideDocument,
    options
  )
}
export function useProductReturnExchangeGuideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ProductReturnExchangeGuideQuery,
    Types.ProductReturnExchangeGuideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ProductReturnExchangeGuideQuery, Types.ProductReturnExchangeGuideQueryVariables>(
    ProductReturnExchangeGuideDocument,
    options
  )
}
export type ProductReturnExchangeGuideQueryHookResult = ReturnType<typeof useProductReturnExchangeGuideQuery>
export type ProductReturnExchangeGuideLazyQueryHookResult = ReturnType<typeof useProductReturnExchangeGuideLazyQuery>
export type ProductReturnExchangeGuideQueryResult = Apollo.QueryResult<
  Types.ProductReturnExchangeGuideQuery,
  Types.ProductReturnExchangeGuideQueryVariables
>
export const CreateCreditCardDocument = gql`
  mutation createCreditCard($input: CreateCreditCardInput!) {
    createCreditCard(input: $input) {
      creditCard {
        id
        iamportName
        customerId
        customerUid
        merchantUid
      }
    }
  }
`
export type CreateCreditCardMutationFn = Apollo.MutationFunction<
  Types.CreateCreditCardMutation,
  Types.CreateCreditCardMutationVariables
>

/**
 * __useCreateCreditCardMutation__
 *
 * To run a mutation, you first call `useCreateCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditCardMutation, { data, loading, error }] = useCreateCreditCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreditCardMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateCreditCardMutation, Types.CreateCreditCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.CreateCreditCardMutation, Types.CreateCreditCardMutationVariables>(
    CreateCreditCardDocument,
    options
  )
}
export type CreateCreditCardMutationHookResult = ReturnType<typeof useCreateCreditCardMutation>
export type CreateCreditCardMutationResult = Apollo.MutationResult<Types.CreateCreditCardMutation>
export type CreateCreditCardMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateCreditCardMutation,
  Types.CreateCreditCardMutationVariables
>
export const CreateMembershipInvitationDocument = gql`
  mutation createMembershipInvitation($id: ID!) {
    createMembershipInvitation(id: $id) {
      membershipInvitation {
        id
        invitationCode
      }
    }
  }
`
export type CreateMembershipInvitationMutationFn = Apollo.MutationFunction<
  Types.CreateMembershipInvitationMutation,
  Types.CreateMembershipInvitationMutationVariables
>

/**
 * __useCreateMembershipInvitationMutation__
 *
 * To run a mutation, you first call `useCreateMembershipInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMembershipInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMembershipInvitationMutation, { data, loading, error }] = useCreateMembershipInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateMembershipInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateMembershipInvitationMutation,
    Types.CreateMembershipInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateMembershipInvitationMutation,
    Types.CreateMembershipInvitationMutationVariables
  >(CreateMembershipInvitationDocument, options)
}
export type CreateMembershipInvitationMutationHookResult = ReturnType<typeof useCreateMembershipInvitationMutation>
export type CreateMembershipInvitationMutationResult = Apollo.MutationResult<Types.CreateMembershipInvitationMutation>
export type CreateMembershipInvitationMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateMembershipInvitationMutation,
  Types.CreateMembershipInvitationMutationVariables
>
export const KickMembershipFamilyDocument = gql`
  mutation kickMembershipFamily($id: ID!, $input: KickMembershipFamilyInput!) {
    kickMembershipFamily(id: $id, input: $input) {
      membership {
        id
      }
    }
  }
`
export type KickMembershipFamilyMutationFn = Apollo.MutationFunction<
  Types.KickMembershipFamilyMutation,
  Types.KickMembershipFamilyMutationVariables
>

/**
 * __useKickMembershipFamilyMutation__
 *
 * To run a mutation, you first call `useKickMembershipFamilyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKickMembershipFamilyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kickMembershipFamilyMutation, { data, loading, error }] = useKickMembershipFamilyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKickMembershipFamilyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.KickMembershipFamilyMutation,
    Types.KickMembershipFamilyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.KickMembershipFamilyMutation, Types.KickMembershipFamilyMutationVariables>(
    KickMembershipFamilyDocument,
    options
  )
}
export type KickMembershipFamilyMutationHookResult = ReturnType<typeof useKickMembershipFamilyMutation>
export type KickMembershipFamilyMutationResult = Apollo.MutationResult<Types.KickMembershipFamilyMutation>
export type KickMembershipFamilyMutationOptions = Apollo.BaseMutationOptions<
  Types.KickMembershipFamilyMutation,
  Types.KickMembershipFamilyMutationVariables
>
export const MembershipCancelRegularPaymentDocument = gql`
  mutation membershipCancelRegularPayment($id: ID!, $input: MembershipCancelReasonInput!) {
    unsubscribeMembership(id: $id, input: $input) {
      membership {
        id
        endedAt
      }
    }
  }
`
export type MembershipCancelRegularPaymentMutationFn = Apollo.MutationFunction<
  Types.MembershipCancelRegularPaymentMutation,
  Types.MembershipCancelRegularPaymentMutationVariables
>

/**
 * __useMembershipCancelRegularPaymentMutation__
 *
 * To run a mutation, you first call `useMembershipCancelRegularPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMembershipCancelRegularPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [membershipCancelRegularPaymentMutation, { data, loading, error }] = useMembershipCancelRegularPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMembershipCancelRegularPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.MembershipCancelRegularPaymentMutation,
    Types.MembershipCancelRegularPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.MembershipCancelRegularPaymentMutation,
    Types.MembershipCancelRegularPaymentMutationVariables
  >(MembershipCancelRegularPaymentDocument, options)
}
export type MembershipCancelRegularPaymentMutationHookResult = ReturnType<
  typeof useMembershipCancelRegularPaymentMutation
>
export type MembershipCancelRegularPaymentMutationResult =
  Apollo.MutationResult<Types.MembershipCancelRegularPaymentMutation>
export type MembershipCancelRegularPaymentMutationOptions = Apollo.BaseMutationOptions<
  Types.MembershipCancelRegularPaymentMutation,
  Types.MembershipCancelRegularPaymentMutationVariables
>
export const MembershipImmediateCancelDocument = gql`
  mutation membershipImmediateCancel($id: ID!, $input: MembershipCancelReasonInput!) {
    refundMembership(id: $id, input: $input) {
      membership {
        id
        endedAt
      }
    }
  }
`
export type MembershipImmediateCancelMutationFn = Apollo.MutationFunction<
  Types.MembershipImmediateCancelMutation,
  Types.MembershipImmediateCancelMutationVariables
>

/**
 * __useMembershipImmediateCancelMutation__
 *
 * To run a mutation, you first call `useMembershipImmediateCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMembershipImmediateCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [membershipImmediateCancelMutation, { data, loading, error }] = useMembershipImmediateCancelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMembershipImmediateCancelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.MembershipImmediateCancelMutation,
    Types.MembershipImmediateCancelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.MembershipImmediateCancelMutation, Types.MembershipImmediateCancelMutationVariables>(
    MembershipImmediateCancelDocument,
    options
  )
}
export type MembershipImmediateCancelMutationHookResult = ReturnType<typeof useMembershipImmediateCancelMutation>
export type MembershipImmediateCancelMutationResult = Apollo.MutationResult<Types.MembershipImmediateCancelMutation>
export type MembershipImmediateCancelMutationOptions = Apollo.BaseMutationOptions<
  Types.MembershipImmediateCancelMutation,
  Types.MembershipImmediateCancelMutationVariables
>
export const SubscribeMembershipDocument = gql`
  mutation subscribeMembership($input: SubscribeMembershipInput!) {
    subscribeMembership(input: $input) {
      membership {
        id
        startedAt
        endedAt
        nextPaidAt
        nextAmount
        payDuration
        creditCard {
          id
          cardName
          cardStatus
        }
      }
    }
  }
`
export type SubscribeMembershipMutationFn = Apollo.MutationFunction<
  Types.SubscribeMembershipMutation,
  Types.SubscribeMembershipMutationVariables
>

/**
 * __useSubscribeMembershipMutation__
 *
 * To run a mutation, you first call `useSubscribeMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeMembershipMutation, { data, loading, error }] = useSubscribeMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SubscribeMembershipMutation,
    Types.SubscribeMembershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SubscribeMembershipMutation, Types.SubscribeMembershipMutationVariables>(
    SubscribeMembershipDocument,
    options
  )
}
export type SubscribeMembershipMutationHookResult = ReturnType<typeof useSubscribeMembershipMutation>
export type SubscribeMembershipMutationResult = Apollo.MutationResult<Types.SubscribeMembershipMutation>
export type SubscribeMembershipMutationOptions = Apollo.BaseMutationOptions<
  Types.SubscribeMembershipMutation,
  Types.SubscribeMembershipMutationVariables
>
export const SubscribeMembershipFamilyDocument = gql`
  mutation subscribeMembershipFamily($input: SubscribeMembershipFamilyInput!) {
    subscribeMembershipFamily(input: $input) {
      membership {
        id
        payDuration
        nextAmount
      }
    }
  }
`
export type SubscribeMembershipFamilyMutationFn = Apollo.MutationFunction<
  Types.SubscribeMembershipFamilyMutation,
  Types.SubscribeMembershipFamilyMutationVariables
>

/**
 * __useSubscribeMembershipFamilyMutation__
 *
 * To run a mutation, you first call `useSubscribeMembershipFamilyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeMembershipFamilyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeMembershipFamilyMutation, { data, loading, error }] = useSubscribeMembershipFamilyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeMembershipFamilyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SubscribeMembershipFamilyMutation,
    Types.SubscribeMembershipFamilyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.SubscribeMembershipFamilyMutation, Types.SubscribeMembershipFamilyMutationVariables>(
    SubscribeMembershipFamilyDocument,
    options
  )
}
export type SubscribeMembershipFamilyMutationHookResult = ReturnType<typeof useSubscribeMembershipFamilyMutation>
export type SubscribeMembershipFamilyMutationResult = Apollo.MutationResult<Types.SubscribeMembershipFamilyMutation>
export type SubscribeMembershipFamilyMutationOptions = Apollo.BaseMutationOptions<
  Types.SubscribeMembershipFamilyMutation,
  Types.SubscribeMembershipFamilyMutationVariables
>
export const UnsubscribeMembershipFamilyDocument = gql`
  mutation unsubscribeMembershipFamily($id: ID!) {
    unsubscribeMembershipFamily(id: $id) {
      membership {
        id
      }
    }
  }
`
export type UnsubscribeMembershipFamilyMutationFn = Apollo.MutationFunction<
  Types.UnsubscribeMembershipFamilyMutation,
  Types.UnsubscribeMembershipFamilyMutationVariables
>

/**
 * __useUnsubscribeMembershipFamilyMutation__
 *
 * To run a mutation, you first call `useUnsubscribeMembershipFamilyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeMembershipFamilyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeMembershipFamilyMutation, { data, loading, error }] = useUnsubscribeMembershipFamilyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnsubscribeMembershipFamilyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UnsubscribeMembershipFamilyMutation,
    Types.UnsubscribeMembershipFamilyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UnsubscribeMembershipFamilyMutation,
    Types.UnsubscribeMembershipFamilyMutationVariables
  >(UnsubscribeMembershipFamilyDocument, options)
}
export type UnsubscribeMembershipFamilyMutationHookResult = ReturnType<typeof useUnsubscribeMembershipFamilyMutation>
export type UnsubscribeMembershipFamilyMutationResult = Apollo.MutationResult<Types.UnsubscribeMembershipFamilyMutation>
export type UnsubscribeMembershipFamilyMutationOptions = Apollo.BaseMutationOptions<
  Types.UnsubscribeMembershipFamilyMutation,
  Types.UnsubscribeMembershipFamilyMutationVariables
>
export const UpdateMembershipCreditCardDocument = gql`
  mutation updateMembershipCreditCard($input: SubscribeMembershipInput!) {
    updateMembershipCreditCard(input: $input) {
      membership {
        id
      }
    }
  }
`
export type UpdateMembershipCreditCardMutationFn = Apollo.MutationFunction<
  Types.UpdateMembershipCreditCardMutation,
  Types.UpdateMembershipCreditCardMutationVariables
>

/**
 * __useUpdateMembershipCreditCardMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipCreditCardMutation, { data, loading, error }] = useUpdateMembershipCreditCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembershipCreditCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateMembershipCreditCardMutation,
    Types.UpdateMembershipCreditCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateMembershipCreditCardMutation,
    Types.UpdateMembershipCreditCardMutationVariables
  >(UpdateMembershipCreditCardDocument, options)
}
export type UpdateMembershipCreditCardMutationHookResult = ReturnType<typeof useUpdateMembershipCreditCardMutation>
export type UpdateMembershipCreditCardMutationResult = Apollo.MutationResult<Types.UpdateMembershipCreditCardMutation>
export type UpdateMembershipCreditCardMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateMembershipCreditCardMutation,
  Types.UpdateMembershipCreditCardMutationVariables
>
export const MembershipAccumulatedBenefitDocument = gql`
  query membershipAccumulatedBenefit($id: ID!) {
    user(id: $id) {
      id
      membershipAccumulatedBenefit
    }
  }
`

/**
 * __useMembershipAccumulatedBenefitQuery__
 *
 * To run a query within a React component, call `useMembershipAccumulatedBenefitQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipAccumulatedBenefitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipAccumulatedBenefitQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMembershipAccumulatedBenefitQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.MembershipAccumulatedBenefitQuery,
    Types.MembershipAccumulatedBenefitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MembershipAccumulatedBenefitQuery, Types.MembershipAccumulatedBenefitQueryVariables>(
    MembershipAccumulatedBenefitDocument,
    options
  )
}
export function useMembershipAccumulatedBenefitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MembershipAccumulatedBenefitQuery,
    Types.MembershipAccumulatedBenefitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MembershipAccumulatedBenefitQuery, Types.MembershipAccumulatedBenefitQueryVariables>(
    MembershipAccumulatedBenefitDocument,
    options
  )
}
export type MembershipAccumulatedBenefitQueryHookResult = ReturnType<typeof useMembershipAccumulatedBenefitQuery>
export type MembershipAccumulatedBenefitLazyQueryHookResult = ReturnType<
  typeof useMembershipAccumulatedBenefitLazyQuery
>
export type MembershipAccumulatedBenefitQueryResult = Apollo.QueryResult<
  Types.MembershipAccumulatedBenefitQuery,
  Types.MembershipAccumulatedBenefitQueryVariables
>
export const MembershipAccumulatedMileageDocument = gql`
  query membershipAccumulatedMileage($id: ID!) {
    user(id: $id) {
      id
      membershipAccumulatedMileage
    }
  }
`

/**
 * __useMembershipAccumulatedMileageQuery__
 *
 * To run a query within a React component, call `useMembershipAccumulatedMileageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipAccumulatedMileageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipAccumulatedMileageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMembershipAccumulatedMileageQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.MembershipAccumulatedMileageQuery,
    Types.MembershipAccumulatedMileageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MembershipAccumulatedMileageQuery, Types.MembershipAccumulatedMileageQueryVariables>(
    MembershipAccumulatedMileageDocument,
    options
  )
}
export function useMembershipAccumulatedMileageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MembershipAccumulatedMileageQuery,
    Types.MembershipAccumulatedMileageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MembershipAccumulatedMileageQuery, Types.MembershipAccumulatedMileageQueryVariables>(
    MembershipAccumulatedMileageDocument,
    options
  )
}
export type MembershipAccumulatedMileageQueryHookResult = ReturnType<typeof useMembershipAccumulatedMileageQuery>
export type MembershipAccumulatedMileageLazyQueryHookResult = ReturnType<
  typeof useMembershipAccumulatedMileageLazyQuery
>
export type MembershipAccumulatedMileageQueryResult = Apollo.QueryResult<
  Types.MembershipAccumulatedMileageQuery,
  Types.MembershipAccumulatedMileageQueryVariables
>
export const MembershipBenefitMonthlyDocument = gql`
  query membershipBenefitMonthly($pageInfo: PageInfoInputType) {
    membershipBenefitMonthly(orderBy: "createdAt", pageInfo: $pageInfo) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...membershipBenefitMonthlyAtHome
        }
      }
      totalCount
    }
  }
  ${MembershipBenefitMonthlyAtHomeFragmentDoc}
`

/**
 * __useMembershipBenefitMonthlyQuery__
 *
 * To run a query within a React component, call `useMembershipBenefitMonthlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipBenefitMonthlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipBenefitMonthlyQuery({
 *   variables: {
 *      pageInfo: // value for 'pageInfo'
 *   },
 * });
 */
export function useMembershipBenefitMonthlyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.MembershipBenefitMonthlyQuery,
    Types.MembershipBenefitMonthlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MembershipBenefitMonthlyQuery, Types.MembershipBenefitMonthlyQueryVariables>(
    MembershipBenefitMonthlyDocument,
    options
  )
}
export function useMembershipBenefitMonthlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MembershipBenefitMonthlyQuery,
    Types.MembershipBenefitMonthlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MembershipBenefitMonthlyQuery, Types.MembershipBenefitMonthlyQueryVariables>(
    MembershipBenefitMonthlyDocument,
    options
  )
}
export type MembershipBenefitMonthlyQueryHookResult = ReturnType<typeof useMembershipBenefitMonthlyQuery>
export type MembershipBenefitMonthlyLazyQueryHookResult = ReturnType<typeof useMembershipBenefitMonthlyLazyQuery>
export type MembershipBenefitMonthlyQueryResult = Apollo.QueryResult<
  Types.MembershipBenefitMonthlyQuery,
  Types.MembershipBenefitMonthlyQueryVariables
>
export const MembershipCanRefundDocument = gql`
  query membershipCanRefund {
    membership {
      canRefund
    }
  }
`

/**
 * __useMembershipCanRefundQuery__
 *
 * To run a query within a React component, call `useMembershipCanRefundQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipCanRefundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipCanRefundQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipCanRefundQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.MembershipCanRefundQuery, Types.MembershipCanRefundQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MembershipCanRefundQuery, Types.MembershipCanRefundQueryVariables>(
    MembershipCanRefundDocument,
    options
  )
}
export function useMembershipCanRefundLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.MembershipCanRefundQuery, Types.MembershipCanRefundQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MembershipCanRefundQuery, Types.MembershipCanRefundQueryVariables>(
    MembershipCanRefundDocument,
    options
  )
}
export type MembershipCanRefundQueryHookResult = ReturnType<typeof useMembershipCanRefundQuery>
export type MembershipCanRefundLazyQueryHookResult = ReturnType<typeof useMembershipCanRefundLazyQuery>
export type MembershipCanRefundQueryResult = Apollo.QueryResult<
  Types.MembershipCanRefundQuery,
  Types.MembershipCanRefundQueryVariables
>
export const MembershipExpectBenefitDocument = gql`
  query membershipExpectBenefit {
    membership {
      expectMembershipBenefit {
        orderCount
        mileage
        shippingDiscountAmount
      }
      endedAt
      canRefund
    }
  }
`

/**
 * __useMembershipExpectBenefitQuery__
 *
 * To run a query within a React component, call `useMembershipExpectBenefitQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipExpectBenefitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipExpectBenefitQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipExpectBenefitQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.MembershipExpectBenefitQuery, Types.MembershipExpectBenefitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MembershipExpectBenefitQuery, Types.MembershipExpectBenefitQueryVariables>(
    MembershipExpectBenefitDocument,
    options
  )
}
export function useMembershipExpectBenefitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MembershipExpectBenefitQuery,
    Types.MembershipExpectBenefitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MembershipExpectBenefitQuery, Types.MembershipExpectBenefitQueryVariables>(
    MembershipExpectBenefitDocument,
    options
  )
}
export type MembershipExpectBenefitQueryHookResult = ReturnType<typeof useMembershipExpectBenefitQuery>
export type MembershipExpectBenefitLazyQueryHookResult = ReturnType<typeof useMembershipExpectBenefitLazyQuery>
export type MembershipExpectBenefitQueryResult = Apollo.QueryResult<
  Types.MembershipExpectBenefitQuery,
  Types.MembershipExpectBenefitQueryVariables
>
export const MembershipExpectRefundInfoDocument = gql`
  query membershipExpectRefundInfo {
    membership {
      ...membershipRefundInfo
    }
  }
  ${MembershipRefundInfoFragmentDoc}
`

/**
 * __useMembershipExpectRefundInfoQuery__
 *
 * To run a query within a React component, call `useMembershipExpectRefundInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipExpectRefundInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipExpectRefundInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipExpectRefundInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.MembershipExpectRefundInfoQuery,
    Types.MembershipExpectRefundInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MembershipExpectRefundInfoQuery, Types.MembershipExpectRefundInfoQueryVariables>(
    MembershipExpectRefundInfoDocument,
    options
  )
}
export function useMembershipExpectRefundInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MembershipExpectRefundInfoQuery,
    Types.MembershipExpectRefundInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MembershipExpectRefundInfoQuery, Types.MembershipExpectRefundInfoQueryVariables>(
    MembershipExpectRefundInfoDocument,
    options
  )
}
export type MembershipExpectRefundInfoQueryHookResult = ReturnType<typeof useMembershipExpectRefundInfoQuery>
export type MembershipExpectRefundInfoLazyQueryHookResult = ReturnType<typeof useMembershipExpectRefundInfoLazyQuery>
export type MembershipExpectRefundInfoQueryResult = Apollo.QueryResult<
  Types.MembershipExpectRefundInfoQuery,
  Types.MembershipExpectRefundInfoQueryVariables
>
export const MembershipFamiliesDocument = gql`
  query membershipFamilies {
    membershipFamilies {
      edges {
        node {
          id
          name
          isOwner
          isMe
        }
      }
    }
  }
`

/**
 * __useMembershipFamiliesQuery__
 *
 * To run a query within a React component, call `useMembershipFamiliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipFamiliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipFamiliesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipFamiliesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.MembershipFamiliesQuery, Types.MembershipFamiliesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MembershipFamiliesQuery, Types.MembershipFamiliesQueryVariables>(
    MembershipFamiliesDocument,
    options
  )
}
export function useMembershipFamiliesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.MembershipFamiliesQuery, Types.MembershipFamiliesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MembershipFamiliesQuery, Types.MembershipFamiliesQueryVariables>(
    MembershipFamiliesDocument,
    options
  )
}
export type MembershipFamiliesQueryHookResult = ReturnType<typeof useMembershipFamiliesQuery>
export type MembershipFamiliesLazyQueryHookResult = ReturnType<typeof useMembershipFamiliesLazyQuery>
export type MembershipFamiliesQueryResult = Apollo.QueryResult<
  Types.MembershipFamiliesQuery,
  Types.MembershipFamiliesQueryVariables
>
export const MembershipFamilyJoinInfoDocument = gql`
  query membershipFamilyJoinInfo {
    membership {
      id
      startedAt
      endedAt
      nextPaidAt
      nextAmount
      nextStartedAt
      nextEndedAt
      nextExpectedEndedAt
      payDuration
      creditCard {
        id
        cardName
        lastFourDigitCardNumber
        cardStatus
      }
    }
  }
`

/**
 * __useMembershipFamilyJoinInfoQuery__
 *
 * To run a query within a React component, call `useMembershipFamilyJoinInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipFamilyJoinInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipFamilyJoinInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipFamilyJoinInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.MembershipFamilyJoinInfoQuery,
    Types.MembershipFamilyJoinInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MembershipFamilyJoinInfoQuery, Types.MembershipFamilyJoinInfoQueryVariables>(
    MembershipFamilyJoinInfoDocument,
    options
  )
}
export function useMembershipFamilyJoinInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MembershipFamilyJoinInfoQuery,
    Types.MembershipFamilyJoinInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MembershipFamilyJoinInfoQuery, Types.MembershipFamilyJoinInfoQueryVariables>(
    MembershipFamilyJoinInfoDocument,
    options
  )
}
export type MembershipFamilyJoinInfoQueryHookResult = ReturnType<typeof useMembershipFamilyJoinInfoQuery>
export type MembershipFamilyJoinInfoLazyQueryHookResult = ReturnType<typeof useMembershipFamilyJoinInfoLazyQuery>
export type MembershipFamilyJoinInfoQueryResult = Apollo.QueryResult<
  Types.MembershipFamilyJoinInfoQuery,
  Types.MembershipFamilyJoinInfoQueryVariables
>
export const MembershipHistoriesDocument = gql`
  query membershipHistories($id: String, $pageInfo: PageInfoInputType, $filter: GenericScalar) {
    membershipHistories(user: $id, pageInfo: $pageInfo, filter: $filter, orderBy: "-endedAt") {
      pageInfo {
        ...pageInfo
      }
      totalCount
      edges {
        node {
          id
          name
          startedAt
          endedAt
          amount
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useMembershipHistoriesQuery__
 *
 * To run a query within a React component, call `useMembershipHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipHistoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      pageInfo: // value for 'pageInfo'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMembershipHistoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.MembershipHistoriesQuery, Types.MembershipHistoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MembershipHistoriesQuery, Types.MembershipHistoriesQueryVariables>(
    MembershipHistoriesDocument,
    options
  )
}
export function useMembershipHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.MembershipHistoriesQuery, Types.MembershipHistoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MembershipHistoriesQuery, Types.MembershipHistoriesQueryVariables>(
    MembershipHistoriesDocument,
    options
  )
}
export type MembershipHistoriesQueryHookResult = ReturnType<typeof useMembershipHistoriesQuery>
export type MembershipHistoriesLazyQueryHookResult = ReturnType<typeof useMembershipHistoriesLazyQuery>
export type MembershipHistoriesQueryResult = Apollo.QueryResult<
  Types.MembershipHistoriesQuery,
  Types.MembershipHistoriesQueryVariables
>
export const MembershipInvitationDocument = gql`
  query membershipInvitation($code: String!) {
    membershipInvitation(code: $code) {
      isExpired
      ownerName
      canInvite
      isOwnerActive
    }
  }
`

/**
 * __useMembershipInvitationQuery__
 *
 * To run a query within a React component, call `useMembershipInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipInvitationQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useMembershipInvitationQuery(
  baseOptions: Apollo.QueryHookOptions<Types.MembershipInvitationQuery, Types.MembershipInvitationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MembershipInvitationQuery, Types.MembershipInvitationQueryVariables>(
    MembershipInvitationDocument,
    options
  )
}
export function useMembershipInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.MembershipInvitationQuery, Types.MembershipInvitationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MembershipInvitationQuery, Types.MembershipInvitationQueryVariables>(
    MembershipInvitationDocument,
    options
  )
}
export type MembershipInvitationQueryHookResult = ReturnType<typeof useMembershipInvitationQuery>
export type MembershipInvitationLazyQueryHookResult = ReturnType<typeof useMembershipInvitationLazyQuery>
export type MembershipInvitationQueryResult = Apollo.QueryResult<
  Types.MembershipInvitationQuery,
  Types.MembershipInvitationQueryVariables
>
export const MembershipInvitationCodeDocument = gql`
  query membershipInvitationCode($code: String!) {
    membershipInvitation(code: $code) {
      isExpired
      ownerName
      canInvite
      isOwnerActive
    }
  }
`

/**
 * __useMembershipInvitationCodeQuery__
 *
 * To run a query within a React component, call `useMembershipInvitationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipInvitationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipInvitationCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useMembershipInvitationCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.MembershipInvitationCodeQuery,
    Types.MembershipInvitationCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MembershipInvitationCodeQuery, Types.MembershipInvitationCodeQueryVariables>(
    MembershipInvitationCodeDocument,
    options
  )
}
export function useMembershipInvitationCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MembershipInvitationCodeQuery,
    Types.MembershipInvitationCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MembershipInvitationCodeQuery, Types.MembershipInvitationCodeQueryVariables>(
    MembershipInvitationCodeDocument,
    options
  )
}
export type MembershipInvitationCodeQueryHookResult = ReturnType<typeof useMembershipInvitationCodeQuery>
export type MembershipInvitationCodeLazyQueryHookResult = ReturnType<typeof useMembershipInvitationCodeLazyQuery>
export type MembershipInvitationCodeQueryResult = Apollo.QueryResult<
  Types.MembershipInvitationCodeQuery,
  Types.MembershipInvitationCodeQueryVariables
>
export const MembershipJoinInfoDocument = gql`
  query membershipJoinInfo {
    membership {
      id
      startedAt
      endedAt
      nextPaidAt
      nextAmount
      nextStartedAt
      nextEndedAt
      nextExpectedEndedAt
      payDuration
      creditCard {
        id
        cardName
        lastFourDigitCardNumber
        cardStatus
      }
    }
  }
`

/**
 * __useMembershipJoinInfoQuery__
 *
 * To run a query within a React component, call `useMembershipJoinInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipJoinInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipJoinInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipJoinInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.MembershipJoinInfoQuery, Types.MembershipJoinInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MembershipJoinInfoQuery, Types.MembershipJoinInfoQueryVariables>(
    MembershipJoinInfoDocument,
    options
  )
}
export function useMembershipJoinInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.MembershipJoinInfoQuery, Types.MembershipJoinInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MembershipJoinInfoQuery, Types.MembershipJoinInfoQueryVariables>(
    MembershipJoinInfoDocument,
    options
  )
}
export type MembershipJoinInfoQueryHookResult = ReturnType<typeof useMembershipJoinInfoQuery>
export type MembershipJoinInfoLazyQueryHookResult = ReturnType<typeof useMembershipJoinInfoLazyQuery>
export type MembershipJoinInfoQueryResult = Apollo.QueryResult<
  Types.MembershipJoinInfoQuery,
  Types.MembershipJoinInfoQueryVariables
>
export const MembershipDocument = gql`
  query membership {
    membership {
      id
      startedAt
      endedAt
      nextPaidAt
      nextAmount
      payDuration
      creditCard {
        id
        cardName
        lastFourDigitCardNumber
        cardStatus
      }
    }
  }
`

/**
 * __useMembershipQuery__
 *
 * To run a query within a React component, call `useMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.MembershipQuery, Types.MembershipQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MembershipQuery, Types.MembershipQueryVariables>(MembershipDocument, options)
}
export function useMembershipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.MembershipQuery, Types.MembershipQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MembershipQuery, Types.MembershipQueryVariables>(MembershipDocument, options)
}
export type MembershipQueryHookResult = ReturnType<typeof useMembershipQuery>
export type MembershipLazyQueryHookResult = ReturnType<typeof useMembershipLazyQuery>
export type MembershipQueryResult = Apollo.QueryResult<Types.MembershipQuery, Types.MembershipQueryVariables>
export const MembershipSubscribeInfoBeforePaymentDocument = gql`
  query membershipSubscribeInfoBeforePayment {
    membershipSubscribeInfoBeforePayment {
      nextPaidAt
      nextAmount
      amount
      payInfo {
        period
        price
        discountPercent
      }
    }
  }
`

/**
 * __useMembershipSubscribeInfoBeforePaymentQuery__
 *
 * To run a query within a React component, call `useMembershipSubscribeInfoBeforePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipSubscribeInfoBeforePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipSubscribeInfoBeforePaymentQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipSubscribeInfoBeforePaymentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.MembershipSubscribeInfoBeforePaymentQuery,
    Types.MembershipSubscribeInfoBeforePaymentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.MembershipSubscribeInfoBeforePaymentQuery,
    Types.MembershipSubscribeInfoBeforePaymentQueryVariables
  >(MembershipSubscribeInfoBeforePaymentDocument, options)
}
export function useMembershipSubscribeInfoBeforePaymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MembershipSubscribeInfoBeforePaymentQuery,
    Types.MembershipSubscribeInfoBeforePaymentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.MembershipSubscribeInfoBeforePaymentQuery,
    Types.MembershipSubscribeInfoBeforePaymentQueryVariables
  >(MembershipSubscribeInfoBeforePaymentDocument, options)
}
export type MembershipSubscribeInfoBeforePaymentQueryHookResult = ReturnType<
  typeof useMembershipSubscribeInfoBeforePaymentQuery
>
export type MembershipSubscribeInfoBeforePaymentLazyQueryHookResult = ReturnType<
  typeof useMembershipSubscribeInfoBeforePaymentLazyQuery
>
export type MembershipSubscribeInfoBeforePaymentQueryResult = Apollo.QueryResult<
  Types.MembershipSubscribeInfoBeforePaymentQuery,
  Types.MembershipSubscribeInfoBeforePaymentQueryVariables
>
export const MileageHistoriesDocument = gql`
  query mileageHistories($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
    mileageHistories(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
      edges {
        node {
          id
          mileageDiff
          membershipAddMileage
          expiredAt
          createdAt
          message
          subMessage
        }
      }
      pageInfo {
        ...pageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useMileageHistoriesQuery__
 *
 * To run a query within a React component, call `useMileageHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMileageHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMileageHistoriesQuery({
 *   variables: {
 *      pageInfo: // value for 'pageInfo'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useMileageHistoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.MileageHistoriesQuery, Types.MileageHistoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MileageHistoriesQuery, Types.MileageHistoriesQueryVariables>(
    MileageHistoriesDocument,
    options
  )
}
export function useMileageHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.MileageHistoriesQuery, Types.MileageHistoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MileageHistoriesQuery, Types.MileageHistoriesQueryVariables>(
    MileageHistoriesDocument,
    options
  )
}
export type MileageHistoriesQueryHookResult = ReturnType<typeof useMileageHistoriesQuery>
export type MileageHistoriesLazyQueryHookResult = ReturnType<typeof useMileageHistoriesLazyQuery>
export type MileageHistoriesQueryResult = Apollo.QueryResult<
  Types.MileageHistoriesQuery,
  Types.MileageHistoriesQueryVariables
>
export const MyPageMembershipBenefitDocument = gql`
  query myPageMembershipBenefit {
    membershipBenefitMonthly(orderBy: "createdAt", pageInfo: { last: 1 }) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
        __typename
      }
      edges {
        node {
          startedAt
          endedAt
          membershipConfirmMileageAmount
          shippingCount
          isFreeFirstMonthMembership
          payDuration
          isOwner
          ...membershipBenefitMonthlyAtHome
        }
      }
      totalCount
    }
  }
  ${MembershipBenefitMonthlyAtHomeFragmentDoc}
`

/**
 * __useMyPageMembershipBenefitQuery__
 *
 * To run a query within a React component, call `useMyPageMembershipBenefitQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPageMembershipBenefitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPageMembershipBenefitQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyPageMembershipBenefitQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.MyPageMembershipBenefitQuery, Types.MyPageMembershipBenefitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.MyPageMembershipBenefitQuery, Types.MyPageMembershipBenefitQueryVariables>(
    MyPageMembershipBenefitDocument,
    options
  )
}
export function useMyPageMembershipBenefitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MyPageMembershipBenefitQuery,
    Types.MyPageMembershipBenefitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.MyPageMembershipBenefitQuery, Types.MyPageMembershipBenefitQueryVariables>(
    MyPageMembershipBenefitDocument,
    options
  )
}
export type MyPageMembershipBenefitQueryHookResult = ReturnType<typeof useMyPageMembershipBenefitQuery>
export type MyPageMembershipBenefitLazyQueryHookResult = ReturnType<typeof useMyPageMembershipBenefitLazyQuery>
export type MyPageMembershipBenefitQueryResult = Apollo.QueryResult<
  Types.MyPageMembershipBenefitQuery,
  Types.MyPageMembershipBenefitQueryVariables
>
export const IntegrateActionPowerOrderItemDocument = gql`
  mutation integrateActionPowerOrderItem($id: ID!) {
    integrateActionPowerOrderItem(id: $id) {
      orderItem {
        actionPowerOrder {
          id
          status
          orderUrl
          requestedAt
        }
      }
    }
  }
`
export type IntegrateActionPowerOrderItemMutationFn = Apollo.MutationFunction<
  Types.IntegrateActionPowerOrderItemMutation,
  Types.IntegrateActionPowerOrderItemMutationVariables
>

/**
 * __useIntegrateActionPowerOrderItemMutation__
 *
 * To run a mutation, you first call `useIntegrateActionPowerOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntegrateActionPowerOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [integrateActionPowerOrderItemMutation, { data, loading, error }] = useIntegrateActionPowerOrderItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIntegrateActionPowerOrderItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.IntegrateActionPowerOrderItemMutation,
    Types.IntegrateActionPowerOrderItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.IntegrateActionPowerOrderItemMutation,
    Types.IntegrateActionPowerOrderItemMutationVariables
  >(IntegrateActionPowerOrderItemDocument, options)
}
export type IntegrateActionPowerOrderItemMutationHookResult = ReturnType<
  typeof useIntegrateActionPowerOrderItemMutation
>
export type IntegrateActionPowerOrderItemMutationResult =
  Apollo.MutationResult<Types.IntegrateActionPowerOrderItemMutation>
export type IntegrateActionPowerOrderItemMutationOptions = Apollo.BaseMutationOptions<
  Types.IntegrateActionPowerOrderItemMutation,
  Types.IntegrateActionPowerOrderItemMutationVariables
>
export const CancelVBankOrderDocument = gql`
  query cancelVBankOrder($orderId: ID!) {
    order(id: $orderId) {
      orderOrderItems {
        totalCount
        edges {
          node {
            id
            productOption {
              name
            }
            approvedTransaction {
              payMethodType
              transactionStatus
            }
          }
        }
      }
    }
  }
`

/**
 * __useCancelVBankOrderQuery__
 *
 * To run a query within a React component, call `useCancelVBankOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancelVBankOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancelVBankOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCancelVBankOrderQuery(
  baseOptions: Apollo.QueryHookOptions<Types.CancelVBankOrderQuery, Types.CancelVBankOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CancelVBankOrderQuery, Types.CancelVBankOrderQueryVariables>(
    CancelVBankOrderDocument,
    options
  )
}
export function useCancelVBankOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.CancelVBankOrderQuery, Types.CancelVBankOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.CancelVBankOrderQuery, Types.CancelVBankOrderQueryVariables>(
    CancelVBankOrderDocument,
    options
  )
}
export type CancelVBankOrderQueryHookResult = ReturnType<typeof useCancelVBankOrderQuery>
export type CancelVBankOrderLazyQueryHookResult = ReturnType<typeof useCancelVBankOrderLazyQuery>
export type CancelVBankOrderQueryResult = Apollo.QueryResult<
  Types.CancelVBankOrderQuery,
  Types.CancelVBankOrderQueryVariables
>
export const InvalidConditionBeforeEstimateOrderDocument = gql`
  query InvalidConditionBeforeEstimateOrder($orderItems: [InvalidConditionBeforeEstimateOrderTypeInput]!) {
    invalidConditionBeforeEstimateOrder(orderItems: $orderItems) {
      actionPowerProducts {
        name
      }
    }
  }
`

/**
 * __useInvalidConditionBeforeEstimateOrderQuery__
 *
 * To run a query within a React component, call `useInvalidConditionBeforeEstimateOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvalidConditionBeforeEstimateOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvalidConditionBeforeEstimateOrderQuery({
 *   variables: {
 *      orderItems: // value for 'orderItems'
 *   },
 * });
 */
export function useInvalidConditionBeforeEstimateOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.InvalidConditionBeforeEstimateOrderQuery,
    Types.InvalidConditionBeforeEstimateOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.InvalidConditionBeforeEstimateOrderQuery,
    Types.InvalidConditionBeforeEstimateOrderQueryVariables
  >(InvalidConditionBeforeEstimateOrderDocument, options)
}
export function useInvalidConditionBeforeEstimateOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.InvalidConditionBeforeEstimateOrderQuery,
    Types.InvalidConditionBeforeEstimateOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.InvalidConditionBeforeEstimateOrderQuery,
    Types.InvalidConditionBeforeEstimateOrderQueryVariables
  >(InvalidConditionBeforeEstimateOrderDocument, options)
}
export type InvalidConditionBeforeEstimateOrderQueryHookResult = ReturnType<
  typeof useInvalidConditionBeforeEstimateOrderQuery
>
export type InvalidConditionBeforeEstimateOrderLazyQueryHookResult = ReturnType<
  typeof useInvalidConditionBeforeEstimateOrderLazyQuery
>
export type InvalidConditionBeforeEstimateOrderQueryResult = Apollo.QueryResult<
  Types.InvalidConditionBeforeEstimateOrderQuery,
  Types.InvalidConditionBeforeEstimateOrderQueryVariables
>
export const OrderFinishDocument = gql`
  query orderFinish($id: ID!) {
    order(id: $id) {
      mileageDiscountAmount
      shippingMileageDiscountAmount
      approvedTransaction {
        id
        amount
        vbankAccountHolderName
        vbankAccountNumber
        vbankBankCode
        vbankBankName
        payMethodType
        paymentText
      }
    }
  }
`

/**
 * __useOrderFinishQuery__
 *
 * To run a query within a React component, call `useOrderFinishQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderFinishQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderFinishQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderFinishQuery(
  baseOptions: Apollo.QueryHookOptions<Types.OrderFinishQuery, Types.OrderFinishQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.OrderFinishQuery, Types.OrderFinishQueryVariables>(OrderFinishDocument, options)
}
export function useOrderFinishLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.OrderFinishQuery, Types.OrderFinishQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.OrderFinishQuery, Types.OrderFinishQueryVariables>(OrderFinishDocument, options)
}
export type OrderFinishQueryHookResult = ReturnType<typeof useOrderFinishQuery>
export type OrderFinishLazyQueryHookResult = ReturnType<typeof useOrderFinishLazyQuery>
export type OrderFinishQueryResult = Apollo.QueryResult<Types.OrderFinishQuery, Types.OrderFinishQueryVariables>
export const ReviewOrderItemDocument = gql`
  query reviewOrderItem($id: ID!) {
    orderItem(id: $id) {
      ...reviewOrderItems
    }
  }
  ${ReviewOrderItemsFragmentDoc}
`

/**
 * __useReviewOrderItemQuery__
 *
 * To run a query within a React component, call `useReviewOrderItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewOrderItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewOrderItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewOrderItemQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ReviewOrderItemQuery, Types.ReviewOrderItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ReviewOrderItemQuery, Types.ReviewOrderItemQueryVariables>(
    ReviewOrderItemDocument,
    options
  )
}
export function useReviewOrderItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewOrderItemQuery, Types.ReviewOrderItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ReviewOrderItemQuery, Types.ReviewOrderItemQueryVariables>(
    ReviewOrderItemDocument,
    options
  )
}
export type ReviewOrderItemQueryHookResult = ReturnType<typeof useReviewOrderItemQuery>
export type ReviewOrderItemLazyQueryHookResult = ReturnType<typeof useReviewOrderItemLazyQuery>
export type ReviewOrderItemQueryResult = Apollo.QueryResult<
  Types.ReviewOrderItemQuery,
  Types.ReviewOrderItemQueryVariables
>
export const UserIsUseAllMileageDocument = gql`
  query userIsUseAllMileage($id: ID!) {
    user(id: $id) {
      isUseAllMileage
    }
  }
`

/**
 * __useUserIsUseAllMileageQuery__
 *
 * To run a query within a React component, call `useUserIsUseAllMileageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIsUseAllMileageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIsUseAllMileageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserIsUseAllMileageQuery(
  baseOptions: Apollo.QueryHookOptions<Types.UserIsUseAllMileageQuery, Types.UserIsUseAllMileageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.UserIsUseAllMileageQuery, Types.UserIsUseAllMileageQueryVariables>(
    UserIsUseAllMileageDocument,
    options
  )
}
export function useUserIsUseAllMileageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.UserIsUseAllMileageQuery, Types.UserIsUseAllMileageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.UserIsUseAllMileageQuery, Types.UserIsUseAllMileageQueryVariables>(
    UserIsUseAllMileageDocument,
    options
  )
}
export type UserIsUseAllMileageQueryHookResult = ReturnType<typeof useUserIsUseAllMileageQuery>
export type UserIsUseAllMileageLazyQueryHookResult = ReturnType<typeof useUserIsUseAllMileageLazyQuery>
export type UserIsUseAllMileageQueryResult = Apollo.QueryResult<
  Types.UserIsUseAllMileageQuery,
  Types.UserIsUseAllMileageQueryVariables
>
export const SellersDocument = gql`
  query sellers($filter: GenericScalar) {
    sellers(filter: $filter) {
      totalCount
      edges {
        node {
          id
          shopName
        }
      }
    }
  }
`

/**
 * __useSellersQuery__
 *
 * To run a query within a React component, call `useSellersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSellersQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.SellersQuery, Types.SellersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.SellersQuery, Types.SellersQueryVariables>(SellersDocument, options)
}
export function useSellersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.SellersQuery, Types.SellersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.SellersQuery, Types.SellersQueryVariables>(SellersDocument, options)
}
export type SellersQueryHookResult = ReturnType<typeof useSellersQuery>
export type SellersLazyQueryHookResult = ReturnType<typeof useSellersLazyQuery>
export type SellersQueryResult = Apollo.QueryResult<Types.SellersQuery, Types.SellersQueryVariables>
export const ToggleProductLikedDocument = gql`
  mutation toggleProductLiked($id: ID!, $input: ProductLikedPromotionInput) {
    toggleProductLiked(id: $id, input: $input) {
      product {
        id
        isLiked
        likedCount
        productPromotion {
          id
          productPromotionType
        }
      }
    }
  }
`
export type ToggleProductLikedMutationFn = Apollo.MutationFunction<
  Types.ToggleProductLikedMutation,
  Types.ToggleProductLikedMutationVariables
>

/**
 * __useToggleProductLikedMutation__
 *
 * To run a mutation, you first call `useToggleProductLikedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleProductLikedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleProductLikedMutation, { data, loading, error }] = useToggleProductLikedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleProductLikedMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.ToggleProductLikedMutation, Types.ToggleProductLikedMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.ToggleProductLikedMutation, Types.ToggleProductLikedMutationVariables>(
    ToggleProductLikedDocument,
    options
  )
}
export type ToggleProductLikedMutationHookResult = ReturnType<typeof useToggleProductLikedMutation>
export type ToggleProductLikedMutationResult = Apollo.MutationResult<Types.ToggleProductLikedMutation>
export type ToggleProductLikedMutationOptions = Apollo.BaseMutationOptions<
  Types.ToggleProductLikedMutation,
  Types.ToggleProductLikedMutationVariables
>
export const RecommendIngredientProductsDocument = gql`
  query RecommendIngredientProducts($id: ID!, $feedIngredientType: String) {
    ingredientBasedFeedRecommendationProducts(id: $id, feedIngredientType: $feedIngredientType) {
      totalCount
      edges {
        node {
          ...ingredientProductItem
        }
      }
    }
  }
  ${IngredientProductItemFragmentDoc}
`

/**
 * __useRecommendIngredientProductsQuery__
 *
 * To run a query within a React component, call `useRecommendIngredientProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendIngredientProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendIngredientProductsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      feedIngredientType: // value for 'feedIngredientType'
 *   },
 * });
 */
export function useRecommendIngredientProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.RecommendIngredientProductsQuery,
    Types.RecommendIngredientProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.RecommendIngredientProductsQuery, Types.RecommendIngredientProductsQueryVariables>(
    RecommendIngredientProductsDocument,
    options
  )
}
export function useRecommendIngredientProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.RecommendIngredientProductsQuery,
    Types.RecommendIngredientProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.RecommendIngredientProductsQuery, Types.RecommendIngredientProductsQueryVariables>(
    RecommendIngredientProductsDocument,
    options
  )
}
export type RecommendIngredientProductsQueryHookResult = ReturnType<typeof useRecommendIngredientProductsQuery>
export type RecommendIngredientProductsLazyQueryHookResult = ReturnType<typeof useRecommendIngredientProductsLazyQuery>
export type RecommendIngredientProductsQueryResult = Apollo.QueryResult<
  Types.RecommendIngredientProductsQuery,
  Types.RecommendIngredientProductsQueryVariables
>
export const BundledProductTotalCountDocument = gql`
  query bundledProductTotalCount($pageInfo: PageInfoInputType, $id: ID!) {
    recommendBundledProducts(pageInfo: $pageInfo, id: $id) {
      totalCount
    }
  }
`

/**
 * __useBundledProductTotalCountQuery__
 *
 * To run a query within a React component, call `useBundledProductTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundledProductTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBundledProductTotalCountQuery({
 *   variables: {
 *      pageInfo: // value for 'pageInfo'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBundledProductTotalCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.BundledProductTotalCountQuery,
    Types.BundledProductTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.BundledProductTotalCountQuery, Types.BundledProductTotalCountQueryVariables>(
    BundledProductTotalCountDocument,
    options
  )
}
export function useBundledProductTotalCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.BundledProductTotalCountQuery,
    Types.BundledProductTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.BundledProductTotalCountQuery, Types.BundledProductTotalCountQueryVariables>(
    BundledProductTotalCountDocument,
    options
  )
}
export type BundledProductTotalCountQueryHookResult = ReturnType<typeof useBundledProductTotalCountQuery>
export type BundledProductTotalCountLazyQueryHookResult = ReturnType<typeof useBundledProductTotalCountLazyQuery>
export type BundledProductTotalCountQueryResult = Apollo.QueryResult<
  Types.BundledProductTotalCountQuery,
  Types.BundledProductTotalCountQueryVariables
>
export const BundledProductsDocument = gql`
  query bundledProducts($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
    products(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
      pageInfo {
        ...pageInfo
      }
      totalCount
      edges {
        node {
          ...bundledProductItem
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${BundledProductItemFragmentDoc}
`

/**
 * __useBundledProductsQuery__
 *
 * To run a query within a React component, call `useBundledProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundledProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBundledProductsQuery({
 *   variables: {
 *      pageInfo: // value for 'pageInfo'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useBundledProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.BundledProductsQuery, Types.BundledProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.BundledProductsQuery, Types.BundledProductsQueryVariables>(
    BundledProductsDocument,
    options
  )
}
export function useBundledProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.BundledProductsQuery, Types.BundledProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.BundledProductsQuery, Types.BundledProductsQueryVariables>(
    BundledProductsDocument,
    options
  )
}
export type BundledProductsQueryHookResult = ReturnType<typeof useBundledProductsQuery>
export type BundledProductsLazyQueryHookResult = ReturnType<typeof useBundledProductsLazyQuery>
export type BundledProductsQueryResult = Apollo.QueryResult<
  Types.BundledProductsQuery,
  Types.BundledProductsQueryVariables
>
export const CachedDisplayCollectionProductInfosDocument = gql`
  query cachedDisplayCollectionProductInfos($id: ID!, $pageInfo: PageInfoInputType) {
    cachedDisplayCollection(id: $id, pageInfo: $pageInfo)
  }
`

/**
 * __useCachedDisplayCollectionProductInfosQuery__
 *
 * To run a query within a React component, call `useCachedDisplayCollectionProductInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useCachedDisplayCollectionProductInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCachedDisplayCollectionProductInfosQuery({
 *   variables: {
 *      id: // value for 'id'
 *      pageInfo: // value for 'pageInfo'
 *   },
 * });
 */
export function useCachedDisplayCollectionProductInfosQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CachedDisplayCollectionProductInfosQuery,
    Types.CachedDisplayCollectionProductInfosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.CachedDisplayCollectionProductInfosQuery,
    Types.CachedDisplayCollectionProductInfosQueryVariables
  >(CachedDisplayCollectionProductInfosDocument, options)
}
export function useCachedDisplayCollectionProductInfosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CachedDisplayCollectionProductInfosQuery,
    Types.CachedDisplayCollectionProductInfosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.CachedDisplayCollectionProductInfosQuery,
    Types.CachedDisplayCollectionProductInfosQueryVariables
  >(CachedDisplayCollectionProductInfosDocument, options)
}
export type CachedDisplayCollectionProductInfosQueryHookResult = ReturnType<
  typeof useCachedDisplayCollectionProductInfosQuery
>
export type CachedDisplayCollectionProductInfosLazyQueryHookResult = ReturnType<
  typeof useCachedDisplayCollectionProductInfosLazyQuery
>
export type CachedDisplayCollectionProductInfosQueryResult = Apollo.QueryResult<
  Types.CachedDisplayCollectionProductInfosQuery,
  Types.CachedDisplayCollectionProductInfosQueryVariables
>
export const LikedProductsWithPromotionDocument = gql`
  query likedProductsWithPromotion($pageInfo: PageInfoInputType) {
    likedProductsWithPromotion(pageInfo: $pageInfo) {
      pageInfo {
        ...pageInfo
      }
      totalCount
      edges {
        node {
          ...likedProductsItem
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${LikedProductsItemFragmentDoc}
`

/**
 * __useLikedProductsWithPromotionQuery__
 *
 * To run a query within a React component, call `useLikedProductsWithPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLikedProductsWithPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLikedProductsWithPromotionQuery({
 *   variables: {
 *      pageInfo: // value for 'pageInfo'
 *   },
 * });
 */
export function useLikedProductsWithPromotionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.LikedProductsWithPromotionQuery,
    Types.LikedProductsWithPromotionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.LikedProductsWithPromotionQuery, Types.LikedProductsWithPromotionQueryVariables>(
    LikedProductsWithPromotionDocument,
    options
  )
}
export function useLikedProductsWithPromotionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.LikedProductsWithPromotionQuery,
    Types.LikedProductsWithPromotionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.LikedProductsWithPromotionQuery, Types.LikedProductsWithPromotionQueryVariables>(
    LikedProductsWithPromotionDocument,
    options
  )
}
export type LikedProductsWithPromotionQueryHookResult = ReturnType<typeof useLikedProductsWithPromotionQuery>
export type LikedProductsWithPromotionLazyQueryHookResult = ReturnType<typeof useLikedProductsWithPromotionLazyQuery>
export type LikedProductsWithPromotionQueryResult = Apollo.QueryResult<
  Types.LikedProductsWithPromotionQuery,
  Types.LikedProductsWithPromotionQueryVariables
>
export const ProductBenefitsDocument = gql`
  query productBenefits($id: ID!, $productPromotion: ID) {
    product(id: $id, productPromotion: $productPromotion) {
      id
      productPromotionCustomerPrice
      maxDiscountCoupon {
        id
        couponGroup {
          name
          discountType
          discountAmount
          discountRate
          maxDiscountAmount
          couponType
          issueType
        }
      }
      reviewMileage
      reviewMileageRate
      orderConfirmMileage
      orderConfirmMileageRate
      mileage
      mileageRate
      membershipMileage
      imageReviewMileage
      imageReviewMileageRate
      membershipOrderConfirmMileage
      membershipOrderConfirmMileageRate
      membershipOrderConfirmDiffMileage
      membershipOrderConfirmDiffMileageRate
    }
  }
`

/**
 * __useProductBenefitsQuery__
 *
 * To run a query within a React component, call `useProductBenefitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductBenefitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductBenefitsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      productPromotion: // value for 'productPromotion'
 *   },
 * });
 */
export function useProductBenefitsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ProductBenefitsQuery, Types.ProductBenefitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ProductBenefitsQuery, Types.ProductBenefitsQueryVariables>(
    ProductBenefitsDocument,
    options
  )
}
export function useProductBenefitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductBenefitsQuery, Types.ProductBenefitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ProductBenefitsQuery, Types.ProductBenefitsQueryVariables>(
    ProductBenefitsDocument,
    options
  )
}
export type ProductBenefitsQueryHookResult = ReturnType<typeof useProductBenefitsQuery>
export type ProductBenefitsLazyQueryHookResult = ReturnType<typeof useProductBenefitsLazyQuery>
export type ProductBenefitsQueryResult = Apollo.QueryResult<
  Types.ProductBenefitsQuery,
  Types.ProductBenefitsQueryVariables
>
export const ProductDetailDocument = gql`
  query productDetail($id: ID!, $productPromotion: ID, $orderBy: String) {
    product(id: $id, productPromotion: $productPromotion) {
      id
      name
      price
      productNumber
      customerPrice
      discountRate
      description
      averageReviewScore
      finalDiscountRate
      reviewCount
      productPromotionCustomerPrice
      isRealSoldOut
      isSoldOut
      isTimesale
      shippingGuide
      shippingNotice
      searchKeywords
      supplyType
      isSingleOption
      isLiked
      likedCount
      mileage
      petType
      pricePerSku
      maxProductOptionSkuCount
      supplyType
      ingredientTypes
      feedCategoryType
      status {
        value
      }
      categories {
        edges {
          node {
            id
            name
            depth
            description
            text
          }
        }
      }
      seller {
        id
        companyName
        isFitpet
      }
      shippingPreset {
        id
        shippingFee
        guide
        notice
        freeShippingBaseAmount
        shippingDeadline
        shippingCompany {
          name
        }
      }
      productPromotion {
        id
        startedAt
        endedAt
        isDelete
        minOrderAmount
        boughtCount
        maxOrderCount
        discountPrice
        discountRate
        productPromotionType
        displayProductInfo {
          displayCollection {
            name
            ad {
              name
            }
          }
        }
      }
      productProductImages {
        edges {
          node {
            id
            displayType
            image
          }
        }
      }
      productProductOptions(isDelete: false, includeNotUse: false, orderBy: $orderBy) {
        totalCount
        edges {
          node {
            ...ProductOptionFragment
            productOptionSkus {
              edges {
                node {
                  sku {
                    id
                    code
                    stockQuantity
                  }
                  count
                }
              }
            }
          }
        }
      }
    }
  }
  ${ProductOptionFragmentFragmentDoc}
`

/**
 * __useProductDetailQuery__
 *
 * To run a query within a React component, call `useProductDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      productPromotion: // value for 'productPromotion'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useProductDetailQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ProductDetailQuery, Types.ProductDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ProductDetailQuery, Types.ProductDetailQueryVariables>(ProductDetailDocument, options)
}
export function useProductDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductDetailQuery, Types.ProductDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ProductDetailQuery, Types.ProductDetailQueryVariables>(
    ProductDetailDocument,
    options
  )
}
export type ProductDetailQueryHookResult = ReturnType<typeof useProductDetailQuery>
export type ProductDetailLazyQueryHookResult = ReturnType<typeof useProductDetailLazyQuery>
export type ProductDetailQueryResult = Apollo.QueryResult<Types.ProductDetailQuery, Types.ProductDetailQueryVariables>
export const ProductDetailSsrDocument = gql`
  query productDetailSSR($id: ID!) {
    product(id: $id) {
      id
      name
      mainImage {
        thumbnail
      }
      brand {
        name
      }
      reviewCount
      averageReviewScore
      productPromotionCustomerPrice
      petType
      status {
        value
      }
    }
  }
`

/**
 * __useProductDetailSsrQuery__
 *
 * To run a query within a React component, call `useProductDetailSsrQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailSsrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailSsrQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductDetailSsrQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ProductDetailSsrQuery, Types.ProductDetailSsrQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ProductDetailSsrQuery, Types.ProductDetailSsrQueryVariables>(
    ProductDetailSsrDocument,
    options
  )
}
export function useProductDetailSsrLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductDetailSsrQuery, Types.ProductDetailSsrQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ProductDetailSsrQuery, Types.ProductDetailSsrQueryVariables>(
    ProductDetailSsrDocument,
    options
  )
}
export type ProductDetailSsrQueryHookResult = ReturnType<typeof useProductDetailSsrQuery>
export type ProductDetailSsrLazyQueryHookResult = ReturnType<typeof useProductDetailSsrLazyQuery>
export type ProductDetailSsrQueryResult = Apollo.QueryResult<
  Types.ProductDetailSsrQuery,
  Types.ProductDetailSsrQueryVariables
>
export const ProductNameDocument = gql`
  query productName($id: ID!) {
    product(id: $id) {
      name
    }
  }
`

/**
 * __useProductNameQuery__
 *
 * To run a query within a React component, call `useProductNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductNameQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ProductNameQuery, Types.ProductNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ProductNameQuery, Types.ProductNameQueryVariables>(ProductNameDocument, options)
}
export function useProductNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductNameQuery, Types.ProductNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ProductNameQuery, Types.ProductNameQueryVariables>(ProductNameDocument, options)
}
export type ProductNameQueryHookResult = ReturnType<typeof useProductNameQuery>
export type ProductNameLazyQueryHookResult = ReturnType<typeof useProductNameLazyQuery>
export type ProductNameQueryResult = Apollo.QueryResult<Types.ProductNameQuery, Types.ProductNameQueryVariables>
export const ProductOgInfoDocument = gql`
  query productOgInfo($id: ID!) {
    product(id: $id) {
      name
      mainImage {
        thumbnail
      }
    }
  }
`

/**
 * __useProductOgInfoQuery__
 *
 * To run a query within a React component, call `useProductOgInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductOgInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductOgInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductOgInfoQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ProductOgInfoQuery, Types.ProductOgInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ProductOgInfoQuery, Types.ProductOgInfoQueryVariables>(ProductOgInfoDocument, options)
}
export function useProductOgInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductOgInfoQuery, Types.ProductOgInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ProductOgInfoQuery, Types.ProductOgInfoQueryVariables>(
    ProductOgInfoDocument,
    options
  )
}
export type ProductOgInfoQueryHookResult = ReturnType<typeof useProductOgInfoQuery>
export type ProductOgInfoLazyQueryHookResult = ReturnType<typeof useProductOgInfoLazyQuery>
export type ProductOgInfoQueryResult = Apollo.QueryResult<Types.ProductOgInfoQuery, Types.ProductOgInfoQueryVariables>
export const ProductReviewCountDocument = gql`
  query productReviewCount($id: ID!) {
    product(id: $id) {
      reviewCount
    }
  }
`

/**
 * __useProductReviewCountQuery__
 *
 * To run a query within a React component, call `useProductReviewCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductReviewCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductReviewCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductReviewCountQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ProductReviewCountQuery, Types.ProductReviewCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ProductReviewCountQuery, Types.ProductReviewCountQueryVariables>(
    ProductReviewCountDocument,
    options
  )
}
export function useProductReviewCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductReviewCountQuery, Types.ProductReviewCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ProductReviewCountQuery, Types.ProductReviewCountQueryVariables>(
    ProductReviewCountDocument,
    options
  )
}
export type ProductReviewCountQueryHookResult = ReturnType<typeof useProductReviewCountQuery>
export type ProductReviewCountLazyQueryHookResult = ReturnType<typeof useProductReviewCountLazyQuery>
export type ProductReviewCountQueryResult = Apollo.QueryResult<
  Types.ProductReviewCountQuery,
  Types.ProductReviewCountQueryVariables
>
export const RecommendBundledProductsDocument = gql`
  query recommendBundledProducts($pageInfo: PageInfoInputType, $id: ID!) {
    recommendBundledProducts(pageInfo: $pageInfo, id: $id) {
      edges {
        node {
          ...bundledProductItem
        }
      }
      pageInfo {
        ...pageInfo
      }
      totalCount
    }
  }
  ${BundledProductItemFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useRecommendBundledProductsQuery__
 *
 * To run a query within a React component, call `useRecommendBundledProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendBundledProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendBundledProductsQuery({
 *   variables: {
 *      pageInfo: // value for 'pageInfo'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecommendBundledProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.RecommendBundledProductsQuery,
    Types.RecommendBundledProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.RecommendBundledProductsQuery, Types.RecommendBundledProductsQueryVariables>(
    RecommendBundledProductsDocument,
    options
  )
}
export function useRecommendBundledProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.RecommendBundledProductsQuery,
    Types.RecommendBundledProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.RecommendBundledProductsQuery, Types.RecommendBundledProductsQueryVariables>(
    RecommendBundledProductsDocument,
    options
  )
}
export type RecommendBundledProductsQueryHookResult = ReturnType<typeof useRecommendBundledProductsQuery>
export type RecommendBundledProductsLazyQueryHookResult = ReturnType<typeof useRecommendBundledProductsLazyQuery>
export type RecommendBundledProductsQueryResult = Apollo.QueryResult<
  Types.RecommendBundledProductsQuery,
  Types.RecommendBundledProductsQueryVariables
>
export const RecommendedProductDetailProductsDocument = gql`
  query recommendedProductDetailProducts(
    $id: ID!
    $pageInfo: PageInfoInputType
    $filter: GenericScalar
    $orderBy: String
  ) {
    recommendedProductDetailProducts(id: $id, pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
      pageInfo {
        ...pageInfo
      }
      totalCount
      edges {
        node {
          ...recommendedProductDetailProductItem
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${RecommendedProductDetailProductItemFragmentDoc}
`

/**
 * __useRecommendedProductDetailProductsQuery__
 *
 * To run a query within a React component, call `useRecommendedProductDetailProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedProductDetailProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedProductDetailProductsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      pageInfo: // value for 'pageInfo'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useRecommendedProductDetailProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.RecommendedProductDetailProductsQuery,
    Types.RecommendedProductDetailProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.RecommendedProductDetailProductsQuery,
    Types.RecommendedProductDetailProductsQueryVariables
  >(RecommendedProductDetailProductsDocument, options)
}
export function useRecommendedProductDetailProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.RecommendedProductDetailProductsQuery,
    Types.RecommendedProductDetailProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.RecommendedProductDetailProductsQuery,
    Types.RecommendedProductDetailProductsQueryVariables
  >(RecommendedProductDetailProductsDocument, options)
}
export type RecommendedProductDetailProductsQueryHookResult = ReturnType<
  typeof useRecommendedProductDetailProductsQuery
>
export type RecommendedProductDetailProductsLazyQueryHookResult = ReturnType<
  typeof useRecommendedProductDetailProductsLazyQuery
>
export type RecommendedProductDetailProductsQueryResult = Apollo.QueryResult<
  Types.RecommendedProductDetailProductsQuery,
  Types.RecommendedProductDetailProductsQueryVariables
>
export const RecommendedRelatedProductsDocument = gql`
  query recommendedRelatedProducts($productId: ID!) {
    recommendedRelatedProducts(id: $productId) {
      edges {
        node {
          ...recommendedRelatedProducts
        }
      }
      totalCount
    }
  }
  ${RecommendedRelatedProductsFragmentDoc}
`

/**
 * __useRecommendedRelatedProductsQuery__
 *
 * To run a query within a React component, call `useRecommendedRelatedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedRelatedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedRelatedProductsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useRecommendedRelatedProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.RecommendedRelatedProductsQuery,
    Types.RecommendedRelatedProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.RecommendedRelatedProductsQuery, Types.RecommendedRelatedProductsQueryVariables>(
    RecommendedRelatedProductsDocument,
    options
  )
}
export function useRecommendedRelatedProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.RecommendedRelatedProductsQuery,
    Types.RecommendedRelatedProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.RecommendedRelatedProductsQuery, Types.RecommendedRelatedProductsQueryVariables>(
    RecommendedRelatedProductsDocument,
    options
  )
}
export type RecommendedRelatedProductsQueryHookResult = ReturnType<typeof useRecommendedRelatedProductsQuery>
export type RecommendedRelatedProductsLazyQueryHookResult = ReturnType<typeof useRecommendedRelatedProductsLazyQuery>
export type RecommendedRelatedProductsQueryResult = Apollo.QueryResult<
  Types.RecommendedRelatedProductsQuery,
  Types.RecommendedRelatedProductsQueryVariables
>
export const RecommendedSimilarProductsDocument = gql`
  query recommendedSimilarProducts($id: ID!, $pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
    recommendedSimilarProducts(id: $id, pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
      pageInfo {
        ...pageInfo
      }
      totalCount
      edges {
        node {
          ...recommendedSimilarProductItem
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${RecommendedSimilarProductItemFragmentDoc}
`

/**
 * __useRecommendedSimilarProductsQuery__
 *
 * To run a query within a React component, call `useRecommendedSimilarProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedSimilarProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedSimilarProductsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      pageInfo: // value for 'pageInfo'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useRecommendedSimilarProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.RecommendedSimilarProductsQuery,
    Types.RecommendedSimilarProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.RecommendedSimilarProductsQuery, Types.RecommendedSimilarProductsQueryVariables>(
    RecommendedSimilarProductsDocument,
    options
  )
}
export function useRecommendedSimilarProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.RecommendedSimilarProductsQuery,
    Types.RecommendedSimilarProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.RecommendedSimilarProductsQuery, Types.RecommendedSimilarProductsQueryVariables>(
    RecommendedSimilarProductsDocument,
    options
  )
}
export type RecommendedSimilarProductsQueryHookResult = ReturnType<typeof useRecommendedSimilarProductsQuery>
export type RecommendedSimilarProductsLazyQueryHookResult = ReturnType<typeof useRecommendedSimilarProductsLazyQuery>
export type RecommendedSimilarProductsQueryResult = Apollo.QueryResult<
  Types.RecommendedSimilarProductsQuery,
  Types.RecommendedSimilarProductsQueryVariables
>
export const SearchedProductsDocument = gql`
  query searchedProducts($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
    searchedProducts(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
      pageInfo {
        ...pageInfo
      }
      totalCount
      edges {
        node {
          ...bundledProductItem
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${BundledProductItemFragmentDoc}
`

/**
 * __useSearchedProductsQuery__
 *
 * To run a query within a React component, call `useSearchedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchedProductsQuery({
 *   variables: {
 *      pageInfo: // value for 'pageInfo'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSearchedProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.SearchedProductsQuery, Types.SearchedProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.SearchedProductsQuery, Types.SearchedProductsQueryVariables>(
    SearchedProductsDocument,
    options
  )
}
export function useSearchedProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchedProductsQuery, Types.SearchedProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.SearchedProductsQuery, Types.SearchedProductsQueryVariables>(
    SearchedProductsDocument,
    options
  )
}
export type SearchedProductsQueryHookResult = ReturnType<typeof useSearchedProductsQuery>
export type SearchedProductsLazyQueryHookResult = ReturnType<typeof useSearchedProductsLazyQuery>
export type SearchedProductsQueryResult = Apollo.QueryResult<
  Types.SearchedProductsQuery,
  Types.SearchedProductsQueryVariables
>
export const UserRestocksDocument = gql`
  query userRestocks($id: ID!) {
    user(id: $id) {
      id
      userRestocks {
        edges {
          node {
            id
            requestedAt
            informedAt
            productOption {
              id
            }
          }
        }
      }
    }
  }
`

/**
 * __useUserRestocksQuery__
 *
 * To run a query within a React component, call `useUserRestocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRestocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRestocksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserRestocksQuery(
  baseOptions: Apollo.QueryHookOptions<Types.UserRestocksQuery, Types.UserRestocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.UserRestocksQuery, Types.UserRestocksQueryVariables>(UserRestocksDocument, options)
}
export function useUserRestocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.UserRestocksQuery, Types.UserRestocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.UserRestocksQuery, Types.UserRestocksQueryVariables>(UserRestocksDocument, options)
}
export type UserRestocksQueryHookResult = ReturnType<typeof useUserRestocksQuery>
export type UserRestocksLazyQueryHookResult = ReturnType<typeof useUserRestocksLazyQuery>
export type UserRestocksQueryResult = Apollo.QueryResult<Types.UserRestocksQuery, Types.UserRestocksQueryVariables>
export const ToggleReviewLikedDocument = gql`
  mutation toggleReviewLiked($id: ID!) {
    toggleReviewLiked(id: $id) {
      review {
        id
        isLiked
        likedCount
      }
    }
  }
`
export type ToggleReviewLikedMutationFn = Apollo.MutationFunction<
  Types.ToggleReviewLikedMutation,
  Types.ToggleReviewLikedMutationVariables
>

/**
 * __useToggleReviewLikedMutation__
 *
 * To run a mutation, you first call `useToggleReviewLikedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleReviewLikedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleReviewLikedMutation, { data, loading, error }] = useToggleReviewLikedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleReviewLikedMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.ToggleReviewLikedMutation, Types.ToggleReviewLikedMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.ToggleReviewLikedMutation, Types.ToggleReviewLikedMutationVariables>(
    ToggleReviewLikedDocument,
    options
  )
}
export type ToggleReviewLikedMutationHookResult = ReturnType<typeof useToggleReviewLikedMutation>
export type ToggleReviewLikedMutationResult = Apollo.MutationResult<Types.ToggleReviewLikedMutation>
export type ToggleReviewLikedMutationOptions = Apollo.BaseMutationOptions<
  Types.ToggleReviewLikedMutation,
  Types.ToggleReviewLikedMutationVariables
>
export const PopularBrandDocument = gql`
  query popularBrand($filter: GenericScalar, $first: Int, $petType: String) {
    brands(filter: $filter, first: $first, petType: $petType) {
      totalCount
      edges {
        node {
          id
          name
          listImage
          backgroundImage
        }
      }
    }
  }
`

/**
 * __usePopularBrandQuery__
 *
 * To run a query within a React component, call `usePopularBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularBrandQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      petType: // value for 'petType'
 *   },
 * });
 */
export function usePopularBrandQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.PopularBrandQuery, Types.PopularBrandQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.PopularBrandQuery, Types.PopularBrandQueryVariables>(PopularBrandDocument, options)
}
export function usePopularBrandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.PopularBrandQuery, Types.PopularBrandQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.PopularBrandQuery, Types.PopularBrandQueryVariables>(PopularBrandDocument, options)
}
export type PopularBrandQueryHookResult = ReturnType<typeof usePopularBrandQuery>
export type PopularBrandLazyQueryHookResult = ReturnType<typeof usePopularBrandLazyQuery>
export type PopularBrandQueryResult = Apollo.QueryResult<Types.PopularBrandQuery, Types.PopularBrandQueryVariables>
export const PopularKeywordsDocument = gql`
  query popularKeywords($petType: String) {
    popularKeywords(petType: $petType) {
      totalCount
      edges {
        node {
          keyword
          rank
          changeRank
          isNewRank
        }
      }
    }
  }
`

/**
 * __usePopularKeywordsQuery__
 *
 * To run a query within a React component, call `usePopularKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularKeywordsQuery({
 *   variables: {
 *      petType: // value for 'petType'
 *   },
 * });
 */
export function usePopularKeywordsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.PopularKeywordsQuery, Types.PopularKeywordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.PopularKeywordsQuery, Types.PopularKeywordsQueryVariables>(
    PopularKeywordsDocument,
    options
  )
}
export function usePopularKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.PopularKeywordsQuery, Types.PopularKeywordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.PopularKeywordsQuery, Types.PopularKeywordsQueryVariables>(
    PopularKeywordsDocument,
    options
  )
}
export type PopularKeywordsQueryHookResult = ReturnType<typeof usePopularKeywordsQuery>
export type PopularKeywordsLazyQueryHookResult = ReturnType<typeof usePopularKeywordsLazyQuery>
export type PopularKeywordsQueryResult = Apollo.QueryResult<
  Types.PopularKeywordsQuery,
  Types.PopularKeywordsQueryVariables
>
export const RecommendSearchKeywordDocument = gql`
  query recommendSearchKeyword($petType: String) {
    recommendSearchKeywords(petType: $petType)
  }
`

/**
 * __useRecommendSearchKeywordQuery__
 *
 * To run a query within a React component, call `useRecommendSearchKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendSearchKeywordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendSearchKeywordQuery({
 *   variables: {
 *      petType: // value for 'petType'
 *   },
 * });
 */
export function useRecommendSearchKeywordQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.RecommendSearchKeywordQuery, Types.RecommendSearchKeywordQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.RecommendSearchKeywordQuery, Types.RecommendSearchKeywordQueryVariables>(
    RecommendSearchKeywordDocument,
    options
  )
}
export function useRecommendSearchKeywordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.RecommendSearchKeywordQuery,
    Types.RecommendSearchKeywordQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.RecommendSearchKeywordQuery, Types.RecommendSearchKeywordQueryVariables>(
    RecommendSearchKeywordDocument,
    options
  )
}
export type RecommendSearchKeywordQueryHookResult = ReturnType<typeof useRecommendSearchKeywordQuery>
export type RecommendSearchKeywordLazyQueryHookResult = ReturnType<typeof useRecommendSearchKeywordLazyQuery>
export type RecommendSearchKeywordQueryResult = Apollo.QueryResult<
  Types.RecommendSearchKeywordQuery,
  Types.RecommendSearchKeywordQueryVariables
>
export const RelatedSearchedKeywordsDocument = gql`
  query relatedSearchedKeywords($keyword: String, $petType: String) {
    relatedSearchedKeywords: searchedKeywords(keyword: $keyword, petType: $petType)
  }
`

/**
 * __useRelatedSearchedKeywordsQuery__
 *
 * To run a query within a React component, call `useRelatedSearchedKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedSearchedKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedSearchedKeywordsQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      petType: // value for 'petType'
 *   },
 * });
 */
export function useRelatedSearchedKeywordsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.RelatedSearchedKeywordsQuery, Types.RelatedSearchedKeywordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.RelatedSearchedKeywordsQuery, Types.RelatedSearchedKeywordsQueryVariables>(
    RelatedSearchedKeywordsDocument,
    options
  )
}
export function useRelatedSearchedKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.RelatedSearchedKeywordsQuery,
    Types.RelatedSearchedKeywordsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.RelatedSearchedKeywordsQuery, Types.RelatedSearchedKeywordsQueryVariables>(
    RelatedSearchedKeywordsDocument,
    options
  )
}
export type RelatedSearchedKeywordsQueryHookResult = ReturnType<typeof useRelatedSearchedKeywordsQuery>
export type RelatedSearchedKeywordsLazyQueryHookResult = ReturnType<typeof useRelatedSearchedKeywordsLazyQuery>
export type RelatedSearchedKeywordsQueryResult = Apollo.QueryResult<
  Types.RelatedSearchedKeywordsQuery,
  Types.RelatedSearchedKeywordsQueryVariables
>
export const SearchedKeywordsWithBrandDocument = gql`
  query searchedKeywordsWithBrand($keyword: String, $petType: String) {
    searchedKeywords(keyword: $keyword, petType: $petType)
    searchedBrands(keyword: $keyword, petType: $petType) {
      id
      name
      listImage
    }
  }
`

/**
 * __useSearchedKeywordsWithBrandQuery__
 *
 * To run a query within a React component, call `useSearchedKeywordsWithBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchedKeywordsWithBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchedKeywordsWithBrandQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      petType: // value for 'petType'
 *   },
 * });
 */
export function useSearchedKeywordsWithBrandQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.SearchedKeywordsWithBrandQuery,
    Types.SearchedKeywordsWithBrandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.SearchedKeywordsWithBrandQuery, Types.SearchedKeywordsWithBrandQueryVariables>(
    SearchedKeywordsWithBrandDocument,
    options
  )
}
export function useSearchedKeywordsWithBrandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchedKeywordsWithBrandQuery,
    Types.SearchedKeywordsWithBrandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.SearchedKeywordsWithBrandQuery, Types.SearchedKeywordsWithBrandQueryVariables>(
    SearchedKeywordsWithBrandDocument,
    options
  )
}
export type SearchedKeywordsWithBrandQueryHookResult = ReturnType<typeof useSearchedKeywordsWithBrandQuery>
export type SearchedKeywordsWithBrandLazyQueryHookResult = ReturnType<typeof useSearchedKeywordsWithBrandLazyQuery>
export type SearchedKeywordsWithBrandQueryResult = Apollo.QueryResult<
  Types.SearchedKeywordsWithBrandQuery,
  Types.SearchedKeywordsWithBrandQueryVariables
>
export const DisplayCollectionProductInfosDocument = gql`
  query displayCollectionProductInfos($id: ID!, $pageInfo: PageInfoInputType, $filter: GenericScalar) {
    displayCollection(id: $id) {
      id
      name
      petType
      collectionType
      displayProductInfos(pageInfo: $pageInfo, filter: $filter) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            id
            product {
              ...displayCollectionProducts
            }
            productpromotion {
              id
              productPromotionType
            }
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${DisplayCollectionProductsFragmentDoc}
`

/**
 * __useDisplayCollectionProductInfosQuery__
 *
 * To run a query within a React component, call `useDisplayCollectionProductInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplayCollectionProductInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayCollectionProductInfosQuery({
 *   variables: {
 *      id: // value for 'id'
 *      pageInfo: // value for 'pageInfo'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDisplayCollectionProductInfosQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.DisplayCollectionProductInfosQuery,
    Types.DisplayCollectionProductInfosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.DisplayCollectionProductInfosQuery, Types.DisplayCollectionProductInfosQueryVariables>(
    DisplayCollectionProductInfosDocument,
    options
  )
}
export function useDisplayCollectionProductInfosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DisplayCollectionProductInfosQuery,
    Types.DisplayCollectionProductInfosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.DisplayCollectionProductInfosQuery,
    Types.DisplayCollectionProductInfosQueryVariables
  >(DisplayCollectionProductInfosDocument, options)
}
export type DisplayCollectionProductInfosQueryHookResult = ReturnType<typeof useDisplayCollectionProductInfosQuery>
export type DisplayCollectionProductInfosLazyQueryHookResult = ReturnType<
  typeof useDisplayCollectionProductInfosLazyQuery
>
export type DisplayCollectionProductInfosQueryResult = Apollo.QueryResult<
  Types.DisplayCollectionProductInfosQuery,
  Types.DisplayCollectionProductInfosQueryVariables
>
