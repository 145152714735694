import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { FrequentlyBoughtItemResponseDto } from '@/apis/rest/generated/types'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { ScrollContainer } from '@/components/domains/etc'
import { NewProductCard } from '@/components/domains/products/new-product-card/new-product-card'
import {
  PRODUCT_CARD_SIZES,
  THUMBNAIL_STATES,
} from '@/components/domains/products/new-product-card/new-product-card.type'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { ProductLocation } from '@/constants/product-location.const'
import { useBottomSheetEventTracker } from '@/containers/event-tracker/bottom-sheet.event.tracker'
import { useCustomRouter } from '@/containers/hooks'
import { useCartBottomSheetRestReduxData } from '@/containers/hooks/cart/useCartBottomSheetRestReduxData'
import { ABTestGroupCase, useAbGroup } from '@/containers/hooks/useAbGroup'
import { RootState } from '@/stores/store'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { getLocationByPathname } from '@/utils/getLocationByPathname'
import { transformProductTypeDefinedByFrontend } from '@/utils/product/product-card.util'
import { removeScrollbar } from '@/utils/utilCSS'

const FrequentlyBoughtProductTitle = '자주 구매한 상품'

interface FrequentlyBoughtProductListProps {
  data: FrequentlyBoughtItemResponseDto[]
}

export const FrequentlyBoughtProductListInBottomSheet = ({ data }: FrequentlyBoughtProductListProps) => {
  const [isTracked, setIsTracked] = useState(false)
  const { ref, inView } = useInView()
  const { reduxProductInCartConfirmationBottomSheet } = useSelector(
    (state: RootState) => state.reduxDataReducers.cartConfirmationBottomSheetData
  )
  const { push, pathname, reload } = useCustomRouter()
  const { color } = useTheme()
  const { resetCartBottomSheetReduxData } = useCartBottomSheetRestReduxData()
  const {
    trackViewAddCartBottomUpProductEvent,
    trackClickAddCartBottomUpSectionMoreEvent,
    trackClickAddCartBottomUpProductEvent,
  } = useBottomSheetEventTracker()
  const { getAbByKey } = useAbGroup()

  const { abCode } = getAbByKey({
    abKey: ABTestGroupCase.BOTTOM_SHEET_RECOMMENDATION_SLOT_IMPROVEMENT_ON_ADD_TO_CART,
  })
  const isNewCartBottomSheet = abCode !== 'A'

  const handleMoreClick = () => {
    if (reduxProductInCartConfirmationBottomSheet) {
      trackClickAddCartBottomUpSectionMoreEvent({
        sectionName: FrequentlyBoughtProductTitle,
        listType: 'frequent_recommend',
        originProductId: reduxProductInCartConfirmationBottomSheet?.id,
        originProductName: reduxProductInCartConfirmationBottomSheet?.name,
        supplyType: reduxProductInCartConfirmationBottomSheet?.supplyType,
        brandId: reduxProductInCartConfirmationBottomSheet?.brand.id,
        brandName: reduxProductInCartConfirmationBottomSheet?.brand.name,
        addLocation: 'bottomsheet',
        location: getLocationByPathname(pathname),
      })
    }
    resetCartBottomSheetReduxData()
    if (pathname.startsWith(ROUTES.MYPAGE.FREQUENTLY_LIST)) {
      reload()
    } else {
      push(ROUTES.MYPAGE.FREQUENTLY_LIST)
    }
  }

  const handleClickProductCardNew = ({
    sectionName,
    productData,
    index,
  }: {
    sectionName: string
    productData: ProductTypeDefinedByFrontend
    index: number
  }) => {
    if (reduxProductInCartConfirmationBottomSheet) {
      trackClickAddCartBottomUpProductEvent({
        originProductId: reduxProductInCartConfirmationBottomSheet?.id,
        originProductName: reduxProductInCartConfirmationBottomSheet?.name,
        productId: productData.id,
        productName: productData.name,
        supplyType: productData.supplyType,
        brandId: productData.brand.id,
        brandName: productData.brand.name,
        sectionName,
        listIndex: index,
        listType: 'frequent_recommend',
      })
    }
  }

  useEffect(() => {
    if (data.length > 0 && reduxProductInCartConfirmationBottomSheet && inView && !isTracked) {
      setIsTracked(true)
      trackViewAddCartBottomUpProductEvent({
        sectionName: FrequentlyBoughtProductTitle,
        listType: 'frequent_recommend',
        originProductId: reduxProductInCartConfirmationBottomSheet?.id,
        originProductName: reduxProductInCartConfirmationBottomSheet?.name,
        supplyType: reduxProductInCartConfirmationBottomSheet?.supplyType,
        brandId: reduxProductInCartConfirmationBottomSheet?.brand.id,
        brandName: reduxProductInCartConfirmationBottomSheet?.brand.name,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length, inView])

  if (!data?.length) {
    return null
  }

  return (
    <FrequentlyBoughtProductListWrapper className="frequently-bought-product-list" ref={ref}>
      <div className="frequently-bought-product-list__header">
        <Typo variant={TypoVariant.Body1Bold} color={color.gray900}>
          {FrequentlyBoughtProductTitle}
        </Typo>
        <button type="button" className="frequently-bought-product-list__link" onClick={handleMoreClick}>
          <Typo variant={TypoVariant.Body3Regular} color={color.gray500}>
            더보기
          </Typo>
          <FlatIcon type={IconNameEnum.IcArrowRight} color={color.gray500} size={IconSizeEnum.Size16} />
        </button>
      </div>
      <ScrollContainer hideRightButton={data.length < 3} buttonPositionY={30}>
        <StyledListContainer isNewCartBottomSheet={isNewCartBottomSheet}>
          {data.map((item, index) => {
            const productData = transformProductTypeDefinedByFrontend(item.product)
            return (
              <StyledProductCardContainer key={productData.id}>
                <NewProductCard
                  product={productData}
                  size={PRODUCT_CARD_SIZES.lg}
                  thumbnailState={THUMBNAIL_STATES.basicLabel}
                  rankingNumber={index}
                  label={{
                    value: `${item.boughtCount}회 구매`,
                    fontColor: color.grayWhite,
                    backgroundColor: color.blue500,
                  }}
                  hasCartButton
                  trackerData={{
                    addLocation: ProductLocation.BottomSheet,
                    sectionName: FrequentlyBoughtProductTitle,
                    listIndex: index,
                    listType: 'frequent_recommend',
                    originProductId: reduxProductInCartConfirmationBottomSheet?.id,
                    originProductName: reduxProductInCartConfirmationBottomSheet?.name,
                  }}
                  onClickProductCard={() =>
                    handleClickProductCardNew({ sectionName: FrequentlyBoughtProductTitle, productData, index })
                  }
                />
              </StyledProductCardContainer>
            )
          })}
        </StyledListContainer>
      </ScrollContainer>
    </FrequentlyBoughtProductListWrapper>
  )
}

const FrequentlyBoughtProductListWrapper = styled.div`
  .frequently-bought-product-list {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.6rem;
    }
    &__link {
      border: none;
      padding: 0;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
`

const StyledListContainer = styled.div<{ isNewCartBottomSheet: boolean }>`
  ${removeScrollbar};
  display: flex;
  gap: 0.8rem;
  margin-bottom: ${({ isNewCartBottomSheet }) => (isNewCartBottomSheet ? '' : '2.4rem')};

  & > *:first-child {
    margin-left: 16px;
  }

  & > *:last-child {
    padding-right: 16px;
  }
`

const StyledProductCardContainer = styled.div`
  position: relative;
`
