import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'
import ORDER_FRAGMENTS from '@/containers/gqls/orders/fragments'
import REVIEW_FRAGMENTS from '@/containers/gqls/reviews/fragments'

export const REVIEW_QUERY_GQLS = {
  REVIEW_LIST: gql`
    query reviewList($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      reviews(pageInfo: $pageInfo, filter: $filter) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            ...reviewList
            reviewReviewImages {
              edges {
                node {
                  ...reviewImage
                }
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${REVIEW_FRAGMENTS.reviewList}
    ${REVIEW_FRAGMENTS.reviewImage}
  `,
  REVIEW_WRITABLE_ORDER_ITEMS: gql`
    query reviewWritableOrderItems($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      reviewWritableOrderItems(pageInfo: $pageInfo, filter: $filter) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            ...orderListItem
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${ORDER_FRAGMENTS.orderListItem}
  `,
  REVIEW_DETAIL: gql`
    query reviewDetail($id: ID!) {
      review(id: $id) {
        id
        reviewBody
        score
        mileage
        isPhotoReview
        productOptionName
        product {
          productNumber
          name
          mainImage {
            thumbnail
          }
        }
        reviewReviewImages {
          edges {
            node {
              ...reviewImage
            }
          }
        }
        orderItem {
          ...orderItemDetail
        }
      }
    }
    ${REVIEW_FRAGMENTS.reviewImage}
    ${ORDER_FRAGMENTS.orderItemDetail}
  `,
}

export default REVIEW_QUERY_GQLS
