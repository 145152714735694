import { FC, PropsWithChildren } from 'react'
import { Row } from 'antd'
import styled from 'styled-components'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { Z_INDEXES, LAYOUT_UNITS } from '@/constants/legacy/constLayout'

export type LayoutBottomFixedProps = {
  backgroundColor?: string
  position?: 'fixed' | 'sticky' | 'relative'
  padding?: string
  isBoxShadowEnabled?: boolean
}

const LayoutBottomFixed: FC<PropsWithChildren<LayoutBottomFixedProps>> = ({
  backgroundColor = GlobalColorEnum.GrayWhite,
  position = 'sticky',
  padding,
  isBoxShadowEnabled = false,
  children,
}) => {
  return (
    <StyledContainer
      background={backgroundColor}
      position={position}
      padding={padding}
      isBoxShadowEnabled={isBoxShadowEnabled}
    >
      <Row>{children}</Row>
    </StyledContainer>
  )
}

const StyledContainer = styled.div<{
  background: string
  position: string
  padding?: string
  isBoxShadowEnabled: boolean
}>`
  /* position: fixed; */
  position: ${({ position }) => position};
  max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  width: 100%;
  bottom: 0;
  z-index: ${Z_INDEXES.BOTTOM_FIXED};
  padding: ${({ theme, padding }) => padding || theme.spacing.size16};
  padding-bottom: calc(env(safe-area-inset-bottom) + 1.6rem);
  box-shadow: ${({ theme, isBoxShadowEnabled }) => {
    return isBoxShadowEnabled ? `rgb(0 0 0 / 5%) 0 -${theme.spacing.size2} ${theme.spacing.size6}` : undefined
  }};
  background-color: ${({ background }) => background};
`

export default LayoutBottomFixed
