import { FC } from 'react'
import { Checkbox } from 'antd'
import { CheckboxProps } from 'antd/lib/checkbox'
import styled from 'styled-components'
import { TextLegacy } from '@/components/common'
import {
  FontColorType,
  FontResponsiveSizeType,
  FontResponsiveWeightType,
  FontWeightType,
} from '@/components/common/texts/TextBase'

type InputCheckboxProps = {
  text?: string
  size?: FontResponsiveSizeType
  weight?: FontWeightType | FontResponsiveWeightType
  color?: FontColorType
} & CheckboxProps

// 디자인 시스템 적용 전 레거시 체크박스 InputCheckBox 사용할 것
const InputCheckboxLegacy: FC<InputCheckboxProps> = ({
  text = '',
  size = { xs: 14, md: 16 },
  weight = 'normal',
  color = 'default',
  ...props
}) => {
  return (
    <StyledCheckbox {...props}>
      <TextLegacy size={size} weight={weight} color={color}>
        {text}
      </TextLegacy>
    </StyledCheckbox>
  )
}

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-disabled .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.color.blue500};
    border-color: ${(props) => props.theme.color.blue500} !important;
  }

  .ant-checkbox-inner::after,
  .ant-checkbox-disabled .ant-checkbox-inner::after {
    position: absolute;
    top: 50%;
    left: 21.5%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #d9d9d9;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    -moz-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    -moz-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: ' ';
  }
`

export default InputCheckboxLegacy
