import { UrlObject } from 'url'
import { CSSProperties, FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import ROUTES from '@/constants/legacy/constRoutes'
import { useCustomRouter, useUserInfo } from '@/containers/hooks'
import { UserContainer } from '@/containers/users/UserContainer'
import { Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider, { appBridgeLogin, isInAppFlag } from '@/utils/utilBridge'
import { isInAndroid, isInApp, isInIos } from '@/utils/utilCommon'
import { getFullPathUrl } from '@/utils/utilURL'

export type RouteType = 'push' | 'replace' | 'none'

export type LinkButtonProps = {
  goBack?: boolean
  url?: string | UrlObject
  setAnchorHref?: boolean
  openNewWindow?: boolean
  shouldLogin?: boolean
  shouldMobileNumberVerification?: boolean
  shallow?: boolean
  routeType?: RouteType
  onClick?: () => void
  afterRouteChange?: () => void
  style?: CSSProperties | undefined
  rel?: string | undefined
  className?: string
}

const LinkButton: FC<PropsWithChildren<LinkButtonProps>> = ({
  url,
  goBack,
  setAnchorHref = true,
  openNewWindow = false,
  shouldLogin = false,
  shouldMobileNumberVerification = false,
  shallow = false,
  routeType = 'push',
  onClick,
  children,
  afterRouteChange,
  style,
  rel,
  className,
}) => {
  const { push, back, replace, asPath } = useCustomRouter()
  const { me } = UserContainer.useContainer()

  const bridgeLogin = appBridgeLogin()

  const { checkLogin } = useUserInfo.useAuthenticatedRoute(url as string)
  const goToUrl = (e: MouseEvent, _url: string | UrlObject) => {
    e.preventDefault()
    // 카카오채널 이고, 앱인경우 예외 처리
    if (routeType === 'none') return

    const hasLoggedIn = checkLogin(shouldLogin)
    if (!hasLoggedIn) return
    // 전화번호가 없는경우 전화번호 인증 처리
    if (hasLoggedIn && shouldMobileNumberVerification) {
      if (!me?.mobileNumber) {
        if (isInAppFlag) {
          appBridgeProvider((bridge: Bridge) => bridge.authentication())
          window.onforeground = () => {
            if (window.getToken().userInfo.mobileNumber.length > 0) {
              bridgeLogin(window.getToken())
              window.location.reload()
              window.onforeground = null
            }
          }
          return
        }

        push({
          pathname: ROUTES.ACCOUNTS.ADD_INFO,
          query: {
            replace_url: asPath,
          },
        })
        return
      }
    }

    if (goBack) {
      if (history.length === 1 && !isInApp()) {
        push(ROUTES.INDEX)
        return
      }
      back()
    }
    if (!url) return
    if (routeType === 'push') {
      push(_url, undefined, { shallow }).then(() => {
        if (afterRouteChange) afterRouteChange()
      })
    } else {
      replace(_url, undefined, { shallow }).then(() => {
        if (afterRouteChange) afterRouteChange()
      })
    }
  }

  // Next/Link 쓸 경우 카루셀 드래그 상태에서 onClick 방지가 안됨
  let hrefString: string
  if (typeof url === 'string') {
    hrefString = url
  } else {
    hrefString = getFullPathUrl(url?.pathname)
  }

  return (
    <StyledAnchor
      role="button"
      tabIndex={0}
      target={openNewWindow ? '_blank' : undefined}
      href={setAnchorHref ? hrefString : undefined}
      style={style}
      rel={rel}
      className={className}
      onClick={
        openNewWindow
          ? (e) => {
              // 회원가입 제3자 동의 약관에 토큰을 안들어와서 일단 이렇게 처리 함... 추후 수정
              if (routeType === 'none' && (isInIos() || isInAndroid())) {
                e.preventDefault()
                const param = { href: url, type: 'out' }
                if (isInIos())
                  return window.webkit.messageHandlers.jsToNative.postMessage({
                    action: 'openBrowser',
                    param: param,
                    callback: null,
                  })
                if (isInAndroid()) return window.bridgeHandler.jsToNative('openBrowser', JSON.stringify(param), null)
              }
            }
          : (e) => {
              if (onClick) onClick()
              // TODO typescript 오류 수정 필요
              // @ts-ignore
              goToUrl(e, url)
            }
      }
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      onKeyDown={openNewWindow ? undefined : (e) => goToUrl(e, url)}
    >
      {/* <div> */}
      {children}
      {/* </div> */}
    </StyledAnchor>
  )
}

const StyledAnchor = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

export default LinkButton
