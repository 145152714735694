import { FC } from 'react'
import styled, { CSSObject } from 'styled-components'
import { S3_FOLDERS_LEGACY, S3_HOST_URL } from '@/constants/legacy/constEnv'
import { SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'

export type ImageAssetNameType =
  | 'alarm-bell'
  | 'arrow-left'
  | 'new_arrow-left'
  | 'arrow-up'
  | 'arrow-left-white'
  | 'arrow-right-white'
  | 'bell-checked'
  | 'board'
  | 'board_new'
  | 'btn_apple_login'
  | 'btn_circle_apple'
  | 'btn_circle_apple_new'
  | 'btn_circle_email_new'
  | 'btn_circle_kakao'
  | 'btn_circle_kakao_new'
  | 'btn_circle_naver'
  | 'btn_circle_naver_new'
  | 'btn_kakao_login'
  | 'btn_kakao_signup'
  | 'btn_naver_login'
  | 'btn_download'
  | 'btn_download_complete'
  | 'chevron_down'
  | 'chevron-down'
  | 'chevron-right'
  | 'chevron-right-white'
  | 'chevron-right-grey'
  | 'chevron-right-grey2'
  | 'chevron-right-darkgray'
  | 'chevron-left'
  | 'chevron_up'
  | 'chevron-up'
  | 'chevron-up-grey'
  | 'chevron-down-grey'
  | 'check_circle_blue'
  | 'close'
  | 'close_new'
  | 'close-white'
  | 'close-white-thin'
  | 'close_2'
  | 'close_keyword'
  | 'new_close-white'
  | 'customer-center'
  | 'customer-center_new'
  | 'dotdotdot'
  | 'find_hospital_select'
  | 'find_hospital'
  | 'home_select'
  | 'home'
  | 'ic_bag'
  | 'ic_basket'
  | 'ic_hamburger'
  | 'ic_search'
  | 'ic_call'
  | 'ic_setting'
  | 'ic_plus'
  | 'ic_minus'
  | 'ic_dog_white'
  | 'ic_dog_gray'
  | 'ic_cat_white'
  | 'ic_cat_gray'
  | 'ic_new_cart'
  | 'ic_new_hamburger'
  | 'ic_new_search'
  | 'ic_point'
  | 'icn-naver'
  | 'ico_notice'
  | 'ico_apple'
  | 'ico_email'
  | 'ico_kakao'
  | 'ico_shipping_car'
  | 'ico_nosearch_result'
  | 'ico_write_review'
  | 'ico_write_review_black'
  | 'ico_shopping'
  | 'ico_my'
  | 'ic_free_shipping'
  | 'ic_membership_family'
  | 'ic_mileage_rate'
  | 'ico_hospital'
  | 'inquiry'
  | 'inquiry_new'
  | 'myinfo_select'
  | 'myinfo'
  | 'switch_off'
  | 'switch_on'
  | 'empty_cart'
  | 'empty_coupon'
  | 'exclamation_circle_red'
  | 'exclamation_circle_grey'
  | 'logo'
  | 'chevron_down_green'
  | 'check_white'
  | 'category-all-arrow'
  | 'event-all'
  | 'logo_kakaopay'
  | 'logo_npay'
  | 'logo_payco'
  | 'logo_tosspay'
  | 'logo_kbpay'
  | 'leftSlideBtn_medium'
  | 'rightSlideBtn_medium'
  | 'leftSlideBtn_large'
  | 'rightSlideBtn_large'
  | 'order_finish'
  | 'order_finish_new'
  | 'star_grey'
  | 'star_blue'
  | 'star_newBlue'
  | 'thumb_up'
  | 'thumb_up_new'
  | 'thumb_up_blue'
  | 'thumb_up_newBlue'
  | 'no_list'
  | 'timesale-badge'
  | 'timesale-newBadge'
  | 'toast_wish_heart_fill'
  | 'toast_wish_heart_empty'
  | 'event_coupon_download_button'
  | 'empty_writable_review'
  | 'mileage'
  | 'home_slide_type1'
  | 'home_slide_type2'
  | 'home_slide_type3'
  | 'home_carousel_right'
  | 'home_carousel_left'
  | 'checked'
  | 'ic_404'
  | 'ic_order_fail'
  | 'ico_cancelIcon'
  | 'section_arrow_right'
  | 'section_arrow_left'
  | 'scroll_top_arrow'
  | 'wish_heart_fill'
  | 'wish_heart_empty'
  | 'wish_list_empty'
  | 'recently_list_empty'
  | 'ic_left'
  | 'Cart'
  | 'ic_close_small'
  | 'ic_check_16'
  | 'ic_nosearch_result_80'
  | 'health-note'
  | 'chevron-right-bold'
  | 'ic_down_gray700_w6'
  | 'ic_remove_gray200_20'
  | 'ic_chevron_up_gray700_24'
  | 'ic_question_gray300_16'
  | 'ic_chevron_down_gray700_24'
  | 'ic_close_default_gray300_20'
  | 'ic_close_default_gray300_16'
  | 'ic_plus_gray200_20'
  | 'ic_plus_gray_20'
  | 'ic_remove_gray_20'
  | 'ic_arrow_right_blue200_24'
  | 'checkbox_disabled_20'
  | 'checkbox_selected_20'
  | 'checkbox_default_20'
  | 'ic_chevron_right_gray400_20'
  | 'check_circle_blue_new'
  | 'ic_warning_300_16'
  | 'ic_chevron_left_gray_20'
  | 'ic_star_10'
  | 'ic_left_gray500_w6'
  | 'ic_cart'
  | 'ic_cart_gray'
  | 'ic_more_gray900_24'
  | 'ic_info'
  | 'share_kakao'
  | 'share_os'
  | 'share_link'
  | 'img_birth'
  | 'img_family'
  | 'img_reward'
  | 'img_shipping'
  | 'fitpet_plus_logo'
  | 'fitpet_plus_logo_simple'
  | 'fitpet_plus_coupon'
  | 'fitpet_plus_members'
  | 'fitpet_plus_reward_dubble'
  | 'fitpet_plus_shipping'
  | 'kakao_share'
  | 'link_share'
  | 'app_share'
  | 'cart_empty_dog'
  | 'cart_empty_cat'
  | 'ic_invite_share'
  | 'ic_invite_copy'
  | 'ic_kakao_login'
  | 'ic_apple_login'
  | 'ic_naver_login'
  | 'ic_email_login'
  | 'ic_chevron_down'
  | 'ic_chevron_right'

type ImageResponsiveSizeType = {
  xs: number
  md: number
}

export type ImageIconBaseProps = {
  name: ImageAssetNameType
  folder?: 'membership'
  size?: number | ImageResponsiveSizeType
  height?: number
  style?: CSSObject
  svg?: boolean
  rem?: boolean
}

const getImageFileName = (name: ImageAssetNameType, svg: boolean): string => {
  if (svg) {
    return `${name}.svg`
  }
  return `${name}.png`
}

const ImageAssetBase: FC<ImageIconBaseProps> = ({ name, folder = '', style, height, size = 12, svg = false, rem }) => {
  const _iconImage = `${S3_HOST_URL}/${S3_FOLDERS_LEGACY.ASSET_IMAGES}/${folder && folder + '/'}${getImageFileName(
    name,
    svg
  )}`

  return (
    <StyledImage
      className={name}
      src={_iconImage}
      alt={name}
      size={size}
      height={height}
      style={style}
      unit={rem ? 'rem' : 'px'}
    />
  )
}

const StyledImage = styled.img<{ size: number | ImageResponsiveSizeType; height?: number; unit?: 'px' | 'rem' }>`
  object-fit: contain;
  object-position: center;
  width: ${({ size, unit }) => {
    return typeof size === 'number' ? `${unit === 'rem' ? size / 10 : size}${unit}` : `${size.md}${unit}`
  }};
  height: ${({ size, height, unit }) => {
    if (height) {
      return `${unit === 'rem' ? height / 10 : height}${unit}`
    }
    return typeof size === 'number' ? `${unit === 'rem' ? size / 10 : size}${unit}` : `${size.md}${unit}`
  }};
  @media ${SCREEN_MEDIA_QUERY.xs} {
    width: ${({ size, unit }) => {
      return typeof size === 'number' ? `${unit === 'rem' ? size / 10 : size}${unit}` : `${size.xs}${unit}`
    }};
    height: ${({ size, height, unit }) => {
      if (height) {
        return `${unit === 'rem' ? height / 10 : size}${unit}`
      }
      return typeof size === 'number' ? `${unit === 'rem' ? size / 10 : size}${unit}` : `${size.xs}${unit}`
    }};
  }
`

export default ImageAssetBase
