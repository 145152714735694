import { FC } from 'react'
import { Col, Row } from 'antd'
import styled, { useTheme } from 'styled-components'
import { TextLegacy } from '@/components/common'
import { LinkButton } from '@/components/common/buttons'
import FlatIcon from '@/components/common/icons/FlatIcon'
import ToolTip from '@/components/common/toolTips/ToolTip'
import { Typo, TypoVariant } from '@/components/common/typography'
import { DateFormatEnum } from '@/constants/date-type.enum'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import { HOSPITAL_HOST } from '@/constants/legacy/constEnv'
import { FIXED_HEIGHT, Z_INDEXES } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { TooltipArrowPositionEnum } from '@/constants/tooltip-arrow-position.enum'
import { TooltipTypeEnum } from '@/constants/tooltip-type-enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import useCustomRouter from '@/containers/hooks/useCustomRouter'
import { UserContainer } from '@/containers/users/UserContainer'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { getTodayString, isTargetDateBeforeReference } from '@/utils/utilDate'
import { localShowHospitalTooltip } from '@/utils/utilLocalStorage'

const NEW_BADGE_TEXT = 'N'

const getHospitalTooltipState = () => {
  const current = localShowHospitalTooltip.load()

  return (
    !current ||
    isTargetDateBeforeReference({
      targetDate: current,
      referenceDate: getTodayString({ format: DateFormatEnum['YYYY-MM-DD'] }),
    })
  )
}

const LayoutBottomTabNavigator: FC = () => {
  const { ableToShowBottomTabNavigator, pathname } = useCustomRouter()
  const { me } = UserContainer.useContainer()

  const myInfoPageTitle = !!me ? 'My' : '로그인/가입'

  const isShowHospitalTooltip = getHospitalTooltipState()

  const hospitalTooltipCallback = () => {
    localShowHospitalTooltip.save(
      getTodayString({
        format: DateFormatEnum['YYYY-MM-DD'],
      })
    )
  }

  if (!ableToShowBottomTabNavigator()) {
    return null
  }

  return (
    <StyledContainer align="middle" justify="space-around">
      <RenderTabItem
        title="쇼핑"
        iconDefault={IconNameEnum.IcTabShoppingOutlined}
        iconActive={IconNameEnum.IcTabShoppingFilled}
        shouldLogin={false}
        url={ROUTES.INDEX}
        selected={pathname === ROUTES.INDEX || pathname.toString().startsWith(ROUTES.HOME.BASE)}
      />
      <RenderTabItem
        title="건강"
        iconDefault={IconNameEnum.IcTabHealthOutlined}
        iconActive={IconNameEnum.IcTabHealthFilled}
        shouldLogin={false}
        url={ROUTES.HEALTH.INTRO}
        selected={pathname === ROUTES.HEALTH.INTRO}
        hasNewBadge
      />
      <RenderTabItem
        title="병원"
        iconDefault={IconNameEnum.IcTabHospitalOutlined}
        iconActive={IconNameEnum.IcTabHospitalFilled}
        shouldLogin={false}
        url={HOSPITAL_HOST}
        selected={pathname === ROUTES.HOME.FIND_HOSPITALS}
      />
      <RenderTabItem
        title={myInfoPageTitle}
        iconDefault={IconNameEnum.IcTabMyOutlined}
        iconActive={IconNameEnum.IcTabMyFilled}
        shouldLogin={true}
        url={ROUTES.MYPAGE.BASE}
        selected={pathname === ROUTES.MYPAGE.BASE}
      />
    </StyledContainer>
  )
}

type RenderTabItemProps = {
  title: string
  iconActive: IconNameEnum
  iconDefault: IconNameEnum
  url: string
  selected: boolean
  shouldLogin: boolean
  isTooltip?: boolean
  tooltipData?: string
  tooltipCallback?: () => void
  hasNewBadge?: boolean
}

const RenderTabItem = ({
  title,
  iconActive,
  iconDefault,
  url,
  selected,
  shouldLogin,
  isTooltip = false,
  tooltipData,
  tooltipCallback,
  hasNewBadge = false,
}: RenderTabItemProps) => {
  const tracker = useTracker()

  const fontColor = selected ? GlobalColorEnum.Gray700 : 'gray400'
  const fontWeight = selected ? 'bold' : 'normal'
  const _iconName = selected ? iconActive : iconDefault

  const { color } = useTheme()

  return (
    <ItemCol>
      {isTooltip && (
        <ToolTipWrapper>
          <ToolTip
            once
            callback={tooltipCallback}
            arrowPosition={TooltipArrowPositionEnum.BottomMiddle}
            tooltipData={
              <Typo variant={TypoVariant.Body3Regular} color={color.grayWhite}>
                {tooltipData}
              </Typo>
            }
            tooltipStyle={{ top: '-5rem', transform: 'translateX(-50%)' }}
            tooltipType={TooltipTypeEnum.Accent}
          />
        </ToolTipWrapper>
      )}

      <LinkButton
        url={url}
        shouldLogin={shouldLogin}
        onClick={() => {
          tracker.triggerCustomEvent(FitpetMallEventEnum.ClickBottomNavigationbar, {
            navigationbarName: title,
          })
        }}
      >
        <>
          <Row justify="center">
            <FlatIcon type={_iconName} size={IconSizeEnum.Size24} />
            {hasNewBadge && (
              <BadgeContainer>
                <NewBadge>{NEW_BADGE_TEXT}</NewBadge>
              </BadgeContainer>
            )}
          </Row>
          <TitleRow justify="center">
            <TextLegacy size={{ xs: 10, md: 12 }} color={fontColor} weight={fontWeight}>
              {title}
            </TextLegacy>
          </TitleRow>
        </>
      </LinkButton>
    </ItemCol>
  )
}

const StyledContainer = styled(Row)`
  width: inherit;
  position: fixed;
  bottom: 0;
  background-color: ${(props) => props.theme.color.grayWhite};
  z-index: ${Z_INDEXES.BOTTOM_TAB_NAVIGATOR};
  border-top: 1px solid ${GlobalColorEnum.Gray70};
  height: ${FIXED_HEIGHT.BOTTOM_TAB_NAVIGATOR}px;
  padding: 0 1.6rem env(safe-area-inset-bottom) 1.6rem;
`

const ItemCol = styled(Col)`
  padding: 0;
  width: 25%;
`

const TitleRow = styled(Row)`
  margin-top: 5px;
`

const ToolTipWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
`

const BadgeContainer = styled.div`
  position: relative;
`

const NewBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${({
    theme: {
      color: { grayWhite },
    },
  }) => grayWhite};
  position: absolute;
  font-size: 0.8rem;
  width: 1.2rem;
  height: 1.2rem;
  background-color: ${({
    theme: {
      color: { red500 },
    },
  }) => red500};
  font-weight: 800;
`

export default LayoutBottomTabNavigator
