import { FC } from 'react'
import { Switch, SwitchProps } from 'antd'

type InputSwitchProps = {} & SwitchProps

const InputSwitch: FC<InputSwitchProps> = (props) => {
  return <Switch {...props} />
}

export default InputSwitch
