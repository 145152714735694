import styled from 'styled-components'
import { match } from 'ts-pattern'
import {
  ABTestGroupCodeEnum,
  FrequentlyBoughtItemResponseDto,
  ProductsListItemResponseDto,
} from '@/apis/rest/generated/types'
import { BundledProductListInBottomSheet } from '@/components/domains/cart/cart-confirmation/bundled-product-list-in-bottom-sheet'
import { FrequentlyBoughtProductListInBottomSheet } from '@/components/domains/cart/cart-confirmation/frequently-bought-product-list-in-bottom-sheet'
import { RecentlyProductListInBottomSheet } from '@/components/domains/cart/cart-confirmation/recently-product-list-in-bottom-sheet'
import { RelatedProductListInBottomSheet } from '@/components/domains/cart/cart-confirmation/related-product-list=in-bottom-sheet'
import { ABTestGroupCase, useAbGroup } from '@/containers/hooks/useAbGroup'
import { GraphQLProductType } from '@/utils/product/product-card.util'

// 원본 src/components/domains/modals/ModalAddCartComplete.tsx

interface CartConfirmationContentProps {
  count: number
  loadDataList: {
    frequentlyBoughtProducts: FrequentlyBoughtItemResponseDto[]
    recommendedProductDetailProducts: GraphQLProductType[]
    recommendBundledProducts: GraphQLProductType[]
    recentlyProducts: ProductsListItemResponseDto[]
  }
}

export const CartConfirmationContent = ({ count, loadDataList }: CartConfirmationContentProps) => {
  const { getAbByKey } = useAbGroup()
  const { abCode } = getAbByKey({
    abKey: ABTestGroupCase.BOTTOM_SHEET_RECOMMENDATION_SLOT_IMPROVEMENT_ON_ADD_TO_CART,
  })

  const { frequentlyBoughtProducts, recommendedProductDetailProducts, recommendBundledProducts, recentlyProducts } =
    loadDataList

  const defaultHeightByAb = abCode === ABTestGroupCodeEnum.A ? 70 : 80

  const heightByCount: { [key: number]: string } = {
    0: 'auto',
    1: 'auto',
    2: `calc(${defaultHeightByAb}vh - 150px)`,
    3: `calc(${defaultHeightByAb}vh - 150px)`,
    4: `calc(${defaultHeightByAb}vh - 150px)`,
  }

  const getSectionsByAb = (abCode: ABTestGroupCodeEnum) => {
    return match(abCode)
      .with(ABTestGroupCodeEnum.A, () => (
        <>
          {/* 자주 구매한 상품 */}
          <FrequentlyBoughtProductListInBottomSheet data={frequentlyBoughtProducts} />
          {/* 이 상품의 연관 상품 ok */}
          <RelatedProductListInBottomSheet data={recommendedProductDetailProducts} />
          {/* 묶음 배송이 가능한 상품 ok */}
          <BundledProductListInBottomSheet data={recommendBundledProducts} />
          {/* 최근 본 상품 */}
          <RecentlyProductListInBottomSheet data={recentlyProducts} />
        </>
      ))
      .with(ABTestGroupCodeEnum.B, () => (
        <>
          {/* 자주 구매한 상품 */}
          <FrequentlyBoughtProductListInBottomSheet data={frequentlyBoughtProducts} />
          {/* 이 상품의 연관 상품 ok */}
          <RelatedProductListInBottomSheet data={recommendedProductDetailProducts} />
          {/* 묶음 배송이 가능한 상품 ok */}
          <BundledProductListInBottomSheet data={recommendBundledProducts} />
          {/* 최근 본 상품 */}
          <RecentlyProductListInBottomSheet data={recentlyProducts} />
        </>
      ))
      .with(ABTestGroupCodeEnum.C, () => (
        <>
          {/* 묶음 배송이 가능한 상품 ok */}
          <BundledProductListInBottomSheet data={recommendBundledProducts} />
          {/* 이 상품의 연관 상품 ok */}
          <RelatedProductListInBottomSheet data={recommendedProductDetailProducts} />
          {/* 자주 구매한 상품 */}
          <FrequentlyBoughtProductListInBottomSheet data={frequentlyBoughtProducts} />
          {/* 최근 본 상품 */}
          <RecentlyProductListInBottomSheet data={recentlyProducts} />
        </>
      ))
      .otherwise(() => (
        <>
          {/* 자주 구매한 상품 */}
          <FrequentlyBoughtProductListInBottomSheet data={frequentlyBoughtProducts} />
          {/* 이 상품의 연관 상품 ok */}
          <RelatedProductListInBottomSheet data={recommendedProductDetailProducts} />
          {/* 묶음 배송이 가능한 상품 ok */}
          <BundledProductListInBottomSheet data={recommendBundledProducts} />
          {/* 최근 본 상품 */}
          <RecentlyProductListInBottomSheet data={recentlyProducts} />
        </>
      ))
  }

  if (!abCode) {
    return null
  }

  return (
    <StyledCartConfirmationContentWrapper className="cart-confirmation-content" heightByCount={heightByCount[count]}>
      {getSectionsByAb(abCode)}
    </StyledCartConfirmationContentWrapper>
  )
}

const StyledCartConfirmationContentWrapper = styled.div<{ heightByCount: string }>`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  // 데이터 개수에 따라 높이 조절해보기
  ${({ heightByCount }) => `height: ${heightByCount};`}
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: calc(4.8rem + env(safe-area-inset-bottom));
`
