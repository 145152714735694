/* eslint-disable react/display-name */
import { FC, forwardRef, RefObject } from 'react'
import { Input, InputRef } from 'antd'
import { InputProps } from 'antd/lib/input'
import styled from 'styled-components'
import { FontResponsiveSizeType, FontSizeType } from '@/components/common/texts/TextBase'
import { SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'

export type InputTextProps = {
  ref?: ((instance: InputRef | null) => void) | RefObject<InputRef> | null | undefined
  textAlign?: 'center' | 'left' | 'right'
  noPadding?: boolean
  fontSize?: FontResponsiveSizeType | FontSizeType
} & InputProps

const InputText: FC<InputTextProps> = forwardRef(
  (
    {
      // ref,
      textAlign = 'left',
      noPadding = false,
      fontSize = {
        xs: 14,
        md: 16,
      },
      suffix,
      ...props
    },
    ref
  ) => {
    return (
      <StyledInput
        ref={ref}
        align={textAlign}
        suffix={suffix || <span />}
        nopadding={noPadding ? 1 : 0}
        fontSize={fontSize}
        {...props}
      />
    )
  }
)

type StyledInputProps = {
  align: 'center' | 'left' | 'right'
  nopadding: number
  fontSize?: FontResponsiveSizeType | FontSizeType
}

const StyledInput = styled(Input)<StyledInputProps>`
  width: 100%;
  min-height: 48px;
  border-radius: 3px;
  caret-color: rgb(67, 67, 67);
  padding-left: ${({ nopadding }) => (nopadding ? '0px' : '10px')};
  padding-right: ${({ nopadding }) => (nopadding ? '0px' : '10px')};
  color: rgb(67, 67, 67);
  text-align: ${({ align }) => align};
  font-size: ${({ fontSize }) => {
    if (!fontSize) return '15px'
    return typeof fontSize === 'number' ? `${fontSize}px !important` : `${fontSize.md}px !important`
  }};

  /* 일부 아이템들은 중복으로 적용해야 함 */
  .ant-input {
    font-size: ${({ fontSize }) => {
      if (!fontSize) return '15px'
      return typeof fontSize === 'number' ? `${fontSize}px !important` : `${fontSize.md}px !important`
    }};
    text-align: ${({ align }) => align};
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;

    ::placeholder {
      color: ${(props) => props.theme.color.gray400} !important;
    }
  }

  .anticon.ant-input-clear-icon {
    font-size: 18px !important;
    color: rgba(0, 0, 0, 0.1);
  }

  ::placeholder {
    color: ${(props) => props.theme.color.gray400} !important;
  }

  //:hover {
  //  border-color: rgb(67, 67, 67);
  //  box-shadow: none;
  //}

  :focus {
    border-color: rgb(67, 67, 67) !important;
    box-shadow: none !important;
  }

  :disabled {
    /* background-color: transparent !important; */
  }

  @media ${SCREEN_MEDIA_QUERY.xs} {
    font-size: ${({ fontSize }) => {
      if (!fontSize) return '15px'
      return typeof fontSize === 'number' ? `${fontSize}px !important` : `${fontSize.md}px !important`
    }};
    .ant-input {
      font-size: ${({ fontSize }) => {
        if (!fontSize) return '15px'
        return typeof fontSize === 'number' ? `${fontSize}px !important` : `${fontSize.md}px !important`
      }};
    }
  }
`

export default InputText
