import { KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import styled from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { SearchedKeywords } from '@/components/domains/search'
import useSearchedKeywords from '@/components/domains/search/hooks/useSearchedKeywords'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import { ARIA_LABEL } from '@/constants/legacy/constData'
import ROUTES from '@/constants/legacy/constRoutes'
import { PathParamKeyEnum } from '@/constants/path-param-key.enum'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { SearchLocationEnum } from '@/constants/search-location.enum'
import { SEARCH_INPUT } from '@/constants/search.const'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter } from '@/containers/hooks'
import { AdPetType } from '@/graphql/generated/schemas'
import { Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider from '@/utils/utilBridge'
import { isInAndroid } from '@/utils/utilCommon'

const debounceTime = 300

const SearchBarInputText = ({ onRefetch }: { onRefetch?: () => void }) => {
  const tracker = useTracker()
  const { pathname, query, replace } = useCustomRouter()
  const {
    [QueryStringKeyEnum.SearchQuery]: searchKeywordQueryParam,
    [QueryStringKeyEnum.SearchPetType]: searchPetType,
    [PathParamKeyEnum.Keyword]: searchKeywordPathParam,
  } = query
  const [searchInputText, setSearchInputText] = useState<string>('')
  const [searchedKeywordText, setSearchedKeywordText] = useState<string>('')

  const inputTextRef = useRef<HTMLInputElement | null>(null)

  const searchKeyword = searchKeywordQueryParam || searchKeywordPathParam || ''

  const { setShowSearchedKeywords } = useSearchedKeywords()

  const isAutoFocus = isInAndroid() && pathname === ROUTES.SEARCH

  // 검색 화면 진입 시 input focus 및 기존 ref 값 설정
  const inputRefCallback = useCallback(
    (node: HTMLInputElement | null) => {
      if (node !== null) {
        inputTextRef.current = node
        if (isAutoFocus) {
          node.focus()
          appBridgeProvider((bridge: Bridge) => bridge.showKeyboard({ isShow: true }))
        }
      }
    },
    [pathname, appBridgeProvider]
  )
  // url 수정하여 접근 시 input 값 수정
  useEffect(() => {
    setSearchInputText(searchKeyword as string)
  }, [searchKeyword])

  const changeTextHandler = (changedText: string) => {
    setSearchInputText(changedText)
    updateSearchedKeyword(changedText)
  }

  // debounceTime 이후에 자동완성 키워드 검색
  const updateSearchedKeyword = useCallback(
    debounce((text: string) => {
      setSearchedKeywordText(text)
    }, debounceTime),
    []
  )

  // 엔터키 입력 시 검색 처리
  const keypressHandler: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const key = e.key || e.keyCode
    if (key !== 'Enter' && key !== 13) {
      return
    }
    const currentSearchText = searchInputText.trim()
    if (currentSearchText) {
      tracker.triggerCustomEvent(FitpetMallEventEnum.SubmitSearchKeyword, {
        searchKeyword: currentSearchText,
        petTypeSearch: searchPetType || AdPetType.All,
      })
      replace({
        pathname: ROUTES.SEARCH_RESULT,
        query: {
          [QueryStringKeyEnum.SearchQuery]: currentSearchText,
          [QueryStringKeyEnum.SearchPetType]: searchPetType,
          [QueryStringKeyEnum.SearchLocation]: SearchLocationEnum.Submit,
        },
      })
      if (currentSearchText === searchKeyword) {
        //이미 검색한 키워드와 현재 키워드가 같으면 재호출하도록
        onRefetch?.()
      }
    }

    // Enter 입력시에는 검색키워드 API 호출하지 않아야 함 (검색 키워드 호출하면 검색결과위에 검색키워드 리스트가 덮어버림)
    updateSearchedKeyword.cancel()
    setShowSearchedKeywords(false)
    inputTextRef.current?.blur()
  }

  // reset 아이콘 클릭 시 검색어 제거하며 검색홈으로 이동
  const resetValue = () => {
    setSearchInputText?.('')
    updateSearchedKeyword('')

    if (pathname === ROUTES.SEARCH_RESULT) {
      replace({
        pathname: ROUTES.SEARCH,
        query: { [QueryStringKeyEnum.SearchPetType]: searchPetType },
      })
    }
    setShowSearchedKeywords(false)
    inputTextRef.current?.focus({ preventScroll: true })
  }

  return (
    <>
      <StyledSearchBarContainer>
        <StyledInput
          type="text"
          inputMode="search"
          aria-label={ARIA_LABEL.SEARCH_BAR_INPUT_TEXT}
          placeholder="검색어를 입력해 주세요."
          value={searchInputText}
          ref={inputRefCallback}
          onKeyPress={keypressHandler}
          onChange={(e) => changeTextHandler(e.target.value)}
          data-cy="search-input"
          autoFocus={isAutoFocus}
          maxLength={SEARCH_INPUT.maxLength}
        />
        {searchInputText && (
          <StyledClearIcon onClick={resetValue} data-cy="search-input-clear">
            <FlatIcon type={IconNameEnum.IcSearchDelete} size={IconSizeEnum.Size16} />
          </StyledClearIcon>
        )}
      </StyledSearchBarContainer>
      <SearchedKeywords searchInputText={searchedKeywordText} />
    </>
  )
}

const StyledSearchBarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const StyledInput = styled.input`
  padding-right: 4.2rem;
  width: 100%;
  height: 4.2rem;
  border: none;
  border-radius: 0.8rem;
  background-color: ${(props) => props.theme.color.gray50};
  text-indent: 1.6rem;
  font-size: 16px;

  ::placeholder {
    color: ${(props) => props.theme.color.gray300};
    font-size: 16px;
  }

  :focus,
  :active {
    outline: none;
  }
`

const StyledClearIcon = styled.div`
  position: absolute;
  cursor: pointer;
  padding: 1.6rem;
  right: 0;
`

export default SearchBarInputText
