import { ParsedUrlQueryInput } from 'querystring'
import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import ROUTES from '@/constants/legacy/constRoutes'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useCoupons } from '@/containers/coupon/useCoupons'
import { useAccountsEventTracker } from '@/containers/event-tracker/accounts.common.event-tracker'
import { useCustomRouter, useUserInfo } from '@/containers/hooks'
import { useTokenSignInMutation, useUserInfoLazyQuery } from '@/graphql/generated/hooks'
import { MeFragment } from '@/graphql/generated/operations'
import {
  CouponGroupCoverageOsType,
  DevicePlatformType,
  SocialServiceSocialType,
  TokenSigninMutation,
} from '@/graphql/generated/schemas'
import { Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider, { appBridgeLogin, isInAppFlag } from '@/utils/utilBridge'
import { isInAndroid, isInApp, isInIos, isMobileWeb } from '@/utils/utilCommon'
import { createDeviceUuid } from '@/utils/utilCrypto'
import { isCheckJoinFirstByDay } from '@/utils/utilDate'
import { localUserToken } from '@/utils/utilLocalStorage'

// 현재 redux와 함께 두번쿼리가 되는 형식입니다.
// TODO 후에 IModel과 연결되어 redux를 제거하고 이거로 이전하는게 목표

const userContainer = () => {
  // me 정보를 useTokenSignInMutation 에 data 로 사용해도 되지만 state 에 저장하는 이유는 필요한 데이터만 들고 있기 위함
  const [me, setMe] = useState<MeFragment>()
  // 로그인 된 현재 user 의 로그인 방식
  const [meSocialType, setMeSocialType] = useState<SocialServiceSocialType>(SocialServiceSocialType.Email)
  const [tokenSignIn, { loading }] = useTokenSignInMutation()
  const bridgeLogin = appBridgeLogin()
  const { push, asPath, replace, isAuthenticatedRoute, pathname, isReady } = useCustomRouter()
  const [userInfo] = useUserInfoLazyQuery({ fetchPolicy: 'network-only' })
  // useEffect 초기화 여부
  const [initilized, setInitilized] = useState(false)
  const { trackUserCouponMileageProperties } = useAccountsEventTracker()

  const { useSaveUserInfo, cleanUserInfo } = useUserInfo
  const { saveTokenNWhoami } = useSaveUserInfo()

  const { couponGroups, isCouponLoading } = useCoupons()

  const getTokens = () => {
    return isInAppFlag ? window?.getToken()?.apiToken : localUserToken.load()
  }

  const getClientType = () => {
    return isInApp() ? CouponGroupCoverageOsType.App : CouponGroupCoverageOsType.Web
  }

  const getPlatformType = () => {
    if (isInAndroid()) {
      return DevicePlatformType.Android
    }
    if (isInIos()) {
      return DevicePlatformType.Ios
    }
    if (isMobileWeb()) {
      return DevicePlatformType.Mobile
    }
    return DevicePlatformType.Pc
  }

  const getDeviceInfoForTokenSignIn = () => {
    const tokens = getTokens()
    if (!tokens?.token || !tokens?.refreshToken) {
      return
    }

    return {
      token: tokens.token,
      refreshToken: tokens.refreshToken,
      clientType: getClientType(),
      platformType: getPlatformType(),
      uuid: createDeviceUuid(),
    }
  }

  const getUserInfo = async (userId: string): Promise<MeFragment | undefined> => {
    const res = await userInfo({ variables: { id: userId } })
    if (!res.error && !res.loading && res.data?.user) {
      setMe(res.data?.user)
    }
    return res.data?.user
  }

  // 웹앱 둘 다 적용되는 login 페이지 이동 함수
  const goToLoginWebAppRouter = (returnUrl?: string, query?: ParsedUrlQueryInput) => {
    if (isInAppFlag) {
      appBridgeProvider((bridge: Bridge) =>
        bridge.login().then((isLoggedIn: boolean) => {
          bridgeLogin(window.getToken())
          if (isLoggedIn && returnUrl) {
            push({
              pathname: returnUrl,
              query,
            })
          }
        })
      )
    } else {
      push({
        pathname: ROUTES.ACCOUNTS.LOGIN,
        query: {
          ...query,
          [QueryStringKeyEnum.ReturnUrl]: returnUrl || asPath,
        },
      })
    }
  }

  const initTokenSignIn = async () => {
    if (!me?.id) {
      const deviceInfo = getDeviceInfoForTokenSignIn()
      if (!deviceInfo) {
        return
      }

      // 필수값이 전부 있다면 다시 한번 유저 정보를 가져온다.
      const result = await tokenSignIn({
        variables: {
          input: {
            device: {
              ...deviceInfo,
            },
          },
        },
      })

      // 에러 상황이 아닐경우 필수데이터 Type 에 추려서 데이터를 저장한다.
      const user = result.data?.tokenSignin?.user
      if (!result.errors && result.data?.tokenSignin?.device?.token) {
        setMe(user)
        setMeSocialType(result.data?.tokenSignin?.device?.socialService?.socialType || SocialServiceSocialType.Email)
        saveTokenNWhoami(result.data?.tokenSignin as TokenSigninMutation)
      } else {
        if (isInApp()) {
          // 앱인경우 토큰 관리를 앱이 하므로 이동작이 웹에서만 돌게 처리
        } else {
          cleanUserInfo()
          if (isAuthenticatedRoute()) {
            replace({
              pathname: ROUTES.ACCOUNTS.LOGIN,
              query: {
                [QueryStringKeyEnum.ReturnUrl]: pathname,
              },
            })
          }
        }
      }

      return user
    }
  }

  const clearUserInfo = () => {
    setMe(undefined)
  }

  useEffect(() => {
    initTokenSignIn().then(() => {
      setInitilized(true)
    })
  }, [])

  useEffect(() => {
    if (me?.id && isCheckJoinFirstByDay() && couponGroups && isReady && !isCouponLoading) {
      trackUserCouponMileageProperties({
        coupons: couponGroups || [],
        mileage: me?.mileagePoint || 0,
      })
    }
  }, [couponGroups, me, isCouponLoading])

  const refreshUserInfo = async (): Promise<MeFragment | undefined> => {
    return getUserInfo(me?.id || '').then((data) => {
      setMe(data)
      return data
    })
  }

  return {
    me,
    meSocialType,
    meIsLoading: loading || !initilized,
    setMe,
    getUserInfo,
    refreshUserInfo,
    // 비로그인, 비멤버십 : undefind, false , 멤버십 : true
    isMembership: me?.isMembership,
    goToLoginWebAppRouter,
    initTokenSignIn,
    clearUserInfo,
  }
}

export const UserContainer = createContainer(userContainer)
