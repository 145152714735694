import { FC } from 'react'
import { Row, Col } from 'antd'
import { ImageAsset } from '@/components/common'
import { PlainBorderButtonLegacy } from '@/components/common/buttons'
import { Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider, { isInAppFlag } from '@/utils/utilBridge'

const ButtonToHome: FC = () => {
  return (
    <Row justify="center" align="middle" style={{ marginTop: 15 }}>
      <Col style={{ width: 187 }}>
        <PlainBorderButtonLegacy
          text="장바구니로 이동"
          suffix={
            <div style={{ paddingLeft: 4, marginTop: -4 }}>
              <ImageAsset name="chevron-right" size={11} />
            </div>
          }
          textStyle={{
            size: { xs: 14, md: 16 },
          }}
          onClick={() => {
            if (isInAppFlag) {
              appBridgeProvider((bridge: Bridge) => bridge.goDeepLink('fitpetmall', '/detail/cart'))
            }
          }}
          height={50}
        />
      </Col>
    </Row>
  )
}

export default ButtonToHome
