import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Modal, Text } from '@/components/common'
import { FontWeightEnum } from '@/constants/font-weight.enum'
import { useCustomRouter } from '@/containers/hooks'
import { UseModalProps } from '@/containers/hooks/useModal'
import { doSetGlobalNoticeModal } from '@/stores/reduxUI'
import { RootState } from '@/stores/store'

const GlobalNoticeModal: FC = () => {
  const globalNoticeModal = useSelector((state: RootState) => state.reduxUIReducers.globalNoticeModal)
  const {
    text,
    subText,
    okText = '확인',
    cancelText = '취소',
    buttonType,
    onOk,
    onCancel,
    afterClose,
    visible,
  } = globalNoticeModal
  const dispatch = useDispatch()
  const router = useCustomRouter()

  useEffect(() => {
    if (globalNoticeModal.visible) {
      dispatch(
        doSetGlobalNoticeModal({
          visible: false,
        })
      )
    }
  }, [router.pathname])

  if (!globalNoticeModal) {
    return null
  }

  // @ts-ignore
  const useModalProps: UseModalProps<any> = {
    visible: visible!,
    hideModal: () => {
      dispatch(
        doSetGlobalNoticeModal({
          ...globalNoticeModal,
          visible: false,
        })
      )
    },
  }
  const textArr = text?.split('\n')
  const subTextArr = subText?.split('\n')

  return (
    <Modal
      useModalProps={useModalProps}
      okText={okText}
      cancelText={cancelText}
      buttonType={buttonType}
      onOk={onOk}
      onCancel={onCancel}
      afterClose={afterClose}
      width={320}
    >
      <StyledWrapper>
        <StyledTextSection>
          {textArr?.map((text, index) => (
            <Text.Body1 data-testid="global-modal-title" key={index} fontWeight={FontWeightEnum.Bold}>
              {text}
            </Text.Body1>
          ))}
        </StyledTextSection>
        {
          <StyledSubTextSection>
            {subTextArr?.map((subText, index) => (
              <Text.Body3 key={index}>{subText}</Text.Body3>
            ))}
          </StyledSubTextSection>
        }
      </StyledWrapper>
    </Modal>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
`
const StyledTextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const StyledSubTextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  text-align: center;
`

export default GlobalNoticeModal
