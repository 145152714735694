import { createContext, CSSProperties, ReactNode, useContext } from 'react'
import styled from 'styled-components'
import { ProductPromotionScheme } from '@/apis/rest/generated/types'
import { CartButton } from '@/components/domains/products/new-product-card/CartButton'
import { NewThumbnail } from '@/components/domains/products/new-product-card/NewThumbnail'
import {
  BasicLabelType,
  PRODUCT_CARD_SIZES,
  ProductCardSize,
  THUMBNAIL_SIZES,
  THUMBNAIL_STATES,
  ThumbnailState,
  ThumbnailTrackingData,
} from '@/components/domains/products/new-product-card/new-product-card.type'

interface VerticalProductCardProps {
  children: ReactNode
  size: ProductCardSize
  onClick: () => void
}

const VerticalProductCardContext = createContext({} as { size: ProductCardSize })

export const VerticalProductCard = ({ children, size, onClick }: VerticalProductCardProps) => {
  const styleVariantBySize: Record<ProductCardSize, CSSProperties> = {
    [PRODUCT_CARD_SIZES.md]: { width: '100%' },
    [PRODUCT_CARD_SIZES.lg]: { width: '13.6rem' },
    [PRODUCT_CARD_SIZES.half]: { width: '100%' },
    [PRODUCT_CARD_SIZES.full]: { width: '100%' },
  }

  const handleClick = () => {
    onClick()
  }

  return (
    <VerticalProductCardContext.Provider
      value={{
        size,
      }}
    >
      <StyledVerticalProductCardWrapper variant={styleVariantBySize[size]} onClick={handleClick}>
        {children}
      </StyledVerticalProductCardWrapper>
    </VerticalProductCardContext.Provider>
  )
}

VerticalProductCard.displayName = 'VerticalProductCard'

interface ThumbnailWrapperProps {
  productId: number
  productName: string
  productPromotionScheme?: ProductPromotionScheme
  thumbnailState?: ThumbnailState
  imageUrl: string
  isLiked: boolean
  isRealSoldOut: boolean
  isNotAvailable?: boolean
  rankingNumber?: number
  basicLabel?: BasicLabelType
  trackingData: ThumbnailTrackingData
  callbackLike?: () => void
}

const Thumbnail = ({
  productId,
  productName,
  productPromotionScheme,
  thumbnailState,
  imageUrl,
  isLiked,
  isRealSoldOut,
  isNotAvailable,
  rankingNumber,
  basicLabel,
  trackingData,
  callbackLike,
}: ThumbnailWrapperProps) => {
  const thumbnailSize = useContext(VerticalProductCardContext).size
  return (
    <NewThumbnail
      productId={productId}
      productName={productName}
      productPromotionScheme={productPromotionScheme}
      size={thumbnailSize || THUMBNAIL_SIZES.lg}
      state={thumbnailState || THUMBNAIL_STATES.basicLabel}
      imageUrl={imageUrl}
      isLiked={isLiked}
      isRealSoldOut={isRealSoldOut}
      isNotAvailable={isNotAvailable}
      rankingNumber={rankingNumber === undefined ? undefined : rankingNumber + 1}
      basicLabel={basicLabel}
      trackingData={trackingData}
      callbackLike={callbackLike}
    />
  )
}

interface CartButton2Props {
  disabled: boolean
  onClick: () => void
}

const CartButtonComponent = ({ disabled, onClick }: CartButton2Props) => {
  return (
    <StyledCartButton>
      <CartButton disabled={disabled} onClick={onClick} />
    </StyledCartButton>
  )
}

interface BodyProps {
  children: ReactNode
}

const Body = ({ children }: BodyProps) => {
  return <StyledBodyWrapper>{children}</StyledBodyWrapper>
}

const StyledVerticalProductCardWrapper = styled.div<{ variant: CSSProperties }>`
  ${({ variant }) => (variant.width ? `width: ${variant.width};` : '')}
  cursor: pointer;
`

const StyledBodyWrapper = styled.div`
  margin-top: 0.8rem;
`

const StyledCartButton = styled.div`
  margin-top: 0.8rem;
`

VerticalProductCard.Thumbnail = Thumbnail
VerticalProductCard.CartButton = CartButtonComponent
VerticalProductCard.Body = Body
