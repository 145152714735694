import { FC, useRef, useState } from 'react'
import { Input, Row } from 'antd'
import { TextAreaProps } from 'antd/lib/input'
import _ from 'lodash'
import styled from 'styled-components'
import { TextLegacy } from '@/components/common'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'

const { TextArea } = Input

type InputTextAreaProps = {
  row?: number
  minLength?: number
  defaultMinLength?: number
  maxLength?: number
  minLengthAddText?: boolean
} & TextAreaProps

const InputTextArea: FC<InputTextAreaProps> = ({
  minLength,
  defaultMinLength,
  maxLength,
  minLengthAddText,
  ...props
}) => {
  const isLastInputEnter = useRef<boolean>(false)
  const [charCount, setCharCount] = useState<number>(defaultMinLength || 0)

  return (
    <StyledContainer>
      <StyledInput
        autoSize={{ minRows: 6, maxRows: 20 }}
        onChangeCapture={(e) => {
          const _val: string = e.currentTarget.value
          isLastInputEnter.current = _.endsWith(_val, '\n')
          const value: string = _val.replace(/(\s*)/g, '')
          let _valLength = _.toArray(value).length
          if (minLength || maxLength) {
            if (maxLength && _valLength > maxLength) {
              _valLength = maxLength
            }
            setCharCount(_valLength)
          }
        }}
        minLength={minLength}
        maxLength={maxLength}
        onPressEnter={(e) => {
          if (isLastInputEnter.current) {
            e.preventDefault()
          }
        }}
        {...props}
      ></StyledInput>
      {(maxLength || minLength) && (
        <CountContainer>
          <Row justify="end">
            <TextLegacy size={{ xs: 12, md: 14 }} color="gray400">
              {`글자수 `}
              <TextLegacy size={{ xs: 12, md: 14 }}>{charCount}</TextLegacy>
              {minLengthAddText
                ? getReviewLengthCount({ maxLength, minLength })
                : getLengthCount({ maxLength, minLength })}
            </TextLegacy>
          </Row>
        </CountContainer>
      )}
    </StyledContainer>
  )
}

const getLengthCount = ({ maxLength, minLength }: { maxLength?: number; minLength?: number }) => {
  // minLength 가 있을 경우 먼저 노출
  if (minLength) {
    return ` (최소 ${minLength}자)`
  }
  if (maxLength) {
    return ` (최대 ${maxLength}자)`
  }
  return null
}

const getReviewLengthCount = ({ maxLength, minLength }: { maxLength?: number; minLength?: number }) => {
  // minLength 가 있을 경우 먼저 노출
  if (minLength) {
    return ` (적립금 지급 최소 ${minLength}자)`
  }
  if (maxLength) {
    return ` (최대 ${maxLength}자)`
  }
  return null
}

const StyledContainer = styled.div`
  border: 1px solid ${GlobalColorEnum.Gray70};
  border-radius: 3px;
  background-color: ${(props) => props.theme.color.grayWhite};
`

const StyledInput = styled(TextArea)`
  width: 100%;
  font-size: 15px !important;
  line-height: 20px !important;
  padding: 10px;
  /* border: 1px solid ${GlobalColorEnum.Gray70}; */
  border: none !important;

  :focus {
    box-shadow: none;
  }

  @media ${SCREEN_MEDIA_QUERY.xs} {
    font-size: 13px !important;
  }
`

const CountContainer = styled.div`
  padding: 0 10px 6px 0;
`

export default InputTextArea
