import { FC } from 'react'
import { Col, Row } from 'antd'
import { TextLegacy } from '@/components/common'
import { SocialIcon } from '@/components/common/etc'
import { SocialServiceSocialType } from '@/graphql/generated/schemas'

type SocialIconNameProps = {
  socialType: SocialServiceSocialType
}

const SocialIconName: FC<SocialIconNameProps> = ({ socialType }) => {
  const getName = (): string => {
    switch (socialType) {
      case SocialServiceSocialType.Apple:
        return '애플'
      case SocialServiceSocialType.Naver:
        return '네이버'
      case SocialServiceSocialType.Kakao:
        return '카카오톡'
      default:
        return '이메일'
    }
  }

  return (
    <Row gutter={10} align="middle">
      <Col>
        <SocialIcon socialType={socialType} />
      </Col>
      <Col>
        <TextLegacy>{getName()}</TextLegacy>
      </Col>
    </Row>
  )
}

export default SocialIconName
