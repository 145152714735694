import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import moment, { Moment } from 'moment'
import { DateFormatEnum } from '@/constants/date-type.enum'
import { localLastConnectedDate } from '@/utils/utilLocalStorage'

dayjs.extend(isBetween)
dayjs.extend(duration)

//Todo : moment는 번들링 사이즈가 커서 번들 사이즈가 작은 date 라이브러리를 사용하여 리팩토링이 필요합니다.

export const DATE_DISPLAY_FORMAT = 'YYYY.MM.DD'
export const DATE_TIME_DISPLAY_FORMAT = 'YYYY.MM.DD HH:mm'
export const BOARD_DATE_DISPLAY_FORMAT = 'YY.MM.DD'
export const DATE_FORMAT_MMDD = 'MM.DD'

export const YEAR_FORMAT = 'YYYY'
export const YEAR_MONTH_FORMAT = 'YYYY.MM'
export const DATE_FORMAT_YYYYMMDDHHmm = 'YYYYMMDDHHmm'
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD'
export const DATE_FORMAT_MMDDHHmm_KOREAN = 'M월 D일 HH:mm'

const _addPeriod = (addValue: number, periodType: 'days' | 'hours', date?: Date, format?: string) => {
  let _date
  if (!date) {
    _date = new Date()
  } else {
    _date = date
  }
  return moment(_date)
    .add(addValue, periodType)
    .format(format || DATE_DISPLAY_FORMAT)
}

export const addDay = (addValue: number, date?: Date, format?: string) => {
  return _addPeriod(addValue, 'days', date, format)
}

export const addHour = (addValue: number, date?: Date, format?: string) => {
  return _addPeriod(addValue, 'hours', date, format)
}

export const date2string = (date: Moment | Date | string | '', format?: string): string => {
  if (date) return moment(date).format(format || DATE_DISPLAY_FORMAT)
  return '-'
}

export const isNowAfter = (subjectDateTime: string | Moment) => {
  const _subjectDateTime = typeof subjectDateTime === 'string' ? moment(subjectDateTime).utc() : subjectDateTime

  const now = moment()
  return now.isAfter(_subjectDateTime)
}

export const isNowBefore = (subjectDateTime: string | Moment) => {
  const _subjectDateTime = typeof subjectDateTime === 'string' ? moment(subjectDateTime).utc() : subjectDateTime

  const now = moment()
  return now.isBefore(_subjectDateTime)
}

export const formatDate = (
  date: Moment | Date | string | null | undefined | '',
  format = DATE_DISPLAY_FORMAT
): string => {
  if (date) return moment(date).format(format)
  return '-'
}

export const nowDate = () => {
  const _now: any = Date.now()
  return formatDate(_now)
}

export const isDateBefore = (oneDate: string | Moment, otherDate: string | Moment | undefined | null) => {
  if (!otherDate) {
    return false
  }
  const oneDate_ = typeof oneDate === 'string' ? moment(oneDate) : oneDate
  const otherDate_ = typeof otherDate === 'string' ? moment(otherDate) : otherDate
  return oneDate_.diff(otherDate_) <= 0
}

export const isDateAfter = (oneDate: string | Moment, otherDate: string | Moment | undefined | null) => {
  if (!otherDate) {
    return false
  }
  const oneDate_ = typeof oneDate === 'string' ? moment(oneDate) : oneDate
  const otherDate_ = typeof otherDate === 'string' ? moment(otherDate) : otherDate
  return oneDate_.diff(otherDate_) >= 0
}

export const diffDateNow = (startDate: string) => {
  const now = moment(new Date())
  const end = moment(startDate)
  const duration = moment.duration(now.diff(end))
  const days = duration.asDays()
  return Math.floor(days)
}

/**
 * 주어진 날짜(`date`)가 유효한 날짜인지 확인합니다.
 * @param {string | Date} date - 유효성을 검사할 날짜입니다. Date 객체 또는 문자열 형태로 표현됩니다.
 * @returns {boolean} - 날짜가 유효하면 true, 그렇지 않으면 false를 반환합니다.
 */
const isValidDate = (date?: string | Date): boolean => {
  if (!date) {
    return false
  }
  return dayjs(date).isValid()
}

/**
 * 주어진 날짜를 지정된 형식(`format`)으로 변환합니다.
 * @param {Date | string} date - 변환할 날짜입니다. Date 객체 또는 문자열 형태로 표현됩니다.
 * @param {DateFormatEnum} format - 변환할 형식을 지정하는 열거형 변수입니다.
 * @returns {string} - 지정된 형식으로 변환된 날짜의 문자열 표현입니다.
 */

export const convertDateWithFormat = ({ date, format }: { date?: Date | string; format: DateFormatEnum }): string => {
  if (!date || !isValidDate(date)) {
    return '-'
  }
  return dayjs(date).format(format)
}

/**
 * 주어진 시작일과 종료일 사이의 차이를 지정된 단위(unit)로 계산합니다.
 * @param startDate {Date} - 계산에 사용할 시작일입니다. 지정하지 않으면 현재 날짜가 기본값으로 사용됩니다.
 * @param endDate {Date} - 계산에 사용할 종료일입니다. startDate와의 차이를 계산합니다.
 * @returns {number} - 시작일과 종료일 사이의 차이를 날짜(days)로 계산한 결과값입니다.
 */
export const getDiffDays = ({ startDate = new Date(), endDate }: { startDate: Date; endDate: Date }): number =>
  dayjs(endDate).startOf('days').diff(dayjs(startDate).startOf('days'), 'days')

/**
 * 지정된 날짜가 시작 날짜와 종료 날짜 사이에 포함(파라미터로 전달된 날짜 포함)되는지 여부를 확인하는 함수
 * @param targetDate - 확인할 날짜 (기본값: 현재 날짜)
 * @param startDate - 시작 날짜
 * @param endDate - 종료 날짜
 * @returns 지정된 날짜가 시작 날짜와 종료 날짜 사이에 포함되면 true를 반환하고, 그렇지 않으면 false를 반환
 */
export const isBetweenDays = ({
  targetDate,
  startDate,
  endDate,
}: {
  targetDate: Date | string
  startDate: Date | string
  endDate: Date | string
}): boolean => {
  return dayjs(targetDate).isBetween(dayjs(startDate), dayjs(endDate), 'days', '[]')
}

// 유저프로퍼티용 날짜 기준 첫진입 비교 함수
export const isCheckJoinFirstByDay = () => {
  const lastConnectionDate: string | undefined = localLastConnectedDate.load()
  const today = dayjs()

  if (!lastConnectionDate || dayjs(lastConnectionDate).isBefore(dayjs(), 'day')) {
    localLastConnectedDate.save(today.format('YYYY-MM-DD'))
    return true
  } else {
    return false
  }
}

// targetDate가 referenceDate보다 이전인지 확인하는 함수
export const isTargetDateBeforeReference = ({
  targetDate,
  referenceDate,
}: {
  targetDate: string | Date
  referenceDate: string | Date
}) => {
  if (!targetDate || dayjs(targetDate).isBefore(referenceDate, 'day')) {
    return true
  } else {
    return false
  }
}

export const getToday = () => {
  return dayjs()
}
// 오늘 날짜 문자열 반환
export const getTodayString = ({ format }: { format: DateFormatEnum }) => {
  return dayjs().format(format)
}

export const getMonth = (date: Dayjs) => {
  return dayjs(date).month() + 1 // dayjs 월은 0부터 시작하므로 API와 일치시키기 위해 1을 더함
}

export const getYear = (date: Dayjs) => {
  return dayjs(date).year()
}

export const getAddMonth = (date: Dayjs) => {
  return dayjs(date).add(1, 'month')
}

export const getSubtractMonth = (date: Dayjs) => {
  return dayjs(date).subtract(1, 'month')
}

export const isSameMonth = (date: Dayjs) => {
  return date.isSame(dayjs(), 'month')
}

export const isSameMonthYearBetween = (date: Dayjs, target: Dayjs) => {
  return date.isSame(target, 'month') && date.isSame(target, 'year')
}

export const getDateByFormat = (date: string) => {
  return dayjs(date)
}

export const getTodayDate = () => {
  return dayjs().toDate()
}

export const getDuration = (diff: number) => {
  return dayjs.duration(diff)
}

export const getDurationDays = (duration: plugin.Duration) => {
  return duration.asDays()
}

export const getDurationHours = (duration: plugin.Duration) => {
  return duration.hours()
}

export const getDurationMinutes = (duration: plugin.Duration) => {
  return duration.minutes()
}

export const getDurationSeconds = (duration: plugin.Duration) => {
  return duration.seconds()
}

/**
 * 주어진 날짜와 현재 날짜 사이의 차이를 계산하는 함수입니다.
 * @param {string} date - 비교할 날짜입니다. 문자열 형태로 표현됩니다.
 * @returns {number} - 주어진 날짜와 현재 날짜 사이의 차이를 반환합니다.
 */
export const getDiff = (date: string) => {
  return dayjs(date).diff(dayjs())
}
