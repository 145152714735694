import { ONELINK_HOST } from '@/constants/legacy/constEnv'
import { AppCartProductOptionType, Bridge, EventTrackingOption, GoViewType, ShareType } from '@/utils/bridge/bridge'
import { isInApp } from '@/utils/utilCommon'

export class InAppBridge implements Bridge {
  private bridge?: Bridge

  init = (): void => {
    if (isInApp()) {
      if (window.__FitPetBridge__) this.bridge = window.__FitPetBridge__
    }
  }

  intro = (): Promise<object> => {
    return this.bridge?.intro()
  }
  // IOS 결제시 에서 login_type : APPLE  이고 phone 이 없는 경우는 로그인 대신 호출해야 함
  authentication = (): Promise<object> => {
    return this.bridge?.authentication()
  }

  login = (): Promise<object> => {
    return this.bridge?.login()
  }

  logout = (): Promise<object> => {
    return this.bridge?.logout()
  }

  acceptTerms = (): Promise<object> => {
    return this.bridge?.acceptTerms()
  }

  goAccountsRegister = (): void => {
    return this.bridge?.goAccountsRegister()
  }

  picture = (): Promise<object> => {
    return this.bridge?.picture()
  }

  openBrowser = (href: string, type?: string) => {
    this.bridge?.openBrowser(href, type)
  }

  amplitudeEvent = (options: EventTrackingOption) => {
    this.bridge?.amplitudeEvent(options)
  }

  appsFlyerEvent = (options: EventTrackingOption) => {
    this.bridge?.appsFlyerEvent(options)
  }

  brazeEvent = (options: EventTrackingOption) => {
    this.bridge?.brazeEvent(options)
  }

  brazeRevenueEvent = (options: EventTrackingOption) => {
    this.bridge?.brazeRevenueEvent(options)
  }

  amplitudeRevenueEvent = (options: EventTrackingOption) => {
    this.bridge?.amplitudeRevenueEvent(options)
  }

  linkShare = (param: ShareType): Promise<object> => {
    // 공유시 삭제 해야 할키
    // @ts-ignore
    delete param?.query?.productId

    // link 설정
    const shareLink = param?.link || ONELINK_HOST
    // @ts-ignore
    // param.query.location = ''
    // 이벤트 파라미터 추가
    return this.bridge?.linkShare({
      title: param.title,
      link: shareLink,
      event: param.event,
    })
  }

  goDeepLink = (serviceType: string, path?: string) => {
    this.bridge?.goDeepLink(serviceType, path)
  }

  goAppUpdateAction = () => {
    this.bridge?.goAppUpdateAction()
  }

  setting = (param: object): Promise<object> => {
    return this.bridge?.setting(param)
  }

  storageGetItem = (keyName: string) => {
    return this.bridge?.storageGetItem(keyName)
  }
  storageSetItem = (keyName: string, param: object | string) => {
    return this.bridge?.storageSetItem(keyName, param)
  }
  storageRemoveItem = (keyName: string) => {
    return this.bridge?.storageRemoveItem(keyName)
  }
  storageClear = () => {
    return this.bridge?.storageClear()
  }

  routerPush = (url: string, data?: object): Promise<object> => {
    return this.bridge?.routerPush(url, data)
  }

  routerGo = (step: number): Promise<object> => {
    return this.bridge?.routerGo(step)
  }

  routerReplace = (url: string, data?: object): Promise<object> => {
    return this.bridge?.routerReplace(url, data)
  }

  insertNonLoginCartProductOptions = (data: AppCartProductOptionType[]): Promise<object> => {
    return this.bridge?.insertNonLoginCartProductOptions(data)
  }
  updateNonLoginCartProductOptions = (data: AppCartProductOptionType[]): Promise<object> => {
    return this.bridge?.updateNonLoginCartProductOptions(data)
  }
  deleteNonLoginCartProductOptions = (data: AppCartProductOptionType[]): Promise<object> => {
    return this.bridge?.deleteNonLoginCartProductOptions(data)
  }

  iosBounceToggle = ({ isShow }: { isShow: boolean }): Promise<object> | undefined => {
    return this.bridge?.iosBounceToggle({ isShow })
  }

  statusBarColorWhite = ({ isWhite }: { isWhite: boolean }): Promise<object> | undefined => {
    return this.bridge?.statusBarColorWhite({ isWhite })
  }

  showKeyboard = ({ isShow }: { isShow: boolean }): Promise<object> | undefined => {
    return this.bridge?.showKeyboard({ isShow })
  }
  goView = ({ type }: { type: GoViewType }): Promise<object> | undefined => {
    return this.bridge?.goView({ type })
  }
}
