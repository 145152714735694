import { decamelize } from '@ridi/object-case-converter'
import { EventTrackerTypeEnum } from '@/constants/event-tracker-type.enum'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { PlatformDetailEnum } from '@/constants/platform-detail.enum'
import { ServiceEnum } from '@/constants/service.enum'
import { AdPetType } from '@/graphql/generated/schemas'
import { FitpetMallEventPropertyType } from '@/utils/event-tracker/events'
import { AmplitudeHandler, AppsflyerHandler, BrazeHandler, GTMHandler } from '@/utils/event-tracker/handlers'
import { EventHandler } from '@/utils/event-tracker/handlers/baseEventHandler'
import { getPlatform } from '@/utils/utilCommon'

export class Tracker {
  private userProperties: any = {}
  private platform: PlatformDetailEnum = PlatformDetailEnum.PcWeb
  private petTypeMall?: AdPetType
  private eventHandleObject: {
    [key in EventTrackerTypeEnum]: EventHandler
  }
  constructor(props?: { petTypeMall?: AdPetType }) {
    this.petTypeMall = props?.petTypeMall
    const [GTMHandle, AmplitudeHandle, BrazeHandle, AppsflyerHandle] = [
      new GTMHandler(),
      new AmplitudeHandler(),
      new BrazeHandler(),
      new AppsflyerHandler(),
    ]
    this.eventHandleObject = {
      [EventTrackerTypeEnum.Appsflyer]: AppsflyerHandle,
      [EventTrackerTypeEnum.GTM]: GTMHandle,
      [EventTrackerTypeEnum.Amplitude]: AmplitudeHandle,
      [EventTrackerTypeEnum.Braze]: BrazeHandle,
    }
  }
  setPlatform = (platform: PlatformDetailEnum) => {
    this.platform = platform
  }
  setPetTypeMall = (petType: AdPetType) => {
    this.petTypeMall = petType
  }
  initialize = () => {
    Object.values(this.eventHandleObject).forEach((handler) => handler.initialize())
  }
  setUserId = (user: string) => {
    // this.user = user
    Object.values(this.eventHandleObject).forEach((handler) => handler.setUserId(user))
  }
  setUserProperties = (properties: any) => {
    this.userProperties = { ...this.userProperties, ...properties }
    Object.values(this.eventHandleObject).forEach((handler) => handler.setUserProperties(this.userProperties))
  }
  triggerPageviewEvent = (url: string) => {
    Object.values(this.eventHandleObject).forEach((handler) => handler.sendPageviewEvent(url))
  }
  triggerCustomEvent = (event?: FitpetMallEventEnum, properties: any = {}) => {
    if (!this.petTypeMall || !event) {
      return
    }
    try {
      const eventProperties = this.getEventProperties(event, properties)
      if (process.env.TRACKER_LOGGER === 'true' && process.env.NODE_ENV !== 'production') {
        console.groupCollapsed(`%c 이벤트명: ${event}`, 'color: skyblue;')
        console.table(eventProperties)
        console.groupEnd()
      }
      Object.values(this.eventHandleObject).forEach((handler) =>
        handler.sendCustomEvent(event, eventProperties, this.platform)
      )
    } catch (e) {
      console.error(e)
    }
  }
  triggerEvent = (
    event: FitpetMallEventEnum,
    eventTrackerTypes: EventTrackerTypeEnum[],
    properties: Partial<FitpetMallEventPropertyType> = {}
  ) => {
    if (!this.petTypeMall) {
      return
    }
    try {
      const eventProperties = this.getEventProperties(event, properties)
      if (process.env.TRACKER_LOGGER === 'true' && process.env.NODE_ENV !== 'production') {
        console.groupCollapsed(`%c 이벤트명: ${event} / 타겟: ${eventTrackerTypes}`, 'color: skyblue;')
        console.table(eventProperties)
        console.groupEnd()
      }
      eventTrackerTypes.forEach((tracker) =>
        this.eventHandleObject[tracker].sendEvent(event, this.platform, eventProperties)
      )
    } catch (e) {
      console.error(e)
    }
  }
  getEventProperties = (
    event: FitpetMallEventEnum,
    properties: Partial<FitpetMallEventPropertyType>
  ): Record<string, any> =>
    decamelize(
      {
        platformDetail: getPlatform(),
        petTypeMall: this.petTypeMall,
        service: ServiceEnum.Fitpetmall,
        ...properties,
      },
      { recursive: true }
    )
}
