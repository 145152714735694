/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Fitpet Mall
 * 핏펫몰 API 스펙

API 스펙 작성시 다음 가이드를 숙지하시기 바랍니다.

- [Open API 가이드 참고](https://swagger.io/docs/specification/about/)
- [Kotlin-Spring Open API Generator 설정 참고](https://openapi-generator.tech/docs/generators/kotlin-spring)
- REST API 디자인 가이드 참고
  - doc > guide > rest-api-design.md
- OpenAPI Spec 컨벤션 참고
  - doc > guid > openapi-spec-convention.md
- 설명(description) 작성시 마크다운([CommonMark](https://commonmark.org/help/)) 구문 참고

로컬에서 스웨거 에디터를 사용하는 방법은 다음과 같다.

```shell
docker pull swaggerapi/swagger-editor
docker run -d -p 8123:8080 swaggerapi/swagger-editor
open http://localhost:8123
```

Api 스펙을 하나의 파일로 합치려면 다음과 같이 실행한다.

```shell
# 설치
npm install -g swagger-cli

# 실행
swagger-cli bundle api-spec.yaml --outfile build/openapi.yaml --type yaml
```
 * OpenAPI spec version: 1.0.0
 */

/**
 * - SEARCH_KEYWORD: 검색 (A/B)
- EXPOSE_RECENT_BOUGHT_PRODUCT_ON_PRODUCT_SEARCH_VIEW: 검색결과 리스트내 최근구매/자주산 상품 노출 (A/B)
- HIDE_PRODUCT_ON_ORDER_PAYMENT_VIEW: 결제화면 내 주문상품 정보 접기 (A/B)
- SORT_REVIEW_COUNT_ON_PRODUCT_LIST_VIEW_BY_CATEGORY: 카테고리 상품 리스트 정렬 리뷰 많은 순 (A/B)
- MAIN_HOME_SECTION: 쇼핑 메인 내 상품 추천섹션 추가 (A/B/C)
- REDUCE_HOME_MAIN_TOP_AREA: 쇼핑 메인 내 상단 영역 축소 (A/B)
- MAIN_HOME_ADD_CATEGORY_QUICK_MENU: 쇼핑 메인 내 카테고리 퀵메뉴 추가 (A/B)
- IMPROVE_COUPON_GUIDE_ON_CART_VIEW: 장바구니 내 쿠폰 안내 개선 (A/B)
- EXPOSE_RECENT_BOUGHT_AND_RELATED_PRODUCT_ON_PRODUCT_SEARCH_VIEW: 검색결과 리스트내 최근구매/관련상품 노출 (A/B)
- ADD_SAME_BRAND_PRODUCT_SECTION_ON_PRODUCT_DETAIL_VIEW: 상품상세 내 동일 브랜드 상품 섹션 추가 (A/B)
- ADD_EXPIRATION_DATE_INFO_ON_PRODUCT_DETAIL_VIEW: 상품상세 내 유통기한 정보 추가 (A/B)
- SEARCH_BRAND_AREA_POSITION: 검색 전 브랜드 영역 위치 및 랜딩 지점 변경(A/B)
- PRODUCT_RECOMMENDATION_ACCESSIBILITY: 상품 상세 내 상품 추천 영역 접근성 강화(A/B/C)
- CART_COUPON_DOWNLOAD: 장바구니 내 쿠폰 다운로드 추가(A/B)
- FAVORITE_PRODUCT_PRICE_CHANGE_NUDGING: 관심상품 페이지 내 상품 가격변동 넛징(A/B)
- NUDGE_IF_PRICE_IS_DROPPED_ON_CART_VIEW: 장바구니 내 상품 가격변동 넛징(A/B)
 */
export type ABTestGroupCaseEnum = typeof ABTestGroupCaseEnum[keyof typeof ABTestGroupCaseEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ABTestGroupCaseEnum = {
  SEARCH_KEYWORD: 'SEARCH_KEYWORD',
  EXPOSE_RECENT_BOUGHT_PRODUCT_ON_PRODUCT_SEARCH_VIEW: 'EXPOSE_RECENT_BOUGHT_PRODUCT_ON_PRODUCT_SEARCH_VIEW',
  HIDE_PRODUCT_ON_ORDER_PAYMENT_VIEW: 'HIDE_PRODUCT_ON_ORDER_PAYMENT_VIEW',
  SORT_REVIEW_COUNT_ON_PRODUCT_LIST_VIEW_BY_CATEGORY: 'SORT_REVIEW_COUNT_ON_PRODUCT_LIST_VIEW_BY_CATEGORY',
  MAIN_HOME_SECTION: 'MAIN_HOME_SECTION',
  REDUCE_HOME_MAIN_TOP_AREA: 'REDUCE_HOME_MAIN_TOP_AREA',
  MAIN_HOME_ADD_CATEGORY_QUICK_MENU: 'MAIN_HOME_ADD_CATEGORY_QUICK_MENU',
  IMPROVE_COUPON_GUIDE_ON_CART_VIEW: 'IMPROVE_COUPON_GUIDE_ON_CART_VIEW',
  EXPOSE_RECENT_BOUGHT_AND_RELATED_PRODUCT_ON_PRODUCT_SEARCH_VIEW:
    'EXPOSE_RECENT_BOUGHT_AND_RELATED_PRODUCT_ON_PRODUCT_SEARCH_VIEW',
  ADD_SAME_BRAND_PRODUCT_SECTION_ON_PRODUCT_DETAIL_VIEW: 'ADD_SAME_BRAND_PRODUCT_SECTION_ON_PRODUCT_DETAIL_VIEW',
  ADD_EXPIRATION_DATE_INFO_ON_PRODUCT_DETAIL_VIEW: 'ADD_EXPIRATION_DATE_INFO_ON_PRODUCT_DETAIL_VIEW',
  SEARCH_BRAND_AREA_POSITION: 'SEARCH_BRAND_AREA_POSITION',
  PRODUCT_RECOMMENDATION_ACCESSIBILITY: 'PRODUCT_RECOMMENDATION_ACCESSIBILITY',
  CART_COUPON_DOWNLOAD: 'CART_COUPON_DOWNLOAD',
  FAVORITE_PRODUCT_PRICE_CHANGE_NUDGING: 'FAVORITE_PRODUCT_PRICE_CHANGE_NUDGING',
  NUDGE_IF_PRICE_IS_DROPPED_ON_CART_VIEW: 'NUDGE_IF_PRICE_IS_DROPPED_ON_CART_VIEW',
} as const
