import { useQuery } from '@apollo/client'
import { OnApiCompletedType, OnApiErrorType } from '@/constants/legacy/constType'
import { QUERY_KEY2GQLS } from '@/containers/gqls'
import { getResultFromData } from '@/utils/utilApi'
import * as utilApi from '@/utils/utilApi'
import * as utilData from '@/utils/utilData'

const useApiQuery = (
  gqlKey: string,
  variables: object | undefined,
  onAPISuccess?: OnApiCompletedType,
  onApiError?: OnApiErrorType,
  noCache?: boolean
) => {
  const gqlQuery = QUERY_KEY2GQLS[gqlKey]
  const _variables = variables ? { variables: utilData.deepcopy(variables) } : undefined
  const { loading, error, data, refetch, called, fetchMore } = useQuery(gqlQuery, {
    fetchPolicy: noCache ? 'no-cache' : 'cache-first',
    ..._variables,
    onCompleted(resultData) {
      if (!onAPISuccess) return

      const hasDataWithoutError = utilApi.maybe(() => !!Object.keys(resultData).length, false) && called && !error
      if (!hasDataWithoutError) return
      const result: any = getResultFromData(resultData)
      onAPISuccess(result)
    },
    onError(_error) {
      if (!onApiError) return
      onApiError(_error)
    },
  })
  // data manipulation
  const result = getResultFromData(data)
  return { loading, error, data: result, refetch, fetchMore }
}

export default useApiQuery
